import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-sign-form-msg',
  styles: [require('./@styles/_import.sass')],
  template: require('./msg.component.pug')
})

export class SignFormSharedMsgComponent {
  @Input() mod: string;
  @Input() title: string;
  @Input() text: string;
}

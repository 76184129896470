import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';

import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';

import { ProfileWebhookLogsListComponent } from "@frontend/profile/webhook-logs/list/list.component";

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    ProfileSharedModule,
    DirectivesModule
  ],
  declarations: [
    ProfileWebhookLogsListComponent
  ],
  exports: [
    ProfileWebhookLogsListComponent
  ]
})
export class ProfileWebhookLogsModule { }
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
  Validators
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { AccountService, SellersService, ProductService, AppConfig, EmitterService } from '@frontend/@shared/services';
import { Account, Location, StoreInformation, StorePostInformation, StoreReview, StorePartner, StoreReferalLink } from '@frontend/@shared/types';
import { YougiverValidators } from '@frontend/@shared/directives';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { pairwise } from 'rxjs/operators';


@Component({
  selector: 'yougiver-profile-settings-shop-by-link',
  styles: [require('./styles/_import.sass')],
  template: require('./shop-by-link.component.pug'),

})
export class ProfileSettingsShopByLinkComponent implements OnInit {
  protected subscriptions: Array<Subscription> = [];
  public storeInformation: StoreInformation;
  public account: Account;

  form: FormGroup;
  aboutShop: AbstractControl;
  videoLink: AbstractControl;
  previewImage: AbstractControl;
  shopName: AbstractControl;
  customDomain: AbstractControl;
  partners: AbstractControl;
  reviews: AbstractControl;
  referralLinks: AbstractControl;
  companyImage: AbstractControl;
  companyName: AbstractControl;
  companyAddress: AbstractControl;
  companyEmail: AbstractControl;
  companyPhone: AbstractControl;
  companyFacebook: AbstractControl;
  companyInstagram: AbstractControl;
  companyYoutube: AbstractControl;
  companyTwitter: AbstractControl;
  companyLinkedin: AbstractControl;

  private store_type: string;
  private headersForUploader: Array<any> = [];
  private baseUrl: string = '';
  private updatedFields: any = {};

  private showExample: boolean = true;
  private showDeleteLinkModal: boolean = false;
  private showCopyPopup: boolean = false;
  private showSavePopup: boolean = false;

  private isUploadingPhoto: boolean = false;
  private isUploadingCompanyPhoto: boolean = false;
  private isUploadingPreviewPhoto: boolean = false;

  private uploadingPhotoIndex: number;
  private deletedReferralLinkIndex: number;
  
  private shopHasProducts: boolean = false;

  private noProductsError: boolean = false;
  private noDataError: boolean = false;

  private actionReferralLink: number = -2;
  private referralLinkDomains: any = {};

  private translationKeys: any = {
    store: {
      shop_by_link: 'profile.navbar.settings.shop_by_link',
      about_shop: 'profile.shop_by.about_shop',
      main_page_preview: 'profile.shop_by.main_page_preview',
      personal_link: 'profile.shop_by.personal_link',
      create_personal_link: 'profile.shop_by.create_personal_link',
    },
    charity: {
      shop_by_link: 'profile.navbar.settings.charity_by_link',
      about_shop: 'profile.charity_by.about_charity',
      main_page_preview: 'profile.charity_by.main_page_preview',
      personal_link: 'profile.charity_by.personal_link',
      create_personal_link: 'profile.charity_by.create_personal_link',
    },
    auction: {
      shop_by_link: 'profile.navbar.settings.auction_by_link',
      about_shop: 'profile.auction_by.about_auction',
      main_page_preview: 'profile.auction_by.main_page_preview',
      personal_link: 'profile.auction_by.personal_link',
      create_personal_link: 'profile.auction_by.create_personal_link',
    }
  };


  constructor(
    protected fb: FormBuilder,
    protected sellersService: SellersService,
    protected accountService: AccountService,
    protected productService: ProductService,
    protected config: AppConfig,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected ls: CoolLocalStorage,) {
  }

  ngOnInit() {
    EmitterService.get('SET_BREADCRUMB').emit([]);

    this.store_type = this.activeRoute.snapshot.data['store_type'];

    this.setupForm()
    this.setupUploaderHeaders()
    this.setupFileUploaderEvents()
    this.setupData()

    this.baseUrl = this.config.getConfig('new_front_domain')

    this.referralLinkDomains = {
      store: this.config.getConfig('store_domain'),
      charity: this.config.getConfig('charity_domain'),
      auction: this.config.getConfig('auction_domain'),
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getTranslation(fieldName): string {
    return this.translationKeys[this.store_type][fieldName]
  }

  goToPreviewPage() {
    if (!this.shopName.value) {
      this.noDataError = true
      return
    }

    const link = document.createElement('a')

    link.target = '_blank'
    link.href = `https://${this.baseUrl}/${this.shopName.value}/preview?from=${window.location.href}`

    link.click()
    link.remove()
  }

  setupData() {
    this.account = this.accountService.getAccount();

    if (this.account) {
      this.getStoreInformation()
      this.setReferralLinksInformation(this.account)
    }

    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;

      this.getStoreInformation()
      this.setReferralLinksInformation(this.account)
    }));
  }

  setupForm() {
    this.form = this.fb.group({
      aboutShop: [{ value: '', disabled: false }],
      videoLink: ['', YougiverValidators.videoLink],
      previewImage: [{ value: '', disabled: false }],
      shopName: ['', YougiverValidators.shopName],
      customDomain: ['', YougiverValidators.shopName],
      companyImage: [{ value: '', disabled: false }],
      companyName: [{ value: '', disabled: false }],
      companyAddress: [{ value: '', disabled: false }],
      companyEmail: ['', YougiverValidators.email],
      companyPhone: ['', YougiverValidators.phone],
      companyFacebook: [{ value: '', disabled: false }],
      companyInstagram: [{ value: '', disabled: false }],
      companyYoutube: [{ value: '', disabled: false }],
      companyTwitter: [{ value: '', disabled: false }],
      companyLinkedin: [{ value: '', disabled: false }],
      partners: this.fb.array([this.createEmptyPartner()]),
      reviews: this.fb.array([this.createEmptyReview()]),
      referralLinks: this.fb.array([this.createEmptyReferralLink()])
    });

    this.aboutShop = this.form.controls['aboutShop'];
    this.videoLink = this.form.controls['videoLink'];
    this.previewImage = this.form.controls['previewImage'];
    this.shopName = this.form.controls['shopName'];
    this.customDomain = this.form.controls['customDomain'];
    this.partners = this.form.controls['partners'];
    this.reviews = this.form.controls['reviews'];
    this.referralLinks = this.form.controls['referralLinks'];
    this.companyImage = this.form.controls['companyImage'];
    this.companyName = this.form.controls['companyName'];
    this.companyAddress = this.form.controls['companyAddress'];
    this.companyEmail = this.form.controls['companyEmail'];
    this.companyPhone = this.form.controls['companyPhone'];
    this.companyFacebook = this.form.controls['companyFacebook'];
    this.companyInstagram = this.form.controls['companyInstagram'];
    this.companyYoutube = this.form.controls['companyYoutube'];
    this.companyTwitter = this.form.controls['companyTwitter'];
    this.companyLinkedin = this.form.controls['companyLinkedin'];

    this.subscriptions.push(this.form.valueChanges
      .debounceTime(1000)
      .pipe(pairwise())
      .subscribe((data: any) => {
        const updatedData = this.checkChangedData(data)

        if (!updatedData) {
          return
        }

        this.updatedFields = updatedData

        this.updateStoreInformation(updatedData)
        this.updateAccountInformation(updatedData)
      })
    )
  }

  createEmptyPartner(): FormGroup {
    return this.fb.group({
      partnerId: [{ value: undefined, disabled: false }],
      companyName: [{ value: '', disabled: false }],
      companyLink: [{ value: '', disabled: false }],
      companyImage: [{ value: undefined, disabled: false }],
    })
  }

  createEmptyReview(): FormGroup {
    return this.fb.group({
      reviewId: [{ value: undefined, disabled: false }],
      reviewerName: [{ value: '', disabled: false }],
      reviewerActivity: [{ value: '', disabled: false }],
      reviewText: [{ value: '', disabled: false }],
    })
  }

  createEmptyReferralLink(): FormGroup {
    return this.fb.group({
      linkId: [{ value: undefined, disabled: false }],
      path: ['', YougiverValidators.referralLink]
    })
  }

  onClickAddPartner() {
    const partners = this.form.get('partners') as FormArray
    partners.push(this.createEmptyPartner())
  }

  onClickAddReview() {
    const reviews = this.form.get('reviews') as FormArray
    reviews.push(this.createEmptyReview())
  }

  onClickAddReferralLink() {
    const referralLinks = this.form.get('referralLinks') as FormArray
    referralLinks.push(this.createEmptyReferralLink())
  }

  getPartners(): FormControl {
    return this.form.get('partners')['controls'] as FormControl
  }

  getReviews(): FormControl {
    return this.form.get('reviews')['controls'] as FormControl
  }

  getReferralLinks(): FormControl {
    return this.form.get('referralLinks')['controls'] as FormControl
  }

  getShopName(): string {
    return this.form.get('shopName').value
  }

  toggleExample() {
    this.showExample = !this.showExample
  }

  checkChangedData(data: any) {
    const previous = data[0]
    const current = data[1]

    const updatedData = this.checkChangedFields(previous, current, this)

    return updatedData
  }

  checkChangedFields(previous: any, current: any, control: any = {}) {
    const updatedData = {}

    previous && current && Object.keys(previous).forEach(key => {
      if (Array.isArray(current[key])) {
        const updatedArray = this.checkChangesArrays(previous[key], current[key], control[key] && control[key].controls)
        updatedData[key] = updatedArray
        return
      }

      if (typeof current[key] === 'object') {
        const updatedObject = this.checkChangedFields(previous[key], current[key], control[key] && control[key].controls)

        if (updatedObject) {
          updatedData[key] = updatedObject
        }
      }

      if (previous[key] === current[key]) {
        return
      }

      if (control[key] && control[key].value && control[key].invalid) {
        return
      }

      updatedData[key] = current[key]
    })

    if (!Object.keys(updatedData).length) {
      return null
    }

    return updatedData
  }

  checkChangesArrays(previous: Array<any>, current: Array<any>, control: any): Array<any> {
    const updatedArrays = []

    previous.forEach((value, index) => {
      if (!current[index]) {
        return
      }

      const updatedObject = this.checkChangedFields(value, current[index], control[index] && control[index].controls)

      if (updatedObject) {
        updatedArrays.push({ ...current[index], index })
      }
    })

    return updatedArrays
  }

  prepareForm(data: any = []): StorePostInformation {
    const storeInformation = {
      description: data.aboutShop,
      video_url: data.videoLink,
      preview_image_ids: data.previewImage
        ? [data.previewImage.id]
        : (this.previewImage.value ? undefined : []),
      reviews_attributes: this.prepareReviews(data.reviews),
      partners_attributes: this.preparePartners(data.partners),
      custom_domain: data.customDomain,
      company_address: data.companyAddress,
      company_name: data.companyName,
      company_email: data.companyEmail,
      company_phone_number: data.companyPhone,
      company_image_ids: data.companyImage
        ? [data.companyImage.id]
        : (this.companyImage.value ? undefined : []),
    }

    if (this.checkIsSocialLinksChanged(data)) {
      storeInformation['social_networks'] = {
        facebook: this.companyFacebook.value,
        instagram: this.companyInstagram.value,
        youtube: this.companyYoutube.value,
        twitter: this.companyTwitter.value,
        linkedin: this.companyLinkedin.value,
      }
    }

    return storeInformation
  }

  checkIsSocialLinksChanged(data: any) {
    const socialLinksFields = ['companyFacebook', 'companyInstagram', 'companyYoutube', 'companyTwitter', 'companyLinkedin']
    return socialLinksFields.some(link => link in data)
  }

  prepareReviews(reviews: any[] = []): Array<StoreReview> {
    return reviews
      .filter((review: any) => Boolean(review.reviewerName) || Boolean(review.reviewId))
      .map((review: any) => ({
        id: review.reviewId,
        name: review.reviewerName,
        position: review.reviewerActivity,
        body: review.reviewText,
        _destroy: review.destroy
      }))
  }

  preparePartners(partners: any[] = []): Array<StorePartner> {
    return partners
      .filter((partner: any) => Boolean(partner.companyName) || Boolean(partner.partnerId))
      .map((partner: any) => ({
        id: partner.partnerId,
        name: partner.companyName,
        url: partner.companyLink,
        image_ids: partner.companyImage ? [partner.companyImage.id] : [],
        _destroy: partner.destroy
      }))
  }

  prepareReferralLinks(referralLinks: any[] = []): Array<StoreReferalLink> {
    return referralLinks
      .filter((link: any) => Boolean(link.path) || Boolean(link.linkId))
      .map((link: any) => ({
        id: link.linkId,
        path: link.path,
        _destroy: link.destroy
      }))
  }

  updateStoreInformation(data: any) {
    const newStoreInformation = this.prepareForm(data)

    this.subscriptions.push(this.sellersService
      .updateStoreInformation(this.account.id, this.store_type, newStoreInformation)
      .subscribe((data: any) => {
        this.onUpdatedStoreInformation(data)
      })
    )
  }

  onUpdatedStoreInformation(data: any) {
    this.setStoreInformation(data.store_information)

    if (this.checkIsSocialLinksChanged(this.updatedFields)) {
      this.showUpdatedSocialLink()
    }

  }

  updateAccountInformation(data: any) {
    const newAccountInformation = {
      referral_links_attributes: this.prepareReferralLinks(data.referralLinks),
      slug: data.shopName,
    }

    this.subscriptions.push(this.accountService
      .updateAccount(this.account.id, newAccountInformation)
      .subscribe((data: any) => {
        if (data.errors) {
          EmitterService.get('NOTIFICATION').emit({ error: 'shared.validation.slug_exists' });
        }
        if (!data.resource) {
          return
        }

        this.setReferralLinksInformation(data.resource)
      })
    )

  }

  getStoreInformation() {
    this.subscriptions.push(this.sellersService
      .getStoreInformation(this.account.id, this.store_type)
      .subscribe((data: any) => {
        this.setStoreInformation(data.store_information, true)
      })
    )
    this.getProducts()
  }

  getProducts() {
    this.subscriptions.push(this.productService
      .getProducts({})
      .subscribe((data: any) => {
        this.shopHasProducts = data.total_count > 0
      })
    )
  }

  setReferralLinksInformation(data: any) {
    if (!this.shopName.value && data.slug) {
      this.shopName.patchValue(data.slug, { onlySelf: true, emitEvent: false })
    }

    this.setReferralLinksData(data.referral_links)
    
    this.account.slug = data.slug
    this.account.referral_links = data.referral_links
  }

  setStoreInformation(data: StoreInformation, skipUpdateCheck: boolean = false) {
    this.setStoreData(data, skipUpdateCheck)
    this.setPartnersData(data.partners)
    this.setReviewsData(data.reviews)
  }

  setStoreData(data: StoreInformation, skipUpdateCheck: boolean = false) {
    const fieldsToCheck = 
      {
        aboutShop:'description',
        videoLink: 'video_url',
        companyAddress: 'company_address',
        companyName: 'company_name',
        customDomain: 'custom_domain',
        companyEmail: 'company_email',
        companyPhone: 'company_phone_number',
      }

    const socialsToCheck = 
      {
        companyFacebook: 'facebook',
        companyInstagram: 'instagram',
        companyLinkedin: 'linkedin',
        companyTwitter: 'twitter',
        companyYoutube: 'youtube',
      }
    
    Object.keys(fieldsToCheck).forEach((key) => {
      if (!this[key].value && (skipUpdateCheck || key in this.updatedFields) && data[fieldsToCheck[key]]) {
        this[key].patchValue(data[fieldsToCheck[key]], { onlySelf: true, emitEvent: false })
      }
    })

    if (data.social_networks) {
      Object.keys(socialsToCheck).forEach((key) => {
        if (!this[key].value && (skipUpdateCheck || key in this.updatedFields) && data.social_networks[socialsToCheck[key]]) {
          this[key].patchValue(data.social_networks[socialsToCheck[key]], { onlySelf: true, emitEvent: false })
        }
      })
    }

    if (!this.companyImage.value && (skipUpdateCheck || 'companyImage' in this.updatedFields) && data.company_image) {
      this.companyImage.patchValue({
        id: data.company_image.id,
        default_url: data.company_image.versions.default
      }, { onlySelf: true, emitEvent: false })
    }

    if (!this.previewImage.value && (skipUpdateCheck || 'previewImage' in this.updatedFields) && data.preview_image) {
      this.previewImage.patchValue({
        id: data.preview_image.id,
        default_url: data.preview_image.versions.default
      }, { onlySelf: true, emitEvent: false })
    }
  }

  setPartnersData(partners: Array<StorePartner>) {
    const partnersArray = this.form.get('partners') as FormArray
    for (let i = partnersArray.length; i < partners.length; i++) {
      partnersArray.push(this.createEmptyPartner())
    }

    const newPartners = partners
      .filter(partner => !partnersArray.value.find((control: any) => control.partnerId === partner.id))

    newPartners.sort((a, b) => a.id - b.id)

    newPartners.forEach((partner: StorePartner, index: number) => {
      const currentData = partnersArray.at(partners.length - newPartners.length + index)
      const patchData: any = {}

      if (partner.images && partner.images.length > 0 && partner.images[0].versions) {
        patchData.companyImage = {
          id: partner.images[0].id,
          default_url: partner.images[0].versions.default
        }
      }

      if (!currentData.value.partnerId) {
        patchData.partnerId = partner.id
      }

      if (!currentData.value.name) {
        patchData.companyName = partner.name
      }

      if (!currentData.value.url) {
        patchData.companyLink = partner.url
      }

      currentData.patchValue(patchData, { onlySelf: true, emitEvent: false })
    })
  }

  setReviewsData(reviews: Array<StoreReview>) {
    const reviewsArray = this.form.get('reviews') as FormArray
    for (let i = reviewsArray.length; i < reviews.length; i++) {
      reviewsArray.push(this.createEmptyReview())
    }

    const newReviews = reviews
      .filter(review => !reviewsArray.value.find((control: any) => control.reviewId === review.id))

    newReviews.sort((a, b) => a.id - b.id)

    newReviews.forEach((review: StoreReview, index: number) => {
      const currentData = reviewsArray.at(reviews.length - newReviews.length + index)
      const patchData: any = {}

      if (!currentData.value.reviewId) {
        patchData.reviewId = review.id
      }

      if (!currentData.value.reviewerName) {
        patchData.reviewerName = review.name
      }

      if (!currentData.value.reviewerActivity) {
        patchData.reviewerActivity = review.position
      }

      if (!currentData.value.reviewText) {
        patchData.reviewText = review.body
      }

      currentData.patchValue(patchData, { onlySelf: true, emitEvent: false })
    })
  }

  setReferralLinksData(referralLinks: Array<StoreReferalLink> = []) {
    const referralLinksArray = this.form.get('referralLinks') as FormArray
    for (let i = referralLinksArray.length; i < referralLinks.length; i++) {
      referralLinksArray.push(this.createEmptyReferralLink())
    }

    referralLinks.sort((a, b) => a.id - b.id)

    referralLinks.forEach((link: StoreReferalLink, index: number) => {
      const currentData = referralLinksArray.at(index)
      const patchData: any = {}

      if (!currentData.value.linkId) {
        patchData.linkId = link.id
      }

      if (!currentData.value.path) {
        patchData.path = link.path
      }

      currentData.patchValue(patchData, { onlySelf: true, emitEvent: false })
    })
  }

  onDeleteReview(deletedReviewIndex: number) {
    const data = this.form.value
    const updatedReviews = data.reviews

    updatedReviews[deletedReviewIndex] = {
      ...updatedReviews[deletedReviewIndex],
      destroy: true
    }

    this.updateStoreInformation({ ...data, reviews: updatedReviews })

    const reviewsArray = this.form.get('reviews') as FormArray
    reviewsArray.removeAt(deletedReviewIndex)
  }

  onDeletePartner(deletedPartnerIndex: number) {
    const data = this.form.value
    const updatedPartners = data.partners

    updatedPartners[deletedPartnerIndex] = {
      ...updatedPartners[deletedPartnerIndex],
      destroy: true
    }

    this.updateStoreInformation({ ...data, partners: updatedPartners })

    const partnersArray = this.form.get('partners') as FormArray
    partnersArray.removeAt(deletedPartnerIndex)
  }

  onDeleteReferralLink(deletedReferralLinkIndex: number) {
    this.deletedReferralLinkIndex = deletedReferralLinkIndex
    this.showDeleteLinkModal = true
  }

  deleteReferralLink() {
    const data = this.form.value
    const updatedReferralLinks = data.referralLinks

    updatedReferralLinks[this.deletedReferralLinkIndex] = {
      ...updatedReferralLinks[this.deletedReferralLinkIndex],
      destroy: true
    }

    this.updateAccountInformation({ ...data, referralLinks: updatedReferralLinks })

    const referralLinksArray = this.form.get('referralLinks') as FormArray
    referralLinksArray.removeAt(this.deletedReferralLinkIndex)

    this.showDeleteLinkModal = false
  }

  cancelDeleteReferralLink() {
    this.showDeleteLinkModal = false
  }

  onDeleteVideoLink() {
    this.videoLink.patchValue('')
  }

  onDeleteDescription() {
    this.aboutShop.patchValue('')
  }

  onDeleteCompanyContact(contactType: string) {
    switch (contactType) {
      case 'address': return this.companyAddress.patchValue('')
      case 'name': return this.companyName.patchValue('')
      case 'email': return this.companyEmail.patchValue('')
      case 'phone': return this.companyPhone.patchValue('')
    }
  }

  onDeleteCompanySocials(social: string) {
    switch (social) {
      case 'facebook': return this.companyFacebook.patchValue('')
      case 'instagram': return this.companyInstagram.patchValue('')
      case 'linkedin': return this.companyLinkedin.patchValue('')
      case 'twitter': return this.companyTwitter.patchValue('')
      case 'youtube': return this.companyYoutube.patchValue('')
    }
  }

  setupUploaderHeaders() {
    this.sellersService.headers.forEach((value: string[], name: string) => {
      if (name.toLowerCase() === 'content-type') {
        return
      }

      this.headersForUploader.push({ name: name, value: value[0] });
    });
  }

  onPartnerPhotoStartUploading(index: number) {
    this.uploadingPhotoIndex = index
  }

  onPartnerPhotoDeleted(index: number) {
    const partnersArray = this.form.get('partners') as FormArray
    partnersArray.at(index).patchValue({
      companyImage: undefined
    })
  }

  onPreviewImageDeleted() {
    this.previewImage.patchValue(null)
  }

  setupFileUploaderEvents() {
    this.subscriptions.push(EmitterService.get('STATUS_FILE_UPLOADER_EVENT').subscribe((res: boolean) => {
      this.isUploadingPhoto = res
    }));

    this.subscriptions.push(EmitterService.get('FILE_UPLOADER_EVENT').subscribe(res => {
      const response = JSON.parse(res.response);

      if (res.status < 200 || res.status >= 300) {
        EmitterService.get('NOTIFICATION').emit(response);
        return
      }

      if (this.isUploadingCompanyPhoto) {
        this.isUploadingCompanyPhoto = false

        this.companyImage.patchValue({
          id: response.id,
          default_url: response.default_url
        })

        return
      }

      if (this.isUploadingPreviewPhoto) {
        this.isUploadingPreviewPhoto = false

        this.previewImage.patchValue({
          id: response.id,
          default_url: response.default_url
        })

        this.videoLink.patchValue('')

        return
      }

      const partnersArray = this.form.get('partners') as FormArray

      partnersArray.at(this.uploadingPhotoIndex).patchValue({
        companyImage: {
          id: response.id,
          default_url: response.default_url
        }
      })
    }));

    
    this.subscriptions.push(EmitterService.get('DELETE_UPLOADED_FILE').subscribe(file => {
      this.sellersService.destroyPreviewImage(this.account.id, file).subscribe()
    }));
  }

  onCopyReferralLink() {
    this.showCopyPopup = true

    setTimeout(() => {
      this.showCopyPopup = false
    }, 1500)
  }

  onCloseCopyPopup() {
    this.showCopyPopup = false
  }

  onCompanyPhotoStartUploading() {
    this.isUploadingCompanyPhoto = true
  }

  onPreviewImageStartUploading() {
    this.isUploadingPreviewPhoto = true
  }

  onCompanyPhotoDeleted() {
    this.companyImage.patchValue(undefined)
  }

  publish() {
    if (!this.shopHasProducts) {
      this.noProductsError = true
    }
  }

  showUpdatedSocialLink() {
    this.showSavePopup = true

    setTimeout(() => {
      this.showSavePopup = false
    }, 1500)
  }

  onActionReferralLink(index: number) { 
    if (this.shopHasProducts) return

    this.actionReferralLink = index
  }
}

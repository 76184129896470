import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators
} from '@angular/forms';

import { AccountService, EmitterService, AppConfig } from '@frontend/@shared/services';
import { Account } from '@frontend/@shared/types';
import { YougiverValidators } from '@frontend/@shared/directives';

@Component({
  selector: 'yougiver-profile-settings-payments',
  template: require('./payments.component.pug'),

})
export class ProfileSettingsPaymentsComponent implements OnInit, OnDestroy {
  protected invalidForm: boolean = false;
  protected subscriptions: Array<Subscription> = [];
  public account: Account;

  form: FormGroup;
  billing_information: AbstractControl;
  iban: AbstractControl;
  beneficiary_account_usd: AbstractControl;
  beneficiary_name: AbstractControl;
  beneficiary_address: AbstractControl;
  beneficiary_bank_name: AbstractControl;
  beneficiary_bank_swift: AbstractControl;
  beneficiary_bank_address: AbstractControl;
  intermediary_bank: AbstractControl;
  correspondent_account: AbstractControl;
  policies_accepted_at: AbstractControl;


  constructor(protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected router: Router,
    protected config: AppConfig) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      billing_information: this.fb.group({
        iban: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_account_usd: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_name: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_address: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_bank_name: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_bank_swift: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, YougiverValidators.swift_code])],
        beneficiary_bank_address: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        intermediary_bank: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        correspondent_account: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])]
      }),
      policies_accepted_at: ['',
          Validators.compose([Validators.required])]
    });

    this.policies_accepted_at = this.form.controls['policies_accepted_at'];
    this.billing_information = this.form.controls['billing_information'];
    this.iban = (this.billing_information as any).controls['iban'];
    this.beneficiary_account_usd = (this.billing_information as any).controls['beneficiary_account_usd'];
    this.beneficiary_name = (this.billing_information as any).controls['beneficiary_name'];
    this.beneficiary_address = (this.billing_information as any).controls['beneficiary_address'];
    this.beneficiary_bank_name = (this.billing_information as any).controls['beneficiary_bank_name'];
    this.beneficiary_bank_swift = (this.billing_information as any).controls['beneficiary_bank_swift'];
    this.beneficiary_bank_address = (this.billing_information as any).controls['beneficiary_bank_address'];
    this.intermediary_bank = (this.billing_information as any).controls['intermediary_bank'];
    this.correspondent_account = (this.billing_information as any).controls['correspondent_account'];
    EmitterService.get('SET_BREADCRUMB').emit(['shared.settings', 'profile.navbar.settings.payment_data']);
    this.account = this.accountServise.getAccount();
    if (this.account) {
      for (let key in this.account.billing_information) {
        if (this.account.billing_information[key]) {
          this[key].patchValue(this.account.billing_information[key]);
        }
      }
    }
    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      for (let key in this.account.billing_information) {
        if (this.account.billing_information[key]) {
          this[key].patchValue(this.account.billing_information[key]);
        }
      }
    }));


  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      this.invalidForm = false;
      this.accountServise.updateAccount(this.account.id, this.form.value).subscribe((res: any) => {
        if (res) {
          if (res['resource']) {
            this.accountServise.setAccount(res['resource']);
          }
          EmitterService.get('NOTIFICATION').emit(res);
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';

import { FooterSelectComponent } from './component';
import { SelectModule } from '../ng2-select/select.module'

@NgModule({
  imports: [CommonModule, DirectivesModule, SelectModule],
  declarations: [FooterSelectComponent],
  exports: [FooterSelectComponent]
})
export class FooterSelectModule {
}

import { Component, forwardRef, Input, AfterContentInit } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'yougiver-profile-input-small',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ProfileInputSmallComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ProfileInputSmallComponent), multi: true }
  ],
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileInputSmallComponent implements ControlValueAccessor, AfterContentInit {
  @Input() _value: string;
  @Input() modification: string;
  @Input() label: string;
  @Input() validator: string;
  @Input() placeholder: string;
  @Input() mask: any;

  onTouchedCallback: () => void;
  propagateChange: any = () => {};
  validateFn: any = () => {};

  set value(value: string) {
    this._value = value || '';
    this.propagateChange(value);
  }
  get value() {
    return this._value;
  }

  ngAfterContentInit() {
    this.writeValue(this._value);
    this.propagateChange(this._value);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }
}

import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';

import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';

import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';

import { ProfileKeysComponent } from '@frontend/profile/keys/keys.component';
import { ProfileKeysBlockComponent } from '@frontend/profile/keys/block/block.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    ProfileSharedModule
  ],
  declarations: [
    ProfileKeysComponent,
    ProfileKeysBlockComponent
  ],
  exports: [
  ProfileKeysComponent,
  ProfileKeysBlockComponent
  ],
})
export class ProfileKeysModule { }

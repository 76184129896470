import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators
} from '@angular/forms';

import { UserService } from '@frontend/@shared/services';
import { YougiverValidators } from '@frontend/@shared/directives';

@Component({
  selector: 'yougiver-sign-setup-password-form',
  template: require('./password.component.pug')
})
export class SignSetupPasswordFormComponent implements OnInit {
  @Input() reset_password_token: string;
  @Input() email: string;

  private invalidForm: boolean = false;

  form: FormGroup;
  passwords: AbstractControl;
  password: AbstractControl;
  password_confirmation: AbstractControl;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.form = this.fb.group({
      passwords: this.fb.group({
        password: ['', Validators.compose([Validators.required,
                                           YougiverValidators.whitespace,
                                           YougiverValidators.password,
                                           Validators.minLength(8)])],
        password_confirmation: ['', Validators.compose([Validators.required,
                                           YougiverValidators.whitespace,
                                           YougiverValidators.password,
                                           Validators.minLength(8)])]
      }, { validator: YougiverValidators.areEqual }),
      reset_password_token: this.reset_password_token
    });

    this.passwords = this.form.controls['passwords'];
    this.password = (this.form.controls['passwords'] as any).controls['password'];
    this.password_confirmation = (this.form.controls['passwords'] as any).controls['password_confirmation'];
  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      this.invalidForm = false;

      let params = {
        reset_password_token: this.form.controls['reset_password_token'].value,
        password: this.password.value,
        password_confirmation: this.password_confirmation.value
      };

      this.userService.doResetPassword(params).subscribe(res => {
        if (!res.error) {
          this.userService.updateApiKey(res.api_key);
          this.userService.loggedIn = true;
          this.router.navigate(['']);
        } else {
          console.log(res);
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }
}

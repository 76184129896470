import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Helper } from '@frontend/@shared/services';

@Injectable()
export class OrderService {
  public headers;
  public options;
  private ordersUrl = '/api/v1/orders';
  private apiKey: string;

  constructor(private router: Router, private route: ActivatedRoute, private http: Http, private ls: CoolLocalStorage) {
    this.apiKey = this.ls.getItem('auth_token');
    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Yougiver-Token': this.apiKey
    });
    this.options = new RequestOptions({ headers: this.headers });
  }

  public setQueryParams(params: Object) {
    return this.route.queryParams.subscribe(queryParams => {
      if (Object.keys(params).length !== 0) {
        let newQueryParams = JSON.parse(JSON.stringify(queryParams));
        // eslint-disable-next-line
        if ('state' in params && params['state'] === 'all') {
          delete newQueryParams['state'];
        }
        else {
          Object.assign(newQueryParams, params);
        }
        let navigationExtras: NavigationExtras = {
          queryParams: newQueryParams
        };
        this.router.navigate(['profile/orders/list'], navigationExtras);
        params = {};
      }
    });
  }

  public getOrders(accountId, params: Object = undefined): Observable<any> {
    if (params) {
      let newQueryParams = JSON.parse(JSON.stringify(params));
      Object.assign(newQueryParams, {account_id: accountId});
      params = Helper.transformQueryParams(newQueryParams);
      let ls = params.hasOwnProperty('locale') ? {locale: params['locale']} : this.ls;
      this.options.search = new URLSearchParams(Helper.toParams(params, ls));
    }
    return this.http.get(this.ordersUrl, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  public updateOrder(orderId, body: Object, params: Object = undefined): Observable<any> {
    let bodyString = JSON.stringify({ order: body });
    if (params) {
      let newQueryParams = JSON.parse(JSON.stringify(params));
      params = Helper.transformQueryParams(newQueryParams);
      this.options.search = new URLSearchParams(Helper.toParams(params, this.ls));
    }
    return this.http.patch(`${this.ordersUrl}/${orderId}`, bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Orders } from '@frontend/@shared/types';
import { OrderService, EmitterService } from '@frontend/@shared/services';
import { Subscription } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
@Component({
  selector: 'yougiver-profile-orders-list',
  template: require('./list.component.pug'),
  providers: [OrderService]
})

export class ProfileOrdersProductsListComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];

  private paginatorName: string;
  private orders: Orders;
  public accountType: string;

  constructor(private orderService: OrderService, private route: ActivatedRoute,
    private ls: CoolLocalStorage) {
  }

  ngOnInit(): void {
    this.accountType = this.ls.getItem('accountType');
    this.paginatorName = 'ORDERS_LIST_PARAMS';
    EmitterService.get('SET_BREADCRUMB').emit(['profile.navbar.orders', 'profile.navbar.products']);

    this.getOrders();


    this.subscriptions.push(EmitterService.get('ACCOUNT_ID').subscribe(id => {
      this.getOrders();
    }));

    this.subscriptions.push(EmitterService.get('LOCALE').subscribe(locale => {
      this.getOrders();
    }));
  }

  getOrders() {
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      let params = Object.assign({locale: (this.ls.getItem('locale'))}, queryParams);
      let accountId = this.ls.getItem('accountId');
      if (!accountId) { accountId = this.ls.getItem('latestAccount'); }
      this.subscriptions.push(this.orderService.getOrders(accountId, params).subscribe((orders: Orders) => {
        this.orders = orders;
        EmitterService.get('SHOW_TABS').emit(this.orders.total_count > 0 || queryParams['state'] ? true : false);
      }));
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { SignFormSharedButtonComponent } from '@frontend/sign/@shared/sign-form/@shared/button/button.component';
import { SignFormSharedCheckboxComponent } from '@frontend/sign/@shared/sign-form/@shared/checkbox/checkbox.component';
import { SignFormSharedInputComponent } from '@frontend/sign/@shared/sign-form/@shared/input/input.component';
import { SignFormSharedLinkComponent } from '@frontend/sign/@shared/sign-form/@shared/link/link.component';
import { SignFormSharedMsgComponent } from '@frontend/sign/@shared/sign-form/@shared/msg/msg.component';
import { SignFormSharedTabsComponent } from '@frontend/sign/@shared/sign-form/@shared/tabs/tabs.component';
import { SignFormSharedTextComponent } from '@frontend/sign/@shared/sign-form/@shared/text/text.component';
import { SignFormSharedTextareaComponent } from '@frontend/sign/@shared/sign-form/@shared/textarea/textarea.component';
import { SignFormSharedGoBackButtonComponent } from '@frontend/sign/@shared/sign-form/@shared/go-back-button/go-back-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  declarations: [
    SignFormSharedButtonComponent,
    SignFormSharedCheckboxComponent,
    SignFormSharedInputComponent,
    SignFormSharedLinkComponent,
    SignFormSharedMsgComponent,
    SignFormSharedTabsComponent,
    SignFormSharedTextComponent,
    SignFormSharedTextareaComponent,
    SignFormSharedGoBackButtonComponent,
  ],
  exports: [
    SignFormSharedButtonComponent,
    SignFormSharedCheckboxComponent,
    SignFormSharedInputComponent,
    SignFormSharedLinkComponent,
    SignFormSharedMsgComponent,
    SignFormSharedTabsComponent,
    SignFormSharedTextComponent,
    SignFormSharedTextareaComponent,
    SignFormSharedGoBackButtonComponent,
  ]
})
export class SignFormSharedModule {}

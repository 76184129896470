import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Helper, EmitterService } from '@frontend/@shared/services';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class SellersService {
  private sellersUrl = '/api/v2/sellers';
  private apiKey: string;

  public headers: Headers;
  public options: RequestOptions;

  constructor(private http: Http, private ls: CoolLocalStorage) {
    this.setHeaders();
  }

  setHeaders() {
    if (this.apiKey === this.ls.getItem('auth_token')) {
      return
    }
    this.apiKey = this.ls.getItem('auth_token');
    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Yougiver-Token': this.apiKey
    });
    this.options = new RequestOptions({ headers: this.headers });
  }

  clearRequestOptions() {
    this.options.search = undefined
    this.options.body = undefined
  }

  getStoreInformation(id: number, store_type: string): Observable<any> {
    return this.http.get(`${this.sellersUrl}/store_informations/${id}?store_type=${store_type}`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  updateStoreInformation(id: number, store_type: string, body: Object): Observable<any> {
    this.clearRequestOptions()

    const bodyString = JSON.stringify({ id: id, store_information: body })

    return this.http.post(`${this.sellersUrl}/store_informations/?store_type=${store_type}`, bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  getWebhook(id: number): Observable<any> {
    return this.http.get(`${this.sellersUrl}/webhook?seller_id=${id}`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  updateWebhook(id: number, payload) {
    return this.http.put(`${this.sellersUrl}/webhook?seller_id=${id}`, payload)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  destroyAsset(id, file): Observable<any> {
    let destroyPath;
    if (id && file) {
      destroyPath = `${this.sellersUrl}/${id}/assets/${file.id}`;
    } else if (!id && file) {
      destroyPath = `${this.sellersUrl}/assets/${file.id}`;
    }

    if (file) {
      this.options.body = { type: file.type };
    }

    return this.http.delete(destroyPath, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  destroyPreviewImage(id, file): Observable<any> {
    const destroyPath = `/api/v1/assets/${file.id}`;
    
    return this.http.delete(destroyPath, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  private toParams(object) {
    let messages = [];
    for (let name in object) {
      if (object[name as string]) {
        messages.push(`${name}=${object[name as string]}`);
      }
    }
    return messages.join('&');
  }
}

import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

interface Tab {
  text: string;
  mod: string;
  active: boolean;
  name: string;
}

@Component({
  selector: 'yougiver-sign-form-tabs',
  styles: [require('./@styles/_import.sass')],
  template: require('./tabs.component.pug')
})

export class SignFormSharedTabsComponent implements OnInit {
  @Input() tabs: Array<Tab>;

  @Output() selectedTab = new EventEmitter();

  ngOnInit() {
    if (this.tabs instanceof Array) {
      // By convention with PO we always set 'Developer' tab as a default
      this.setActive(this.tabs[1]);
    }
  }

  setActive(tab: Tab) {
    this.tabs.forEach((t) => t.active = false);
    this.selectedTab.emit({value: tab.name});
    tab.active = true;
  }
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmitterService, ProductService, CodesService } from '@frontend/@shared/services';
import { Subscription } from 'rxjs';
import { YougiverValidators } from '@frontend/@shared/directives';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators
} from '@angular/forms';

@Component({
  selector: 'yougiver-profile-codes-generate',
  template: require('./codes.component.pug'),
  providers: [ProductService, CodesService]
})
export class ProfileProductsCodesGenerateComponent implements OnInit, OnDestroy {

  @Input() product: Object;

  private subscriptions: Array<Subscription> = [];
  private paginatorName: string;
  private generationType: string = 'custom';
  public headersForUploader: Array<any> = [];

  codes_form: FormGroup;
  number_of_codes: AbstractControl;

  constructor(private fb: FormBuilder,
    private codesService: CodesService,
    private productService: ProductService,
    private router: ActivatedRoute,
    private r: Router) {

  }

  ngOnInit() {
    this.paginatorName = 'CODES_LIST_PARAMS';
    this.productService.headers.forEach((value, name) => {
      if (name.toLowerCase() !== 'content-type') {
        this.headersForUploader.push({ name: name, value: value[0] });
      }
    });
    this.codeGeneratorForm();
    this.subscriptions.push(this.router.queryParams.subscribe(queryParams => {
      let page_params = this.headersForUploader.find(item => item['name'] === 'X-Codes-Page');
      if (page_params === undefined) {
        this.headersForUploader.push({ name: 'X-Codes-Page', value: queryParams['page'] });
      }
      else {
        page_params['value'] = queryParams['page'];
      }
      this.subscriptions.push(this.codesService.getCodes(this.product['slug'], queryParams).subscribe(codes => {
        this.product['codes'] = codes;
      }));
    }));
  }

  assetsPath(slug: string): string {
    if (slug) {
      return `/api/v1/products/${slug}/assets`;
    } else {
      return `/api/v1/products/assets`;
    }
  }

  importByFilePath(): string {
    return `/api/v1/products/${this.product['slug']}/import_by_file`;
  }

  codeGeneratorForm() {
    this.codes_form = this.fb.group({
      number_of_codes: [null, Validators.compose([Validators.required, YougiverValidators.whitespace, YougiverValidators.numeric])],
    });
    this.number_of_codes = this.codes_form.controls['number_of_codes'];
  }

  codeGenerationType(type: string) {
    this.generationType = type;
  }

  generateCodes() {
    if (this.codes_form.valid) {
      this.subscriptions.push(
        this.codesService.generateCodes(this.product['id'], this.codes_form.value).
          subscribe(res => {
            this.codes_form.reset();
            EmitterService.get('NOTIFICATION').emit(res['message']);
            this.product['codes'] = res;
           }));
    } else {
      console.log('form invalid!', this.codes_form.controls);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';

import { ProfileOrdersComponent } from '@frontend/profile/orders/orders.component';
import { ProfileEmptyOrdersComponent} from '@frontend/profile/orders/empty/empty.component';
import { ProfileOrdersCertificatesListComponent } from '@frontend/profile/orders/certificates/certificates.component';
import { ProfileOrdersProductsListComponent } from '@frontend/profile/orders/products/list/list.component';
import { ProfileOrdersProductsShowComponent } from '@frontend/profile/orders/products/show/show.component';
import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ProfileSharedModule,
    SharedComponentsModule,
    DirectivesModule
  ],
  declarations: [
    ProfileOrdersComponent,
    ProfileEmptyOrdersComponent,
    ProfileOrdersCertificatesListComponent,
    ProfileOrdersProductsListComponent,
    ProfileOrdersProductsShowComponent,
  ],

  exports: [
    ProfileOrdersComponent,
    ProfileEmptyOrdersComponent,
    ProfileOrdersCertificatesListComponent,
    ProfileOrdersProductsListComponent,
    ProfileOrdersProductsShowComponent,
  ]
})
export class ProfileOrdersModule { }

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CertificateCode } from '@frontend/@shared/types';
import { CodesService } from '@frontend/@shared/services';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-codes-list',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
  providers: [CodesService]
})


export class ProfileCodesListComponent implements OnInit, OnDestroy {
  @Input() codes: Array<CertificateCode>;
  @Input() productId: number;
  private selectedCode: number;
  private subscriptions: Array<Subscription> = [];
  private params: Object;

  constructor(private codesService: CodesService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.params = queryParams;
    }));
  }

  public destroyCode(codeId: number) {
    this.subscriptions.push(this.codesService.destroyCode(this.productId, codeId, this.params).
      subscribe( res => { this.codes = res['certificate_codes']; })
    );
  }

  public destroy(selected: Object) {
    if (selected['destroy'] === true) {
      this.destroyCode(selected['value']);
    }
    else {
      this.selectedCode = undefined;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

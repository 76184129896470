import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Account, Accounts } from '@frontend/@shared/types';
import { EmitterService } from '@frontend/@shared/services';
import { CoolLocalStorage } from 'angular2-cool-storage';

import {
  FormGroup,
  AbstractControl,
  FormBuilder
} from '@angular/forms';

@Component({
  selector: 'yougiver-popup-change-account',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})
export class PopupChangeAccountComponent {
  form: FormGroup;
  name: AbstractControl;

  @Input() accounts: Accounts;
  @Input() currentAccount: Account;
  @Output() addAccount = new EventEmitter<boolean>();

  private selectedAccount;

  constructor(private fb: FormBuilder, private ls: CoolLocalStorage) {
    this.form = this.fb.group({ name: '' });
    this.name = this.form.controls['name'];
  }

  switchAccount() {
    if (this.selectedAccount) {
      EmitterService.get('ACCOUNT_ID').emit(this.selectedAccount);
      this.closePopup();
    }
  }

  add() {
    this.ls.setItem('latestAccount', '' + this.currentAccount.id);
    this.addAccount.emit(true);
    this.closePopup();
  }

  closePopup() {
    EmitterService.get('SWITCH_ACCOUNT').emit(false);
  }

  onKeyUp(value: string) {
    if (value) {
      this.accounts.accounts.forEach(a => {
        a.is_hide = a.contractor.str1 !== null && a.contractor.str1.toLowerCase().includes(value.toLowerCase()) ? false : true;
      });
    } else {
      this.accounts.accounts.forEach(a => a.is_hide = false);
    }
  }

}

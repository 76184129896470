import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yougiver-sign-layout',
  styles: [require('./@styles/_import.sass')],
  template: require('./sign-layout.component.pug')
})

export class SignLayoutComponent implements OnInit {
  @Input() type: string;
  private backgroundElementsClass: string;

  ngOnInit() {
    this.backgroundElementsClass = `${this.type}-elements`
  }
}
import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { ProfileProductsComponent } from '@frontend/profile/products/products.component';
import { ProfileProductsListComponent } from '@frontend/profile/products/list/list.component';
import { ProfileProductsAddComponent } from '@frontend/profile/products/add/add.component';
import { ProfileProductsEmptyComponent } from '@frontend/profile/products/empty/empty.component';
import { ProfileProductsCodesGenerateComponent } from '@frontend/profile/products/add/codes/codes.component';
import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ProfileSharedModule,
    SharedComponentsModule,
    DirectivesModule
  ],
  declarations: [
    ProfileProductsComponent,
    ProfileProductsAddComponent,
    ProfileProductsListComponent,
    ProfileProductsEmptyComponent,
    ProfileProductsCodesGenerateComponent,
  ],
})
export class ProfileProductModule { }

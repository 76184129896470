import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-sign-go-back-button',
  styles: [require('./@styles/_import.sass')],
  template: require('./go-back-button.component.pug')
})

export class SignFormSharedGoBackButtonComponent {
}

import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-profile-orders-list',
  template: require('./certificates.component.pug')
})

export class ProfileOrdersCertificatesListComponent {

  private orders: any;

  constructor() {

    this.orders = [

      {
        mod: 'mod-small-img',
        img: 'http://placehold.it/40x40',
        title: 'Букет красных роз «Амстердам» 12 шт.',
        number: '131',
        price: '$ 20.00',
        date: '10.01.2016 17:34',
        localization: '2144587'
      }

    ];

  }
}

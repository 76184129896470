import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-profile-webhooks-sortable',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileWebhooksSortableComponent {
}

import { Component, OnInit } from '@angular/core';
import { CoolLocalStorage } from "angular2-cool-storage";

@Component({
    selector: 'yougiver-sign-up-dev',
    template: require('./sign-up-dev.component.pug')
})
export class SignUpDevComponent implements OnInit {
    constructor(
        private ls: CoolLocalStorage
    ) {}

    ngOnInit() {
        this.ls.setItem('signUpAs', 'dev')
    }
}

import { Component, OnInit, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { YougiverValidators } from '@frontend/@shared/directives';
import 'rxjs/add/operator/debounceTime';

import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
  FormArray,
  FormControl
} from '@angular/forms';

import { AccountService, TaxonomiesService, EmitterService } from '@frontend/@shared/services';
import { Account, Taxonomies, PriceSetting, Taxon, Markup, Taxonomy } from '@frontend/@shared/types';

@Component({
  selector: 'yougiver-profile-developer-settings-common',
  template: require('./common.component.pug'),
  providers: [TaxonomiesService]
})
export class ProfileDeveloperSettingsCommonComponent implements OnInit, OnDestroy {
  protected subscriptions: Array<Subscription> = [];
  protected taxonSubscriptions: Array<Subscription> = [];
  private apiDocsLink: string;
  protected invalidForm: boolean = false;
  protected loaderState: string = '';
  public taxons: any;
  public account: Account;
  public taxonomies: Taxonomies;
  public headersForUploader: Array<any> = [];

  private markups: Array<Markup>;

  form: FormGroup;

  taxon_ids: AbstractControl;
  taxon_id: AbstractControl;

  general_info: AbstractControl;
  company_name: AbstractControl;
  site_url: AbstractControl;
  company_phone_number: AbstractControl;
  legal_address: AbstractControl;
  personal_phone_number: AbstractControl;
  personal_email: AbstractControl;
  personal_address: AbstractControl;
  full_name: AbstractControl;
  position: AbstractControl;

  price_settings_attributes: AbstractControl;

  constructor(protected _changeDetectionRef: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected taxonomiesService: TaxonomiesService,
    protected router: Router,
    protected ls: CoolLocalStorage,
    protected zone: NgZone) {
    this.apiDocsLink = '/api/v1/docs';
  }

  getAccount() {
    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.form = this.fb.group({
        company_name: [this.account.company_name,
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        site_url: this.account.site_url,
        general_info: this.fb.group({
          company_phone_number: ['',
            Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
          legal_address: ['',
            Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
          personal_address: ['',
            Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
          personal_phone_number: ['',
            Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
          personal_email: ['',
            Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
          full_name: ['',
            Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
          position: ['',
            Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])]
        }),
        taxon_ids: this.fb.array([])
      });
      this.company_name = this.form.controls['company_name'];
      this.site_url = this.form.controls['site_url'];
      this.taxon_ids = this.form.controls['taxon_ids'];
      this.general_info = this.form.controls['general_info'];
      this.company_phone_number = (this.general_info as any).controls['company_phone_number'];
      this.legal_address = (this.general_info as any).controls['legal_address'];
      this.personal_phone_number = (this.general_info as any).controls['personal_phone_number'];
      this.personal_email = (this.general_info as any).controls['personal_email'];
      this.personal_address = (this.general_info as any).controls['personal_address'];
      this.full_name = (this.general_info as any).controls['full_name'];
      this.position = (this.general_info as any).controls['position'];
    }));

  }

  ngOnInit() {

    this.formSetup();
    this.account = this.accountServise.getAccount();
    if (this.account) {
      this.formUpdate();
    }
    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.formUpdate();
    }));

    this.accountServise.headers.forEach((value, name) => {
      if (name.toLowerCase() !== 'content-type') {
        this.headersForUploader.push({ name: name, value: value[0] });
      }
    });

    this.subscriptions.push(EmitterService.get('DELETE_UPLOADED_FILE').subscribe(file => {
      if (!file.deleted) {
        this.accountServise.destroyAsset(this.account.id, file).subscribe(accounts => {
          if (accounts.count > 0) {
            this.account = accounts.accounts.find(account => account.id === this.account.id);
          }
        },
          err => { console.log(err); });
      }
    }));

    this.subscriptions.push(EmitterService.get('FILE_UPLOADER_EVENT').subscribe(res => {
      let response = JSON.parse(res.response);
      if (res.status <= 400) {
        this.account[response['snake_type']].push(response);
        EmitterService.get('NOTIFICATION').emit(response);
      } else if (response['errors']) {
        EmitterService.get('NOTIFICATION').emit({ error: response['errors']['attachment'][0] });
      }
    }));

    this.taxonomiesService.getTaxonomies({ 'set': 'nested' }).subscribe((taxonomies: Taxonomies) => {
      this.taxonSubscriptions.forEach(sub => sub.unsubscribe());
      this.taxonomies = taxonomies;
      taxonomies.taxonomies.forEach(taxonomy => {
        this.initTaxonControl(taxonomy);
        taxonomy.taxons.forEach(root_taxon => {
          this.initTaxonControl(root_taxon);
          root_taxon.taxons.forEach(taxon => {
            this.initTaxonControl(taxon);
          });
        });
      });
      this.fillTaxonControls();
    });
    EmitterService.get('SET_BREADCRUMB').emit(['shared.settings', 'shared.common']);
  }

  formSetup() {
    this.form = this.fb.group({
      company_name: ['', Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
      site_url: '',
      general_info: this.fb.group({
        company_phone_number: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        legal_address: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        personal_address: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        personal_phone_number: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        personal_email: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        full_name: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        position: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])]
      }),
      taxon_ids: this.fb.array([]),
      price_settings_attributes: this.fb.array([])
    });
    this.company_name = this.form.controls['company_name'];
    this.site_url = this.form.controls['site_url'];
    this.taxon_ids = this.form.controls['taxon_ids'];
    this.general_info = this.form.controls['general_info'];
    this.company_phone_number = (this.general_info as any).controls['company_phone_number'];
    this.legal_address = (this.general_info as any).controls['legal_address'];
    this.personal_phone_number = (this.general_info as any).controls['personal_phone_number'];
    this.personal_email = (this.general_info as any).controls['personal_email'];
    this.personal_address = (this.general_info as any).controls['personal_address'];
    this.full_name = (this.general_info as any).controls['full_name'];
    this.position = (this.general_info as any).controls['position'];
    this.price_settings_attributes = this.form.controls['price_settings_attributes'];
  }

  formUpdate() {
    this.site_url.patchValue(this.account.site_url);
    this.company_name.patchValue(this.account.company_name);

    this.legal_address.patchValue(this.account.general_info.legal_address);
    this.personal_address.patchValue(this.account.general_info.personal_address);
    this.company_phone_number.patchValue(this.account.general_info.company_phone_number);
    this.full_name.patchValue(this.account.general_info.full_name);
    this.personal_email.patchValue(this.account.general_info.personal_email);
    this.position.patchValue(this.account.general_info.position);
    this.personal_phone_number.patchValue(this.account.general_info.personal_phone_number);
  }

  initTaxonControl(taxon: Taxon | Taxonomy): void {
    let control = <FormArray>this.price_settings_attributes;
    let leaf = taxon.taxons.length == 0;
    
    let fg = {
      id: new FormControl(null),
      taxon_id: new FormControl(taxon.id),
      min_price: new FormControl(null),
      markup: new FormControl(null),
      last: new FormControl({value: leaf, disabled: true}),
      is_changed: new FormControl(false)
    };

    if (!leaf) {
      this.taxonSubscriptions.push(fg['min_price'].valueChanges
        .debounceTime(250)
        .subscribe(changed_value => {
          if (changed_value) {
            let targetControls = this.findTaxonChildren(fg.taxon_id.value); // taxon ids array
            targetControls.forEach(tid => {
              let fg = (this.price_settings_attributes as FormArray).controls.find((fgCtrl: FormGroup) => fgCtrl.controls.taxon_id.value == tid) as FormGroup;
              if (fg) {
                fg.controls.min_price.patchValue(changed_value);
                fg.controls.is_changed.patchValue(true);
              }
            });
          } else {
            let val = taxon['parent_id'];
            if (!val) val = taxon['taxonomy_id'];
            let fg = (this.price_settings_attributes as FormArray).controls.find((fgCtrl: FormGroup) => fgCtrl.controls.taxon_id.value == val) as FormGroup;
            if (fg && fg.controls.min_price.value != null && fg.controls.min_price.value != changed_value) {
              fg.controls.min_price.patchValue(null);
            }
          }
      }));

      this.taxonSubscriptions.push(fg['markup'].valueChanges
        .debounceTime(250)
        .subscribe(changed_value => {
          if (changed_value) {
            let targetControls = this.findTaxonChildren(fg.taxon_id.value);
            targetControls.forEach(tid => {
              let fg = (this.price_settings_attributes as FormArray).controls.find((fgCtrl: FormGroup) => fgCtrl.controls.taxon_id.value == tid) as FormGroup;
              if (fg) {
                fg.controls.markup.patchValue(changed_value);
                fg.controls.is_changed.patchValue(true);
              }
            });
          } else {
            let val = taxon['parent_id'];
            if (!val) val = taxon['taxonomy_id'];
            let fg = (this.price_settings_attributes as FormArray).controls.find((fgCtrl: FormGroup) => fgCtrl.controls.taxon_id.value == val) as FormGroup;
            if (fg && fg.controls.markup.value != null && fg.controls.markup.value != changed_value) {
              fg.controls.markup.patchValue(null);
            }
          }
      }));
    } else {
      this.taxonSubscriptions.push(fg['min_price'].valueChanges
        .debounceTime(250)
        .subscribe(changed_value => {
          let parentFg = (this.price_settings_attributes as FormArray).controls.find((fgCtrl: FormGroup) => fgCtrl.controls.taxon_id.value == taxon['parent_id']) as FormGroup;
          let fg = (this.price_settings_attributes as FormArray).controls.find((fgCtrl: FormGroup) => fgCtrl.controls.taxon_id.value == taxon['id']) as FormGroup;
          if (fg) {
            fg.controls.is_changed.patchValue(true);
            if (parentFg.controls.min_price.value != changed_value) {
              // reset parent value
              parentFg.controls.min_price.patchValue(null);
            }
          }
      }));
      this.taxonSubscriptions.push(fg['markup'].valueChanges
        .debounceTime(250)
        .subscribe(changed_value => {
          let parentFg = (this.price_settings_attributes as FormArray).controls.find((fgCtrl: FormGroup) => fgCtrl.controls.taxon_id.value == taxon['parent_id']) as FormGroup;
          let fg = (this.price_settings_attributes as FormArray).controls.find((fgCtrl: FormGroup) => fgCtrl.controls.taxon_id.value == taxon['id']) as FormGroup;
          if (fg) {
            fg.controls.is_changed.patchValue(true);
            if (parentFg.controls.markup.value != changed_value) {
              // reset parent value
              parentFg.controls.markup.patchValue(null);
            }
          }
      }));
    }
    control.push(new FormGroup(fg));
  }

  findTaxonChildren(id: number): Array<number> {
    let ids = [];
    this.taxonomies.taxonomies.forEach(taxonomy => {
      if (taxonomy.id == id) {
        taxonomy.taxons.forEach(t => ids.push(t.id));
      } else {
        taxonomy.taxons.forEach(root_taxon => {
          if (root_taxon.id == id) {
            root_taxon.taxons.forEach(t => ids.push(t.id));
          }
        });
      }
    });
    return ids;
  }

  getTaxonControl(taxon_id: number): number {
    let finder = (this.price_settings_attributes as FormArray).controls.find((fgCtrl: FormGroup) => fgCtrl.controls.taxon_id.value == taxon_id);
    return (this.price_settings_attributes as FormArray).controls.indexOf(finder);
  }

  fillTaxonControls(): void {
    this.subscriptions.push(this.taxonomiesService.getMarkups().subscribe(markups => {
      this.markups = markups;

      (this.price_settings_attributes as FormArray).controls.forEach((fgCtrl: FormGroup) => {
        let ps: PriceSetting = this.account.price_settings.find((ps: PriceSetting) => ps.taxon_id == fgCtrl.controls.taxon_id.value);
        if (ps) {
          fgCtrl.controls.id.setValue(ps.id);
          fgCtrl.controls.min_price.setValue(parseFloat(ps.min_price));
          fgCtrl.controls.markup.setValue(ps.markup);
        }

        let markup = this.markups.find((markup: Markup) => fgCtrl.controls.taxon_id.value == markup.taxon_id);
        if (markup && markup.service_commission > 0) {
          fgCtrl.controls.markup.setValidators(YougiverValidators.greaterThanOrNull(markup.service_commission));
        }
      });
    }));
  }

  isCheckedTaxon(id: number) {
    if (this.account.taxon_ids && this.account.taxon_ids.indexOf(id) < 0) {
      return false;
    } else {
      return true;
    }
  }

  assetsPath() {
    return `/api/v1/accounts/${this.account.id}/assets`;
  }

  onSubmit(value: any): void {
    let formData = this.form.value;
    (this.price_settings_attributes as FormArray).controls.forEach((fgCtrl: FormGroup, index: number) => {
      if (fgCtrl.controls.min_price.value == 0) fgCtrl.controls.min_price.patchValue(null);
      if (fgCtrl.controls.markup.value == 0) fgCtrl.controls.markup.patchValue(null);
      if (!fgCtrl.controls.last.value || !fgCtrl.controls.is_changed.value) {
        delete(this.form.value['price_settings_attributes'][index]);
      }
    });

    formData['price_settings_attributes'] = formData['price_settings_attributes'].filter(Object);

    if (this.form.valid) {
      this.invalidForm = false;
      this.loaderState = 'isLoading';

      this.accountServise.updateAccount(this.account.id, formData).subscribe((res: any) => {
        if (!res.error) {
          this.loaderState = 'isComplete';
          if (res['resource']) {
            this.accountServise.setAccount(res['resource']);
          }
        } else {
          this.loaderState = '';
        }
        EmitterService.get('NOTIFICATION').emit(res);
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  private errorMessage(control: AbstractControl): string | Object {
    if (control.errors) {
      return YougiverValidators.validationNotifications(control.errors);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.taxonSubscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private user: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
    if (this.user.loggedIn) {
      return true;
    } else {
      return this.user.getCurrentUser().map(res => {
        if (res['api_key']) {
          this.user.loggedIn = true;
          return true;
        }
        this.router.navigateByUrl('/sign');
        return false;
      }).take(1);
    }
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-profile-content-box',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})


export class ProfileContentBoxComponent {
  @Input() title: any = 'Default title';
  @Input() link: any;
  @Input() sign: string;
  @Input() mod: string;
  @Input() isShowingSecondaryData: boolean = false;
  @Input() additionalInfo: string;

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileDeveloperSettingsPaymentsComponent } from '@frontend/profile/developer/payments/payments.component';
import { AppConfig, AccountService, StaticPagesService, EmitterService } from '@frontend/@shared/services';

import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  FormControl,
  AbstractControl,
  Validators
} from '@angular/forms';



@Component({
  selector: 'yougiver-profile-developer-activation-documents-and-payments',
  template: require('./documents-and-payments.component.pug'),
  providers: [StaticPagesService],
  styles: [require('./styles/_import.sass')]
})

export class ProfileDeveloperActivationDocumentsAndPaymnetsComponent
  extends ProfileDeveloperSettingsPaymentsComponent implements OnInit, OnDestroy {

  constructor(protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected router: Router,
    protected staticPagesService: StaticPagesService,
    protected config: AppConfig) {
    super(fb, accountServise, router, config);
  }

  public headersForUploader: Array<any> = [];
  policies_accepted_at: AbstractControl;

  privacyPolicyUrl: string;
  intellectualPropertyPolicyUrl: string;

  taxDocsCount: number = 0;
  incorpDocsCount: number = 0;

  ngOnInit() {
    this.privacyPolicyUrl = this.config.getConfig('privacy_policy_page_id');
    this.intellectualPropertyPolicyUrl = this.config.getConfig('intellectual_property_policy_page_id');

    EmitterService.get('SET_BREADCRUMB').emit(['profile.activation.step3']);
    this.form = this.fb.group({
      billing_information: this.fb.group({
        type_of: 0
      }),
      tax_docs: ['', Validators.required],
      incorp_docs: ['', Validators.required],
      policies_accepted_at: [{value: false, disabled: false}, [Validators.requiredTrue]]
    });
    this.policies_accepted_at = this.form.controls['policies_accepted_at'];
    this.billing_information = this.form.controls['billing_information'];
    this.tax_docs = this.form.controls['tax_docs'];
    this.incorp_docs = this.form.controls['incorp_docs'];
    this.type_of = (this.billing_information as any).controls['type_of'];
    this.account = this.accountServise.getAccount();
    if (this.account) {
      this.formGenerator('paypall');
    }
    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.formGenerator('paypall');
    }));
    this.accountServise.headers.forEach((value, name) => {
      if (name.toLowerCase() !== 'content-type') {
        this.headersForUploader.push({ name: name, value: value[0] });
      }
    });

    this.subscriptions.push(EmitterService.get('FILE_UPLOADER_EVENT').subscribe(res => {
      let response = JSON.parse(res.response);
      if (res.status <= 400) {
        this.validateFileFormControl(res.item.options.type, true);
        this.account[response['snake_type']].push(response);
        EmitterService.get('NOTIFICATION').emit(response);
      } else if (response['errors']) {
        EmitterService.get('NOTIFICATION').emit({ error: response['errors']['attachment'][0] });
      }
    }));

    this.subscriptions.push(EmitterService.get('DELETE_UPLOADED_FILE').subscribe(file => {
      this.validateFileFormControl(file.type, false);

      if (!file.deleted) {
        this.accountServise.destroyAsset(this.account.id, file).subscribe(accounts => {
          if (accounts.count > 0) {
            this.account = accounts.accounts.find(account => account.id === this.account.id);
          }
        },
          err => { console.log(err); });
      }
    }));
  }

  assetsPath() {
    return `/api/v1/accounts/${this.account.id}/assets`;
  }

  onSubmit(value: any): void {
    if (this.account) {
      this.tax_docs.patchValue(this.account['financing_documents']);
      this.incorp_docs.patchValue(this.account['incorporation_documents']);
    }

    if (this.form.valid) {
      this.loaderState = 'isLoading';
      this.invalidForm = false;
      this.accountServise.updateAccount(this.account.id, this.form.value).subscribe((res: any) => {
        EmitterService.get('NOTIFICATION').emit({info: 'profile.activation.sent_to_moderation'});
        if (!res.error) {
          this.loaderState = 'isComplete';
          if (res['resource']) {
            this.accountServise.setAccount(res['resource']);
          }
        } else {
          this.loaderState = '';
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  isReadyToActivate(): boolean {
    return this.account.state === 'activation_step1'
  }

  previousStep() {
    this.router.navigateByUrl('/profile/developer/activation/main-info-and-products');
  }

  buttonText(): string {
    if(this.account.state === 'activation_step1') {
      return 'profile.activation.finish';
    } else if((this.account.state === 'pending') || (this.account.state === 'declined')) {
      return 'profile.activation.update_info';
    }
  }

  validateFileFormControl(docType, uploaded: boolean) {
    let controlName = (docType == 'FinancingDocument' ? 'tax_docs' : 'incorp_docs');
    let counterName = (docType == 'FinancingDocument' ? 'taxDocsCount' : 'incorpDocsCount');
    if(uploaded) {
      this[counterName] += 1;
      this[controlName].setValue(true);
    } else {
      this[counterName] -= 1;
      this[controlName].setValue((this[counterName] > 0) ? true : null);
    }
  }
}

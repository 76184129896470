import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'yougiver-profile-success-message',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileSuccessMessageComponent implements OnChanges {
  @Input() text: string = 'Success';
  @Input() show: boolean = false;
  @Input() hideTimeout: number = 5;

  private _show: boolean = false

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.show) {
      return
    }

    if (!changes.show.currentValue) {
      this._show = false
      return
    }

    if (!this.hideTimeout) {
      this._show = true
      return
    }

    if (!changes.show.previousValue && changes.show.currentValue) {
      this.toggleShow()
    }
  }

  toggleShow() {
    this._show = true

    setTimeout(() => {
      this._show = false
    }, this.hideTimeout * 1000)
  }
}

import { ProfileProductsComponent } from '@frontend/profile/products/products.component';
import { AccountTypeGuard } from '@frontend/@shared/services';
import { ProfileProductsListComponent } from '@frontend/profile/products/list/list.component';
import { ProfileProductsAddComponent } from '@frontend/profile/products/add/add.component';

export const profileProductsRoutes = {
  path: 'products',
  component: ProfileProductsComponent,
  canActivate: [AccountTypeGuard],
  children: [
    {
      path: 'add',
      component: ProfileProductsAddComponent,
      data: {
        type_of: 'product'
      }
    },
    {
      path: 'add_certificate',
      component: ProfileProductsAddComponent,
      data: {
        type_of: 'certificate'
      }
    },
    {
      path: 'edit/:slug',
      component: ProfileProductsAddComponent,
    },
    {
      path: '',
      component: ProfileProductsListComponent,
    }
  ]
};
import { SignInComponent } from '@frontend/sign/sign-in/sign-in.component';
import { SignUpComponent } from '@frontend/sign/sign-up/sign-up.component';
import { LoggedOutGuard } from '@frontend/@shared/services/logged-out.guard';
import { SignRestoreComponent } from '@frontend/sign/sign-restore/sign-restore.component';
import { SignConfirmationComponent } from '@frontend/sign/sign-confirmation/sign-confirmation.component';
import { SignComponent } from '@frontend/sign/sign.component';
import { SignUpSellerComponent } from '@frontend/sign/sign-seller/sign-up-seller.component';
import { SignInSellerComponent } from '@frontend/sign/sign-seller/sign-in-seller.component';
import { SignUpDevComponent } from '@frontend/sign/sign-dev/sign-up-dev.component';
import { SignInDevComponent } from '@frontend/sign/sign-dev/sign-in-dev.component';
import { SignUpMassMediaComponent } from '@frontend/sign/sign-mass-media/sign-up-mass-media.component';
import { SignInMassMediaComponent } from '@frontend/sign/sign-mass-media/sign-in-mass-media.component';
import { SignUpShopComponent } from '@frontend/sign/sign-shop/sign-up-shop.component';
import { SignInShopComponent } from '@frontend/sign/sign-shop/sign-in-shop.component';
import { SignInInfluencerComponent } from '@frontend/sign/sign-influencer/sign-in-influencer.component';
import { SignUpInfluencerComponent } from '@frontend/sign/sign-influencer/sign-up-influencer.component';

export const signRoutes = {
  path: 'sign',
  component: SignComponent,
  canActivate: [LoggedOutGuard],
  children: [
    {
      path: '',
      component: SignUpComponent
    },
    {
      path: 'up',
      component: SignUpComponent
    },
    {
      path: 'up/seller',
      component: SignUpSellerComponent,
    },
    {
      path: 'in/seller',
      component: SignInSellerComponent,
    },
    {
      path: 'up/dev',
      component: SignUpDevComponent,
    },
    {
      path: 'in/dev',
      component: SignInDevComponent,
    },
    {
      path: 'up/mass-media',
      component: SignUpMassMediaComponent,
    },
    {
      path: 'in/mass-media',
      component: SignInMassMediaComponent,
    },
    {
      path: 'up/shop',
      component: SignUpShopComponent,
    },
    {
      path: 'in/shop',
      component: SignInShopComponent,
    },
    {
      path: 'up/influencer',
      component: SignUpInfluencerComponent,
    },
    {
      path: 'in/influencer',
      component: SignInInfluencerComponent,
    },
    {
      path: 'restore',
      component: SignRestoreComponent
    },
    {
      path: 'confirmation/:confirmation_token/confirm',
      component: SignConfirmationComponent
    },
    {
      path: 'reset_password/:reset_password_token/reset',
      component: SignRestoreComponent
    }
  ]
};
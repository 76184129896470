import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-dropdown-msg',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class DropdownMsgComponent {

  @Input() data: string;

}

import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Helper } from '@frontend/@shared/services';

@Injectable()
export class WebhookLogsService {
  public headers;
  public options;
  private webhookLogsUrl = '/api/v1/webhook_logs';
  private apiKey: string;

  constructor(private router: Router, private route: ActivatedRoute,
              private http: Http, private ls: CoolLocalStorage) {
    this.apiKey = this.ls.getItem('auth_token');
    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Yougiver-Token': this.apiKey
    });
    this.options = new RequestOptions({ headers: this.headers });
  }

  public setQueryParams(params: Object) {
    return this.route.queryParams.subscribe(queryParams => {
      if (Object.keys(params).length !== 0) {
        let newQueryParams = JSON.parse(JSON.stringify(queryParams));
        Object.assign(newQueryParams, params);
        let navigationExtras: NavigationExtras = {
          queryParams: newQueryParams
        };
        this.router.navigate(['profile/webhook-logs'], navigationExtras);
        params = {};
      }
    });
  }

  public getWebhookLogs(params: Object = undefined): Observable<any> {
    if (params) {
      params = Helper.transformQueryParams(params);
      this.options.search = new URLSearchParams(Helper.toParams(params, this.ls));
    }
    return this.http.get(this.webhookLogsUrl, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

}

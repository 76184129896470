import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'yougiver-profile-button',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileButtonComponent {
  @Input() text: string;
  @Input() ico: string;
  @Output() clicked = new EventEmitter<boolean>();

  onClick() {
    this.clicked.emit(true);
  }
}

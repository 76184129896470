import { Component, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'yougiver-sign',
  encapsulation: ViewEncapsulation.None,
  template: require('./sign.template.pug'),
  styles: [require('./@styles/_import.sass')]
})

export class SignComponent {

}

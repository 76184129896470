import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'yougiver-sign-form',
  encapsulation: ViewEncapsulation.None,
  styles: [require('./@shared/@styles/_import.sass')],
  template: require('./sign-form.component.pug')
})

export class SignFormComponent {

}

import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FileUploader } from '@frontend/@shared/components/ng2-file-upload/file-uploader.class';
import { YougiverValidators } from '@frontend/@shared/directives';
import { EmitterService } from '@frontend/@shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-upload-photo-single',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileUploadPhotoSingleComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  public hasDropZone: boolean = false;
  public uploader: any;

  private isUploading: boolean = false;
  private isFileAdded: boolean = false;

  @Input() url: string;
  @Input() headers: any;
  @Input() prepopulated: any;
  @Input() viewableType: string = 'Product';
  @Input() position: number = undefined;
  @Input() type: string = 'Image';
  @Input() icon: string = '#upload';
  @Input() tip: string;
  @Input() disabled: boolean;

  @Output() fileAdded = new EventEmitter();
  @Output() fileDeleted = new EventEmitter();
  
  @ViewChild('uploaderInput') uploaderInput: ElementRef;

  constructor() { }

  ngOnInit() {
    this.subscriptions.push(EmitterService.get('STATUS_FILE_UPLOADER_EVENT').subscribe((res: boolean) => {
      this.isUploading = res;

      if (!res) {
        this.isFileAdded = false
      }
    }));
    
    this.uploader = new FileUploader({
      url: this.url,
      isHTML5: true,
      autoUpload: true,
      headers: this.headers,
      itemAlias: 'attachment',
      viewableType: this.viewableType,
      position: this.position,
      type: this.type,
      allowedMimeType: [
        'image/jpeg',
        'image/png',
        'image/svg+xml'
      ]
    });

    this.uploader.onWhenAddingFileFailed = YougiverValidators.fileValidation;
    this.uploader.onAfterAddingFile = () => {
      this.isFileAdded = true
      this.fileAdded.emit()
    }
  }

  public fileOverZone(e: any): void {
    this.hasDropZone = e;
  }

  destroy(item: any): void {
    EmitterService.get('DELETE_UPLOADED_FILE').emit(item);

    this.fileDeleted.emit()
  }

  update() {
    this.uploaderInput.nativeElement.click()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AvailableStates } from '@frontend/@shared/types';

@Component({
  selector: 'yougiver-profile-order-preview-status',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileOrderPreviewStatusComponent {
  @Input() availableStates: AvailableStates;
  @Input() currentState: string;
  @Output() nextState = new EventEmitter<string>();
  private isActive: boolean = false;
  toState(event: string) {
    this.nextState.emit(event);
    this.isActive = false;
  }
}

import { Component, forwardRef, Input, AfterContentInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'yougiver-profile-select',
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ProfileSelectComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ProfileSelectComponent), multi: true }
  ],
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileSelectComponent implements ControlValueAccessor, AfterContentInit {
  @Input() label: string;
  @Input() _value: string;
  @Input() items: any;

  private _active: any;

  @Output() public SrefreshValue = new EventEmitter<any>();
  @Output() public Sselected = new EventEmitter<any>();
  @Output() public Sremoved = new EventEmitter<any>();
  @Output() public Styped = new EventEmitter<any>();

  onTouchedCallback: () => void;
  propagateChange: any = () => {};
  validateFn: any = () => {};

  set value(value: string) {
    this._value = value || '';
    this.propagateChange(value);
  }
  get value() {
    return this._value;
  }

  @Input()
  public set active(selectedItem: any) {
    this._active = selectedItem ? [selectedItem] : [];
  }

  ngAfterContentInit() {
    this.writeValue(this._value);
    this.propagateChange(this._value);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  public selected(value) {
    this.writeValue(value.id);
    this.propagateChange(value.id);
    // console.log('selected ', value);
    this.Sselected.emit(value);
  }

  public refreshValue(value) {
    // console.log('refreshValue ', value);
    this.SrefreshValue.emit(value);
  }

  public removed(value) {
    // console.log('removed ', value);
    this.Sremoved.emit(value);
  }

  public typed(value) {
    // console.log('typed ', value);
    this.Styped.emit(value);
  }
}

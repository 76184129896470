import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yougiver-profile-actions-button',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileActionsButtonComponent implements OnInit {
  @Input() mod: string | Array<string>;
  @Input() ico: any = '';
  @Input() text: any = 'Default label';
  @Input() loaderState: any;

  ngOnInit() {
    if (this.mod instanceof Array) {
      this.mod = this.mod.join(' ');
    }
  }
}

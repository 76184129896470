import { NgModule } from '@angular/core';
import { PaymentComponent } from '@frontend/payment/payment.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule
  ],
  declarations: [
    PaymentComponent
  ],
  exports: [
    PaymentComponent
  ]
})
export class PaymentModule {}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-profile-actions-link',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileActionsLinkComponent {
  @Input() mod: any = '';
  @Input() ico: any = '';
  @Input() text: any = 'Default label';
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrderService, EmitterService } from '@frontend/@shared/services';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-content-tabs',
  styles: [require('./styles/_import.sass')],
  providers: [OrderService],
  template: require('./template.pug'),
})

export class ProfileContentTabsComponent implements OnInit, OnDestroy {
  @Input() tabList: any;
  private showTabs: boolean = true;
  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute, private orderService: OrderService) { }

  ngOnInit() {
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      if (queryParams['state']) {
        this.tabList.forEach(i => i.active = false);
        let item = this.tabList.find(item => item['state'] === queryParams['state']);
        item.active = true;
      }
      else {
        this.tabList.forEach(i => i.active = false);
        this.tabList.find(item => item['state'] === 'all').active = true;
      }
    }));

    EmitterService.get('SHOW_TABS').subscribe(show => {
      this.showTabs = show;
    });
  }

  setActive(item): void {
    if (item['state']) {
      this.subscriptions.push(
        this.orderService.setQueryParams({ state: item['state'] })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { AccountService } from '@frontend/@shared/services';
import { Accounts } from '@frontend/@shared/types';

@Injectable()
export class AccountTypeGuard implements CanActivate {

  constructor(private router: Router, private ls: CoolLocalStorage, private accountService: AccountService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.ls.getItem('accountType')) {
      return this.routesRules(this.ls.getItem('accountType'), state['url']);
    } else {
      let id = this.ls.getItem('accountId');
      if (!id) {
        id = this.ls.getItem('latestAccount');
      }
      return this.accountService.getAccounts({account_id: id}).map((accounts: Accounts) => {
        try {
          let accountType = id === null ? accounts.accounts[0]['account_type'] : accounts.accounts.find(i => i.id === +id)['account_type'];
          if (accountType) {
            this.ls.setItem('accountType', accountType);
            return this.routesRules(accountType, state['url'], accounts.accounts[0]['state']);
          } else {
            throw new Error('Not enough rights')
          }
        } catch {
          this.router.navigateByUrl('/sign');
          return false;
        }
      }).take(1);
    }
  }

  routesRules(accountType: string, url: string, state?: string): boolean {
    // TODO: refactor this method for the love of God
    if ((accountType === 'Developer') || (accountType === 'Seller' && (state !== 'pending') && (url.includes('products') || url.includes('orders')))) {
      return true;
    } else if ((accountType === 'Seller') && (state) && (state === 'activated')) {
      this.router.navigateByUrl('/profile/orders/list');
      return true;
    } else if ((accountType === 'Seller') && (state) && (state === 'activatation_step1')) {
      this.router.navigateByUrl('/profile/activation/documents-and-payments');
      return false;
    } else if ((accountType === 'Seller') && (state) && (state === 'activation_step2')){
      this.router.navigateByUrl('/profile/activation/goods-and-schedule');
      return false;
    } else if (accountType === 'Seller') {
      this.router.navigateByUrl('/profile/activation/main-info')
      return false;
    } else if ((accountType === 'Developer') && (state) && (state === 'activated')){
      this.router.navigateByUrl('/profile/balance/common');
      return false;
    } else if ((accountType === 'Developer') && (state) && (state === 'activation_step1')){
      this.router.navigateByUrl('/profile/developer/activation/documents-and-payments');
      return false;
    } else if (accountType === 'Developer'){
      this.router.navigateByUrl('/profile/developer/activation/main-info-and-products');
      return false;
    }
  }
}

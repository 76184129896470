import { Directive, ElementRef, Input, Renderer, OnInit, OnChanges } from '@angular/core';

export const LOADER_STATES = {none: '', isLoading: 'is-loading', isComplete: 'is-loading-success'};

@Directive({
  selector: '[yougiverElementLoader]'
})
export class ElementLoaderDirective implements OnInit, OnChanges {
  private _oldClasses: string = '';
  private timer: number = 0;

  @Input() loaderState: string;

  constructor(private el: ElementRef, private renderer: Renderer) { }

  ngOnInit() {
    this.timer = 0;
    this._oldClasses = this.el.nativeElement.getAttribute('class');
  }

  ngOnChanges(changes: any) {
    if (changes.loaderState.currentValue && changes.loaderState.currentValue.length > 0) {
      setTimeout(() => {
        this.loaderClass(LOADER_STATES[changes.loaderState.currentValue]);
        this.timer = this.timer + 500;
      }, this.timer);
    }
  }

  private loaderClass(value: string) {
    this.renderer.setElementAttribute(this.el.nativeElement, 'class', `${this._oldClasses} ${value}`);
  }
}

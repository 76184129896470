import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { ProfileDeveloperSettingsCommonComponent } from '@frontend/profile/developer/common/common.component';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
  FormArray,
  FormControl
} from '@angular/forms';
import { YougiverValidators } from '@frontend/@shared/directives';
import { AccountService, TaxonomiesService, EmitterService } from '@frontend/@shared/services';
import { Account, Taxonomies } from '@frontend/@shared/types';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router } from '@angular/router';


@Component({
  selector: 'yougiver-profile-developer-activation-main-info-and-products',
  styles: [require('./styles/_import.sass')],
  template: require('./main-info-and-products.component.pug'),
  providers: [TaxonomiesService]
})

export class ProfileDeveloperActivationMainInfoAndProductsComponent
  extends ProfileDeveloperSettingsCommonComponent implements OnInit {

  constructor(protected _changeDetectionRef: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected taxonomiesService: TaxonomiesService,
    protected router: Router,
    protected ls: CoolLocalStorage,
    protected zone: NgZone) {
    super(_changeDetectionRef, fb, accountServise, taxonomiesService, router, ls, zone);
  }

  ngOnInit() {
    EmitterService.get('SET_BREADCRUMB').emit(['profile.developer.activation']);
    this.formSetup();
    this.account = this.accountServise.getAccount();
    if (this.account) {
      this.formUpdate();
    }
    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.formUpdate();
    }));
  }

  formSetup() {
    this.form = this.fb.group({
      company_name: ['', Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
      site_url: '',
      general_info: this.fb.group({
        company_phone_number: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],

        legal_address: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],

        personal_address: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],

        personal_phone_number: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],

        personal_email: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],

        full_name: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],

        position: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])]
      })
    });
    this.company_name = this.form.controls['company_name'];
    this.site_url = this.form.controls['site_url'];

    this.general_info = this.form.controls['general_info'];
    this.company_phone_number = (this.general_info as any).controls['company_phone_number'];
    this.legal_address = (this.general_info as any).controls['legal_address'];
    this.personal_phone_number = (this.general_info as any).controls['personal_phone_number'];
    this.personal_email = (this.general_info as any).controls['personal_email'];
    this.personal_address = (this.general_info as any).controls['personal_address'];
    this.full_name = (this.general_info as any).controls['full_name'];
    this.position = (this.general_info as any).controls['position'];
  }

  formUpdate() {
    this.site_url.patchValue(this.account.site_url);
    this.company_name.patchValue(this.account.company_name);
    for (let key in this.account.general_info) {
      if (this.account['general_info'][key]) {
        this[key].patchValue(this.account['general_info'][key]);
      }
    }
  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      this.invalidForm = false;
      this.loaderState = 'isLoading';
      this.accountServise.updateAccount(this.account.id, this.form.value).subscribe((res: any) => {
        if (!res.error) {
          this.loaderState = 'isComplete';
          if (res['resource']) {
            this.accountServise.setAccount(res['resource']);
          }
          this.router.navigate(['profile/developer/activation/documents-and-payments']);
        } else {
          this.loaderState = '';
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  destroyAccount() {
    EmitterService.get('DESTROY_ACCOUNT').emit(this.ls.getItem('accountId'));
  }

  buttonText(): string {
    if(this.account && (this.account.state === 'initial')) {
      return 'profile.activation.next_step';
    } else {
      return 'profile.activation.update_info';
    }
  }
}

import { Component, Input, OnDestroy, OnChanges } from '@angular/core';
import {
  ProductService, CodesService, TransactionService, OrderService,
  WebhookLogsService, WebhooksService
} from '@frontend/@shared/services';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'yougiver-profile-pagination',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
  providers: [ProductService, CodesService, TransactionService, OrderService, WebhooksService, WebhookLogsService]
})

export class ProfilePaginationComponent implements OnDestroy, OnChanges {
  @Input() paginatorName: string;
  @Input() currentPage: number;
  @Input() pages: number;
  @Input() perPage: number;
  @Input() productSlug: string;

  private subscriptions: Array<Subscription> = [];
  private pagesArray: Array<number>;

  constructor(private router: Router, private route: ActivatedRoute, private productService: ProductService,
    private codesService: CodesService, private transactionService: TransactionService, private orderService: OrderService,
    private webhooksService: WebhooksService, private webhookLogsService: WebhookLogsService) { }

  ngOnChanges(changes: any) {
    if (changes.pages) {
      this.pagesArray = new Array(changes.pages.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  toPage(page_num: number): void {
    this.emitNewParams({ page: page_num });
  }

  nextPage(): void {
    this.emitNewParams({ page: this.currentPage + 1 });
  }

  previousPage(): void {
    this.emitNewParams({ page: this.currentPage - 1 });
  }

  private emitNewParams(params: any): void {
    if (this.paginatorName === 'PRODUCT_LIST_PARAMS') {
      this.subscriptions.push(
        this.productService.setQueryParams(params));
    } else if (this.paginatorName === 'CODES_LIST_PARAMS') {
      this.subscriptions.push(
        this.codesService.setQueryParams(params, this.productSlug));
    } else if (this.paginatorName === 'TRANSACTIONS_LIST_PARAMS') {
      this.subscriptions.push(
        this.transactionService.setQueryParams(params));
    } else if (this.paginatorName === 'ORDERS_LIST_PARAMS') {
      this.subscriptions.push(
        this.orderService.setQueryParams(params));
    } else if (this.paginatorName === 'WEBHOOKS_LIST_PARAMS') {
      this.subscriptions.push(
        this.webhooksService.setQueryParams(params));
    } else if (this.paginatorName === 'WEBHOOK_LOGS_LIST_PARAMS') {
      this.subscriptions.push(
        this.webhookLogsService.setQueryParams(params));
    }
  }
}

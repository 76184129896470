import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-profile-input-tooltip',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileInputTooltipComponent {
  @Input() modification: any = '';
  @Input() label: any = 'Default label';
  @Input() value: any = '';
}

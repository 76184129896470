import { ModuleWithProviders, NgModule, Optional, SkipSelf }  from '@angular/core';
import { CommonModule }  from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { UserService, AccountService, TranslationsService, SellersService, EmitterService } from '@frontend/@shared/services';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule
  ],
  providers: [
    UserService,
    AccountService,
    TranslationsService,
    EmitterService,
    SellersService
  ]
})
export class CoreModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        UserService,
        AccountService,
        TranslationsService,
        EmitterService,
        SellersService
      ]
    };
  }
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { EmitterService } from '@frontend/@shared/services';

@Component({
  selector: 'yougiver-notification',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class NotificationComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() link: string;
  @Input() ico: string;

  ngOnInit() {
  }

  closeNotification() {
    EmitterService.get('NOTIFICATION').emit(undefined);
  }
}

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';

@Injectable()
export class AppConfig {

  private apiEndpoint: string = '/api/v1';
  private config: Object = null;

  constructor(private http: Http, private ls: CoolLocalStorage) {}

  /**
   * Use to get the data found in the config file
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  public load() {
    return new Promise((resolve, reject) => {
      let request: any = null;
      let  locale = this.ls.getItem('locale');
      if (!locale) {
        locale = (navigator.language || navigator['userLanguage']).slice(0, 2);
      }

      request = this.http.get(`${this.apiEndpoint}/config.json?locale=${locale}`);

      if (request) {
        request
          .map( res => res.json() )
          .catch((error: any) => {
            console.error('Error reading configuration file');
            resolve(error);
            return Observable.throw(error.json().error || 'Server error');
          })
          .subscribe((responseData) => {
            this.config = responseData;
            resolve(true);
          });
      } else {
        console.error('Env is not valid');
        resolve(true);
      }
    });
  }
}

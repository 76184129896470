import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EmitterService, ProductService, TaxonomiesService, AccountService } from '@frontend/@shared/services';
import { Taxonomies, Account } from '@frontend/@shared/types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-nav',
  providers: [TaxonomiesService, ProductService],
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileNavComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  @Input() accountType: string;

  public items: any;
  public taxonomies$: Taxonomies;
  public account: Account; 


  constructor(public router: Router, public route: ActivatedRoute,
    private taxonomiesService: TaxonomiesService,
    private productService: ProductService,
    private accountService: AccountService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.subscriptions.push(EmitterService.get('LOCALE').subscribe(locale => {
      this.getTaxonomies()
    }));

    this.account = this.accountService.getAccount();

    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.getTaxonomies();
    }));

    if (this.accountType === 'Seller') {
      this.getTaxonomies();
      this.items = [
        {
          text: 'profile.navbar.settings.shop_by_link',
          sref: '/profile/settings/shop_by_link',
          class: { 'nav-shop-by-link': true },
        },
        // {
        //   text: 'profile.navbar.settings.auction_by_link',
        //   sref: '/profile/settings/auction_by_link',
        //   class: { 'nav-shop-by-link': true },
        // },
        // {
        //   text: 'profile.navbar.settings.charity_by_link',
        //   sref: '/profile/settings/charity_by_link',
        //   class: { 'nav-shop-by-link': true },
        // },
        {
          text: 'profile.navbar.orders',
          ico: 'list',
          notification: 0,
          addClass: this.checkDropdownUrl('orders'),
          sref: '/profile/orders/list'
        },
        {
          text: 'profile.navbar.products',
          ico: 'products',
          addClass: this.checkDropdownUrl('products')
        },
        {
          text: 'profile.navbar.balance.name',
          ico: 'balance',
          sref: '/profile/operations',
          addClass: this.checkDropdownUrl('operations'),
          list: [
            { text: 'profile.navbar.balance.common_balance', sref: '/profile/balance/common' },
            { text: 'profile.navbar.balance.list_all', sref: '/profile/operations' }
          ]
        },
        {
          text: 'shared.settings',
          ico: 'settings',
          addClass: this.checkDropdownUrl('settings'),
          list: [
            { text: 'profile.navbar.settings.common_info', sref: '/profile/settings/common_info' },
            { text: 'profile.navbar.settings.categories', sref: '/profile/settings/categories' },
            { text: 'profile.navbar.settings.profile', sref: '/profile/settings/general' },
            { text: 'profile.navbar.settings.payment_data', sref: '/profile/settings/payments' },
            { 
              text: 'profile.navbar.settings.integrations', 
              sref: '/profile/settings/integrations',
              class: { 'nav-shop-by-link': true },  
            },
          ]
        }
      ];
    }
    else if (this.accountType === 'Developer') {
      this.items = [
        {
          text: 'profile.navbar.balance.name',
          ico: 'balance',
          sref: '/profile/operations',
          addClass: this.checkDropdownUrl('operations'),
          list: [
            { text: 'profile.navbar.balance.common_balance', sref: '/profile/balance/common' },
            { text: 'profile.navbar.balance.all', sref: '/profile/operations' }
          ]
        },
        {
          text: 'profile.navbar.orders',
          ico: 'list',
          notification: 0,
          addClass: this.checkDropdownUrl('orders'),
          sref: '/profile/orders/list'
        },
        {
          text: 'shared.settings',
          ico: 'settings',
          addClass: this.checkDropdownUrl('settings'),
          list: [
            { text: 'shared.common', sref: '/profile/developer/settings/common' },
            { text: 'profile.navbar.account', sref: '/profile/settings/general' },
            { text: 'profile.navbar.settings.payment_data', sref: '/profile/developer/settings/payments' }
          ]
        },
        {
          text: 'profile.developer.api_keys',
          ico: 'list',
          sref: '/profile/keys'
        },
        {
          text: 'Webhooks',
          ico: 'list',
          sref: '/profile/webhooks'
        },
        {
          text: 'profile.navbar.other.webhook_logs',
          ico: 'list',
          sref: '/profile/webhook-logs'
        }
      ];
    }

  }

  getTaxonomies() {
    this.subscriptions.push(this.taxonomiesService.getTaxonomies().subscribe((res: Taxonomies) => {
      this.taxonomies$ = res;

      let taxonomies = []

      if (this.account.taxonomies && this.account.taxonomies.length) {
        taxonomies = this.taxonomies$.taxonomies.filter((taxon) => {
          return this.account.taxonomies.find(({id}) => id === taxon.id)
        });
      }

      if (!taxonomies.length) taxonomies = this.taxonomies$.taxonomies

      let taxons = [];
      for (let taxon of taxonomies) {
        taxons.push({
          id: +taxon.id, text: taxon.name, sref: '/profile/products',
          params: { taxonomy: taxon.id }
        });
      }
      if (this.items.find(item => item.text === 'profile.navbar.products')) {
        this.items.find(item => item.text === 'profile.navbar.products').list = taxons;
      }
      let firstTaxonomy = taxonomies[0];
      if (firstTaxonomy) { firstTaxonomy.isActive = true; }
    }));
  }

  checkDropdownUrl(keyword: string): boolean {
    return this.router.url.indexOf(keyword) > 0 ? true : false;
  }

  selectedItem(params: string | Object): boolean {
    if (params instanceof Object) {
      const queryParams = this.router.parseUrl(this.router.url).queryParams
      const currentTaxonomy = parseInt(queryParams['taxonomy'])
      
      return currentTaxonomy === params['taxonomy'];
    }

    return this.router.url.includes((params as string).substr(1));
  }

  setTaxon(taxonomy_id: number) {
    EmitterService.get('ACTIVE_MOBILE_MENU').emit(false);
    if (taxonomy_id) {
      let navigationExtras: NavigationExtras = {
        queryParams: { taxonomy: taxonomy_id }
      };
      this.router.navigate(['/profile/products'], navigationExtras);
    }
  }

  mobileMenu(item: string) {
    if (item) {
      EmitterService.get('ACTIVE_MOBILE_MENU').emit(false);
    }
  }

  public sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)['changingThisBreaksApplicationSecurity'];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

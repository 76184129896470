import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EmitterService } from '@frontend/@shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-products',
  template: `<router-outlet></router-outlet>`,
})

export class ProfileProductsComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  constructor(private router: Router) {}
  ngOnInit() {
    this.subscriptions.push(EmitterService.get('ACCOUNT_ID').subscribe(id => {
      this.router.navigate(['/profile/products']);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

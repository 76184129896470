import { AccountTypeGuard } from '@frontend/@shared/services';
import { ProfileDeveloperSettingsPaymentsComponent } from '@frontend/profile/developer/payments/payments.component';
import { ProfileDeveloperActivationDocumentsAndPaymnetsComponent } from '@frontend/profile/developer/documents-and-payments/documents-and-payments.component';
import { ProfileDeveloperSettingsCommonComponent } from '@frontend/profile/developer/common/common.component';
import { ProfileDeveloperActivationMainInfoAndProductsComponent } from '@frontend/profile/developer/main-info-and-products/main-info-and-products.component';

export const profileDeveloperRoutes = {
  path: 'developer',
  canActivate: [AccountTypeGuard],
  children: [
    {
      path: 'settings',
      children: [
        {
          path: 'common',
          component: ProfileDeveloperSettingsCommonComponent
        },
        {
          path: 'payments',
          component: ProfileDeveloperSettingsPaymentsComponent
        }
      ]
    },
    {
      path: 'activation',
      children: [
        {
          path: 'main-info-and-products',
          component: ProfileDeveloperActivationMainInfoAndProductsComponent
        },
        {
          path: 'documents-and-payments',
          component: ProfileDeveloperActivationDocumentsAndPaymnetsComponent
        }
      ]
    }
  ]
};
import { NgModule } from '@angular/core';
import { MyDateRangePickerModule } from '@frontend/@shared/components/date-range-picker/my-date-range-picker.module';
import { FileUploadModule } from '@frontend/@shared/components/ng2-file-upload/file-upload.module';
import { SelectModule } from '@frontend/@shared/components/ng2-select/select.module';
import { SwitchMenuComponent } from '@frontend/@shared/components/switch-menu/component';
import { SimpleTextComponent } from '@frontend/@shared/components/simple-text/component';
import { PopupComponent } from '@frontend/@shared/components/popup/component';
import { PopupChangeLangComponent } from '@frontend/@shared/components/popup/change-lang/component';
import { PopupChangeAccountComponent } from '@frontend/@shared/components/popup/change-account/component';
import { NotificationComponent } from '@frontend/@shared/components/notification/component';
import { LogoutComponent } from '@frontend/@shared/components/logout/component';
import { LoaderComponent } from '@frontend/@shared/components/loader/component';
import { DropdownMsgComponent } from '@frontend/@shared/components/dropdown-msg/component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ElementLoaderDirective } from '@frontend/@shared/directives/button-loader.directive';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { HighlightJsContentDirective } from 'angular2-highlight-js/lib/highlight-js-content.directive';
import { ProfileButtonComponent } from '@frontend/@shared/components/button/component';
import { ProfileInputComponent } from '@frontend/@shared/components/input/component';
import { ProfileInputTooltipComponent } from '@frontend/@shared/components/input/tooltip/component';
import { ProfileInputSmallComponent } from '@frontend/@shared/components/input/small/component';
import { ProfileLinkComponent } from '@frontend/@shared/components/link/component';
import { HeaderComponent } from '@frontend/@shared/components/header/component';
import { FooterModule } from '@frontend/@shared/components/footer/module';
import { GoogleFormInputComponent } from '@frontend/@shared/components/google_input/input.component';
// import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    MyDateRangePickerModule,
    FileUploadModule,
    SelectModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserModule,
    CommonModule,
    DirectivesModule,
    FooterModule,
    // TextMaskModule
  ],
  declarations: [
    ElementLoaderDirective,
    HighlightJsContentDirective,
    SwitchMenuComponent,
    SimpleTextComponent,
    PopupComponent,
    PopupChangeLangComponent,
    PopupChangeAccountComponent,
    NotificationComponent,
    LogoutComponent,
    LoaderComponent,
    DropdownMsgComponent,
    ProfileButtonComponent,
    ProfileInputComponent,
    ProfileInputTooltipComponent,
    ProfileInputSmallComponent,
    ProfileLinkComponent,
    HeaderComponent,
    GoogleFormInputComponent,
  ],
  exports: [
    ElementLoaderDirective,
    HighlightJsContentDirective,
    MyDateRangePickerModule,
    FileUploadModule,
    SelectModule,
    SwitchMenuComponent,
    SimpleTextComponent,
    PopupComponent,
    PopupChangeLangComponent,
    PopupChangeAccountComponent,
    NotificationComponent,
    LogoutComponent,
    LoaderComponent,
    DropdownMsgComponent,
    ProfileButtonComponent,
    ProfileInputComponent,
    ProfileInputTooltipComponent,
    ProfileInputSmallComponent,
    ProfileLinkComponent,
    HeaderComponent,
    GoogleFormInputComponent,
    FooterModule,
  ]
})
export class SharedComponentsModule {}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { ProductService, TaxonomiesService } from '@frontend/@shared/services';

import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-product-sortable',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
  providers: [ProductService]
})

export class ProfileProductSortableComponent implements OnInit, OnDestroy {
  private sortable: Object = {};
  private subscriptions: Array<Subscription> = [];
  // private typeOfProductsData: Object = {
  //   product: {
  //     name: 'shared.product',
  //     sref: ['/profile/products/add']
  //   },
  //   certificate: {
  //     name: 'shared.certificate',
  //     sref: ['/profile/products/add_certificate']
  //   }
  // };
  private productTypeOf: Object = { name: 'shared.product', sref: ['/profile/products/add_certificate'] };

  private taxonomy: number;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    private taxonomiesService: TaxonomiesService
  ) { }

  ngOnInit() {
    // let getTaxonomies = this.taxonomiesService.getTaxonomies();
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.taxonomy = queryParams.taxonomy;
      // this.subscriptions.push(getTaxonomies.subscribe(taxonomies => {
      //   let finder = taxonomies.taxonomies.find(taxonomy => +taxonomy['id'] === +queryParams['taxonomy']);
      //   if (finder) {
      //     this.productTypeOf = this.typeOfProductsData[finder['type_of']];
      //   } else {
      //     this.productTypeOf = this.typeOfProductsData['product'];
      //   }
      // }));
      if (queryParams['sortable']) {
        this.setActiveSorting(queryParams['sortable']);
      }
      else {
        this.sortable = {};
      }
    }));
  }

  setActiveSorting(sorting: string) {
    this.sortable[sorting.match(/by_(.*)/)[0]] = sorting.match(/(.*)_by_/)[1];
  }

  orderColumn(column: string): void {
    if (this.sortable[column]) {
      if (this.sortable[column] === 'ascend') {
        this.sortable[column] = 'descend';
      } else {
        this.sortable[column] = 'ascend';
      }
    } else {
      this.sortable = {};
      this.sortable[column] = 'ascend';
    }
    let params = [];
    for (let name in this.sortable) {
      if (this.sortable[name as string]) {
        params.push(`${this.sortable[name as string]}_${name}`);
      }
    }
    this.subscriptions.push(
      this.productService.setQueryParams({ sortable: params })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { LoggedInGuard } from '@frontend/@shared/services/logged-in.guard';
import { ProfileComponent } from '@frontend/profile/profile.component';
import { profileActivationRoutes } from '@frontend/profile/activation/activation.routing';
import { profileDeveloperRoutes } from '@frontend/profile/developer/developer.routing';
import { profileOperationsRoutes } from '@frontend/profile/operations/operations.routing';
import { profileProductsRoutes } from '@frontend/profile/products/products.routing';
import { profileBalanceRoutes } from '@frontend/profile/balance/balance.routing';
import { profileOrdersRoutes } from '@frontend/profile/orders/orders.routing';
import { profileSettingsRoutes } from '@frontend/profile/settings/settings.routing';
import { profileKeysRoutes } from '@frontend/profile/keys/keys.routing';
import { profileWebhooksRoutes } from '@frontend/profile/webhooks/webhooks.routing';
import { profileWebhookLogsRoutes } from "@frontend/profile/webhook-logs/webhook-logs.routing";

export const profileRoutes = {
  path: 'profile',
  component: ProfileComponent,
  canActivate: [LoggedInGuard],
  children: [
    {
      path: '', redirectTo: 'orders', pathMatch: 'full'
    },
    profileSettingsRoutes,
    profileOrdersRoutes,
    profileBalanceRoutes,
    profileProductsRoutes,
    profileOperationsRoutes,
    profileKeysRoutes,
    profileWebhooksRoutes,
    profileWebhookLogsRoutes,
    profileActivationRoutes,
    profileDeveloperRoutes
  ]
};
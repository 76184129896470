import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { AppConfig } from '@frontend/@shared/services';

@Component({
  selector: 'yougiver-header',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class HeaderComponent implements OnInit {
  private showSignUp: boolean = false
  private showSignIn: boolean = true
  private signPath: string = ''

  constructor(
    private router: Router, 
    private config: AppConfig,
    private ls: CoolLocalStorage
  ) {}

  ngOnInit() {
    this.handleRouteChange(this.router.routerState.snapshot.root.children[0])

    this.router.events.subscribe((e) => {
      if (!(e instanceof RoutesRecognized)) {
        return
      }

      const rootRoute = e.state.root.children[0]
      this.handleRouteChange(rootRoute)
    });
  }

  handleRouteChange(rootRoute) {
    if (!rootRoute.children[0]) {
      return
    }

    const signRoute = rootRoute.children[0]

    if (!signRoute || !signRoute.url[0] || signRoute.url[0].path !== 'sign') {
      this.hideSignButtons()
      return
    }

    const signTypeRoute = signRoute.children[0]

    if (!signTypeRoute || !signTypeRoute.url[0]) {
      this.hideSignButtons()
      return
    }

    if (!signTypeRoute.url[1]) {
      this.hideSignButtons()
      return
    }
    
    if (signTypeRoute.url[0].path !== 'in') {
      this.toggleSignIn()
    } else {
      this.toggleSignUp()
    }

    const role = this.ls.getItem('signUpAs')

    if (signTypeRoute.url[0].path !== 'in' && signTypeRoute.url[0].path !== 'up') {
      this.signPath = role || 'seller'
      return
    }

    this.signPath = signTypeRoute.url[1].path || role || 'seller'
  }

  toggleSignIn() {
    this.showSignIn = true
    this.showSignUp = false
  }

  toggleSignUp() {
    this.showSignIn = false
    this.showSignUp = true
  }

  hideSignButtons() {
    this.showSignIn = false
    this.showSignUp = false
  }
}

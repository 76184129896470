import { AccountTypeGuard } from '@frontend/@shared/services';
import { ProfileOrdersProductsListComponent } from '@frontend/profile/orders/products/list/list.component';
import { ProfileOrdersProductsShowComponent } from '@frontend/profile/orders/products/show/show.component';
import { ProfileOrdersComponent } from '@frontend/profile/orders/orders.component';
import { ProfileOrdersCertificatesListComponent } from '@frontend/profile/orders/certificates/certificates.component';

export const profileOrdersRoutes = {
  path: 'orders',
  component: ProfileOrdersComponent,
  canActivate: [AccountTypeGuard],
  children: [
    {
      path: '', redirectTo: 'list', pathMatch: 'full'
    },
    {
      path: 'list',
      component: ProfileOrdersProductsListComponent,
    },
    // {
    //   path: 'show',
    //   component: ProfileOrdersProductsShowComponent,
    // },
    {
      path: 'certificates',
      component: ProfileOrdersCertificatesListComponent,
    }
  ]
};
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WebhooksService, EmitterService } from '@frontend/@shared/services';
import { Webhooks } from '@frontend/@shared/types';
import { CoolLocalStorage } from 'angular2-cool-storage';

@Component({
  selector: 'yougiver-profile-developer-webhooks-list',
  template: require('./list.component.pug'),
  providers: [WebhooksService]
})

export class ProfileWebhooksListComponent
 implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private paginatorName: string;
  private webhooks: Webhooks;

  constructor(private webhooksService: WebhooksService, private route: ActivatedRoute,
    private ls: CoolLocalStorage) { }

  ngOnInit(): void {
    this.paginatorName = 'WEBHOOKS_LIST_PARAMS';
    this.getWebhooks(this.ls.getItem('accountId'));

    this.subscriptions.push(EmitterService.get('ACCOUNT_ID').subscribe(id => {
      this.getWebhooks(id);
    }));

    EmitterService.get('SET_BREADCRUMB').emit(['Webhooks', 'List']);
  }

  getWebhooks(account_id: string): void {
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      let newQueryParams = JSON.parse(JSON.stringify(queryParams));
      Object.assign(newQueryParams, { account_id: account_id });
      this.subscriptions.push(this.webhooksService.getWebhooks({ params: newQueryParams })
        .subscribe((webhooks: Webhooks) => {
          this.webhooks = webhooks;
        }));
    }));
  }

  public destroyWebhook(webhookId: number) {
    this.subscriptions.push(this.webhooksService.destroy(webhookId).subscribe(res => {
      this.webhooks['webhooks'] = this.webhooks['webhooks'].filter(function(obj) {
        return obj.id !== webhookId;
      });
    },
      err => { console.log(err); }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

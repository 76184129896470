import { Component, forwardRef, Input, AfterContentInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { YougiverValidators } from '@frontend/@shared/directives';

@Component({
  selector: 'yougiver-profile-textarea',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ProfileTextareaComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ProfileTextareaComponent), multi: true }
  ],
})

export class ProfileTextareaComponent implements ControlValueAccessor, AfterContentInit, OnChanges {
  @Input() _value: string;
  @Input() modification: string;
  @Input() label: string;
  @Input() validator: string;
  @Input() type: string = 'text';
  @Input() fixed: boolean = false;
  @Input() placeholder: string;
  @Input() errors: Object;
  private msg: string | Object;
  
  @Input() showClipboard: boolean = false;
  @Input() showShare: boolean = false;
  @Input() showDelete: boolean = false;

  @Output() keyUp = new EventEmitter<any>();
  @Output() copyToClipboard = new EventEmitter();
  @Output() share = new EventEmitter();
  @Output() delete = new EventEmitter();

  onTouchedCallback: () => void;
  propagateChange: any = () => {};
  validateFn: any = () => {};

  ngOnChanges(changes: any) {
    if (changes.errors) {
      this.msg = this.errorMessage();
    }
  }

  set value(value: string) {
    if (value === this._value) {
      return
    }

    this._value = value || '';
    this.propagateChange(value);
  }
  get value() {
    return this._value;
  }

  ngAfterContentInit() {
    this.writeValue(this._value);
    this.propagateChange(this._value);
  }

  onBlur() {
    this.onTouchedCallback();
    this.propagateChange(this.value);
  }

  writeValue(value) {
    this.value = value;
  }
  
  onKeyUp() {
    this.keyUp.emit(this._value);
  }

  updValue(event) {
    this.writeValue(event.target.innerText);
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  errorMessage(): string | Object {
    if (this.errors) {
      return YougiverValidators.validationNotifications(this.errors);
    }
  }
  
  onClickClipboard() {
    this.copyToClipboard.emit()
  }

  onClickShare() {
    this.share.emit()
  }

  onClickDelete() {
    this.delete.emit()
  }
}

import { ProfileSettingsPaymentsComponent } from '@frontend/profile/settings/payments/payments.component';
import { ProfileSettingsComponent } from '@frontend/profile/settings/settings.component';
import { ProfileSettingsCategoriesComponent } from '@frontend/profile/settings/categories/categories.component';
import { ProfileSettingsGeneralComponent } from '@frontend/profile/settings/general/general.component';
import { ProfileSettingsCommonInfoComponent } from '@frontend/profile/settings/common-info/common-info.component';
import { ProfileSettingsIntegrationsComponent } from "@frontend/profile/settings/integrations/integrations.component";
import { ProfileSettingsShopByLinkComponent } from "@frontend/profile/settings/shop-by-link/shop-by-link.component";

export const profileSettingsRoutes = {
  path: 'settings',
  component: ProfileSettingsComponent,
  children: [
    {
      path: '', redirectTo: 'store', pathMatch: 'full'
    },
    {
      path: 'payments',
      component: ProfileSettingsPaymentsComponent,
    },
    {
      path: 'common_info',
      component: ProfileSettingsCommonInfoComponent,
    },
    {
      path: 'categories',
      component: ProfileSettingsCategoriesComponent,
    },
    {
      path: 'general',
      component: ProfileSettingsGeneralComponent,
    },
    {
      path: 'integrations',
      component: ProfileSettingsIntegrationsComponent,
    },
    {
      path: 'shop_by_link',
      component: ProfileSettingsShopByLinkComponent,
      data: { store_type: 'store' }
    },
    {
      path: 'charity_by_link',
      component: ProfileSettingsShopByLinkComponent,
      data: { store_type: 'charity' }
    },
    {
      path: 'auction_by_link',
      component: ProfileSettingsShopByLinkComponent,
      data: { store_type: 'auction' }
    }
  ]
};

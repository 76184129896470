import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';

import { PageHeaderComponent } from '@frontend/pages/@shared/header/header.component';
import { PageComponent } from '@frontend/pages/page.component';

import { SharedComponentsModule } from '@frontend/@shared/components/components.module';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { StaticPagesService } from '@frontend/@shared/services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DirectivesModule,
    SharedComponentsModule
  ],
  declarations: [
    PageHeaderComponent,
    PageComponent
  ],
  exports: [
    PageHeaderComponent,
    PageComponent
  ],
  providers: [
    StaticPagesService
  ]
})
export class PageModule { }
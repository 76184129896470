import { Component, forwardRef, Input, Output, ViewChild, EventEmitter, AfterContentInit, OnChanges, ElementRef, HostListener } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { YougiverValidators } from '@frontend/@shared/directives';
import { NavigatorExtended } from '@frontend/@shared/types'

@Component({
  selector: 'yougiver-profile-input',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ProfileInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ProfileInputComponent), multi: true }
  ],
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileInputComponent implements ControlValueAccessor, AfterContentInit, OnChanges {
  @Input() _value: string;
  @Input() modification: string;
  @Input() label: string;
  @Input() validator: string;
  @Input() type: string = 'text';
  @Input() errors: Object;
  @Input() placeholder: string;
  @Input() fixed: boolean = false;
  @Input() required: string = '';
  @Input() comment: string = '';
  @Input() inputId: string = undefined;
  @Input() name: string = undefined;

  @Input() showClipboard: boolean = false;
  @Input() showShare: boolean = false;
  @Input() showDelete: boolean = false;
  @Input() showOpenLink: boolean = false;

  @Output() share = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() keyUp = new EventEmitter<any>();

  @ViewChild('input') valueInput: ElementRef;

  private msg: any;
  private showShareOptions: boolean = false;
  
  constructor(private sanitizer: DomSanitizer) {
  }

  onTouchedCallback: () => void;
  propagateChange: any = () => { };
  validateFn: any = () => { };

  ngOnChanges(changes: any) {
    if (changes.errors) {
      this.msg = this.errorMessage();
    }
  }

  set value(value: string) {
    if (value === this._value) {
      return
    }

    this._value = value || '';
    this.propagateChange(value);
  }
  get value() {
    return this._value;
  }

  ngAfterContentInit() {
    this.writeValue(this._value);
    this.propagateChange(this._value);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  onKeyUp() {
    this.keyUp.emit(this._value);
  }

  errorMessage(): string | Object {
    if (this.errors) {
      return YougiverValidators.validationNotifications(this.errors);
    }
  }

  onClickClipboard() {
    if (navigator && (navigator as NavigatorExtended).clipboard) {
      (navigator as NavigatorExtended).clipboard.writeText(this.value)
    } else {
      this.valueInput.nativeElement.select()
      document.execCommand("copy")
    }
    
    this.copy.emit()
  }

  @HostListener('window:click', ['$event'])
  onHideShareOptions() {
    this.showShareOptions = false
  }

  onClickShare(e: MouseEvent) {
    e.stopPropagation()
    this.showShareOptions = true
  }

  onClickDelete() {
    this.delete.emit()
  }

  get url() {
    return encodeURI(this._value)
  }

  getFacebookLink() {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`
  }

  getMessengerLink() {
    return this.sanitizer.bypassSecurityTrustUrl(`fb-messenger://share/?link=${this.url}`)
  }
  
  getOdnoklassnikiLink() {
    return `https://connect.ok.ru/offer?url=${this.url}`
  }
  
  getTwitterLink() {
    return `https://twitter.com/intent/tweet?url=${this.url}`
  }
  
  getViberLink() {
    return this.sanitizer.bypassSecurityTrustUrl(`viber://forward?text=${this.url}`)
  }
  
  getWhatsappLink() {
    return `https://wa.me/?text=${this.url}`
  }
  
  getTelegramLink() {
    return `https://t.me/share/url?url=${this.url}`
  }

  getSkypeLink() {
    return `https://web.skype.com/share?url=${this.url}`
  }
  
  getLinkedinLink() {
    return `https://www.linkedin.com/sharing/share-offsite/?url=${this.url}`
  }
  
  getVkLink() {
    return `http://vk.com/share.php?url=${this.url}`
  }
  
  getGooglePlusLink() {
    return `https://plus.google.com/share?url=${this.url}`
  }

  getInstagramLink() {
    return 'https://www.instagram.com/'
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-profile-calendar-simple',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileCalendarSimpleComponent {
  public getNumber: any;

  constructor() {
    this.getNumber = function(num) {
      return new Array(num);
    };
  }
}

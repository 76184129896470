import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { UserService, EmitterService } from '@frontend/@shared/services';
import { User } from '@frontend/@shared/types';
import { YougiverValidators } from '@frontend/@shared/directives';

@Component({
  selector: 'yougiver-profile-settings-general',
  template: require('./general.component.pug')
})
export class ProfileSettingsGeneralComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private invalidForm: boolean = false;
  public user: User;
  private wrongPassword: boolean = false;
  private incorrectPassword: string;
  private avatar_url: string;

  public headersForUploader: Array<any> = [];

  form: FormGroup;
  fullname: AbstractControl;
  email: AbstractControl;
  city: AbstractControl;
  drotr_account: AbstractControl;
  // passwords: AbstractControl;
  // password: AbstractControl;
  // password_confirmation: AbstractControl;
  current_password: AbstractControl;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router) {
  }

  ngOnInit() {
    this.avatar_url = '/api/user/avatar';

    this.subscriptions.push(this.userService.getCurrentUser().subscribe(
      res => {
        this.user = res;
        this.form = this.fb.group({
          fullname: [this.user.fullname, Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
          email: [this.user.email, YougiverValidators.email],
          drotr_account: [this.user.drotr_account, YougiverValidators.email],
          city: [this.user.city, Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
          // passwords: this.fb.group({
          //   password: ['', Validators.compose([Validators.required,
          //                                              YougiverValidators.whitespace,
          //                                              YougiverValidators.password,
          //                                              Validators.minLength(8)])],
          //   password_confirmation: ['', Validators.compose([Validators.required,
          //                                              YougiverValidators.whitespace,
          //                                              YougiverValidators.password,
          //                                              Validators.minLength(8)])]
          // }, { validator: YougiverValidators.areEqual }),
          current_password: ['', Validators.compose([Validators.required,
                                                     YougiverValidators.whitespace,
                                                     YougiverValidators.password,
                                                     Validators.minLength(8)])]
        });
        this.fullname = this.form.controls['fullname'];
        this.email = this.form.controls['email'];
        this.drotr_account = this.form.controls['drotr_account'];
        this.city = this.form.controls['city'];
        // this.passwords = this.form.controls['passwords'];
        // this.password = (this.form.controls['passwords'] as any).controls['password'];
        // this.password_confirmation = (this.form.controls['passwords'] as any).controls['password_confirmation'];
        this.current_password = this.form.controls['current_password'];
      },
      err => { console.log(err); }
    ));

    this.userService.headers.forEach((value, name) => {
      if (name.toLowerCase() !== 'content-type') {
        this.headersForUploader.push({ name: name, value: value[0] });
      }
    });

    this.subscriptions.push(EmitterService.get('FILE_UPLOADER_EVENT').subscribe(res => {
      let response = JSON.parse(res.response);
      if (res.status === 200) {
        this.user.avatar = response.avatar;
      } else {
        EmitterService.get('NOTIFICATION').emit(response);
      }
    }));

    this.subscriptions.push(EmitterService.get('DELETE_UPLOADED_FILE').subscribe(file => {
      if (file) {
        this.userService.destroyAvatar().subscribe(
          res => {
            if (!res.error || res.errors.length === 0) {
              this.user.avatar = null;
            }
          },
          err => { console.log(err); }
        );
      }
    }));

    EmitterService.get('SET_BREADCRUMB').emit(['shared.settings', 'profile.navbar.settings.profile']);
  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      this.userService.update(this.form.value).subscribe((res: any) => {
        if (!res.error) {
          this.wrongPassword = false;
          EmitterService.get('NOTIFICATION').emit(res);
          // (this.password as any).setValue('');
          // (this.password_confirmation as any).setValue('');
        } else if (res['errors']['current_password']) {
          this.wrongPassword = true;
          this.incorrectPassword = this.current_password.value;
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  passwordErrors(): Object {
    if (!this.current_password.valid) {
      return this.current_password.errors;
    } else if (this.wrongPassword) {
      if (this.incorrectPassword === this.current_password.value){
        return { password_incorrect: true };
      } else {
        this.wrongPassword = false;
        this.incorrectPassword = '';
        return null;
      }
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

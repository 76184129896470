import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmitterService, AccountService } from '@frontend/@shared/services';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Accounts, Account } from '@frontend/@shared/types';

@Component({
  selector: 'yougiver-profile-wrapper',
  encapsulation: ViewEncapsulation.None,
  styles: [require('./@styles/_import.sass')],
  template: require('./profile.component.pug')
})

export class ProfileComponent implements OnInit, OnDestroy {
  private htmlClass: any;
  private subscriptions: Array<Subscription> = [];
  private tabList: any;
  private notification: any;
  private popup: any;
  private account: Account;
  private accounts: Accounts;
  private switchAccount: boolean = false;
  private switchLanguage: boolean = false;

  private activeMobileMenu: boolean = false;

  constructor(private accountServise: AccountService, private router: Router, private ls: CoolLocalStorage) { }

  ngOnInit() {
    this.htmlClass = document.querySelector('html').classList;
    this.subscriptions.push(EmitterService.get('ACTIVE_MOBILE_MENU').subscribe(res => {
      this.activeMobileMenu = res;
    }));
    if (!this.account) {
      this.getAccounts();

      this.subscriptions.push(EmitterService.get('ACCOUNT_ID').subscribe(id => {
        this.ls.setItem('accountId', id);
        this.accountServise.accounts = undefined;
        this.accountServise.accountSubscribe({ account_id: id });
      }));
      this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
        this.account = account;
      }));
    }

    this.subscriptions.push(EmitterService.get('NOTIFICATION').subscribe((notification: any) => {
      this.notification = notification;
      setTimeout(() => {
        this.notification = undefined;
      }, 6000);
    }));

    this.subscriptions.push(EmitterService.get('POPUP').subscribe((popup: any) => {
      this.popup = popup;
      this.toggleHtmlClass(popup);
    }));

    this.subscriptions.push(EmitterService.get('SWITCH_ACCOUNT').subscribe(res => {
      this.switchAccount = res;
      this.toggleHtmlClass(res);
    }));

    this.subscriptions.push(EmitterService.get('SWITCH_LANGUAGE').subscribe(res => {
      this.switchLanguage = res;
      this.toggleHtmlClass(res);
    }));

    this.subscriptions.push(EmitterService.get('DESTROY_ACCOUNT').subscribe(accountId => {
      this.destroyAccount(accountId);
    }));

    this.subscriptions.push(EmitterService.get('LOCALE').subscribe(locale => {
      this.getAccounts();
    }));

    this.tabList = [
      {
        active: true,
        state: 'all',
        text: 'profile.orders.states.all',
        counter: 0
      },
      {
        active: false,
        state: 'completed',
        text: this.ls.getItem('accountType') === 'Developer' ? 'profile.orders.states.completed' : 'profile.orders.states.accepted'
      }
    ];
  }

  toggleHtmlClass(val: boolean): void {
    if (val) {
      this.htmlClass.add('is-active-popup');
    } else {
      this.htmlClass.remove('is-active-popup');
    }
  }

  getAccounts() {
    let accountId = this.ls.getItem('accountId');
    if (!accountId) {
      accountId = this.ls.getItem('latestAccount');
    }
    this.accountServise.accountSubscribe({ account_id: accountId });
    this.subscriptions.push(EmitterService.get('ACCOUNTS').subscribe(accounts => {
      this.accounts = accounts;
      accountId = this.ls.getItem('accountId');
      if (!accountId) { accountId = this.ls.getItem('latestAccount'); }
      this.account = accountId === null ? accounts.accounts.find(a => a['avatar'] !== undefined) :
                                          accounts.accounts.find(a => a.id === +accountId);

      if (this.ls.getItem('accountId') === null) {
        this.ls.setItem('accountId', '' + this.account.id);
        EmitterService.get('ACCOUNT_ID').emit(this.account.id);
      }
      EmitterService.get('ACCOUNT').emit(this.account);
      this.ls.setItem('accountType', this.account['account_type']);

      this.possibleRedirect();
    }));
  }

  createAccount(condition: boolean) {
    if (condition) {
      let accountType = { account_type: this.ls.getItem('accountType') };
      this.subscriptions.push(this.accountServise.createAccount(accountType).subscribe(res => {
        if (res['resource']) {
          EmitterService.get('ACCOUNT_ID').emit(res['resource']['id']);
        }
        EmitterService.get('NOTIFICATION').emit(res);
      }));
    }
  }

  destroyAccount(accountId) {
    let latestAccount = this.ls.getItem('latestAccount');
    if(latestAccount) {
      this.subscriptions.push(this.accountServise.destroyAccount(accountId).subscribe(res => {
        if (res['success']) {
          let id = this.ls.getItem('latestAccount');
          EmitterService.get('ACCOUNT_ID').emit(id);
          this.router.navigate(['profile', 'settings', 'common_info']);
        }
      }));
    } else {
      this.router.navigate(['/logout']);
    }
  }

  possibleRedirect(): void {
    if (this.account.account_type === 'Seller') {
      if (this.account.state === 'initial') {
        this.router.navigate(['profile', 'activation', 'main-info']);
      }
      if (this.account.state === 'activation_step1') {
        this.router.navigate(['profile', 'activation', 'goods-and-schedule']);
      }
      if ((this.account.state === 'activation_step2') || (this.account.state === 'pending')) {
        this.router.navigate(['profile', 'activation', 'documents-and-payments']);
      }
    }
    else if (this.account.account_type === 'Developer') {
      if (this.account.state === 'initial') {
        this.router.navigate(['profile', 'developer', 'activation', 'main-info-and-products']);
      }
      if (this.account.state === 'activation_step1') {
        this.router.navigate(['profile', 'developer', 'activation', 'documents-and-payments']);
      }
      if (this.account.state === 'activated' && this.router.url.startsWith('/profile/developer/activation')) {
        this.router.navigate(['profile', 'balance', 'common']);
      }
    }

  }

  response_message(response) {
    let messages = [];
    for (let name in response) {
      if (response[name] instanceof Array && response[name].length === 1) {
        let prefix;
        if (parseInt(name, 0) >= 0) {
          prefix = ' — ';
        } else {
          prefix = name;
        }
        messages.push(`<p>${prefix} ${response[name as string]}</p>`);
      } else if (response[name] instanceof Array) {
        messages.push(this.response_message(response[name]));
      }
    }
    return messages.join('\n');
  }

  isActivation(): boolean {
    return this.router.url.indexOf('activation/') > 0 ? true : false;
  }

  isOrders(): boolean {
    return this.router.url.indexOf('orders/') > 0 ? true : false;
  }

  mobileMenu(active: boolean) {
    if (active) {
      this.activeMobileMenu = !this.activeMobileMenu;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

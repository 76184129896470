import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-simple-text',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})


export class SimpleTextComponent {
  @Input() mod: string;
  @Input() text: string;
}

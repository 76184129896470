import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  // Validators,
  FormArray,
  FormControl
} from '@angular/forms';

import { AccountService, TaxonomiesService, EmitterService } from '@frontend/@shared/services';
import { Account, Taxonomies, Taxonomy } from '@frontend/@shared/types';
import { CoolLocalStorage } from 'angular2-cool-storage';


@Component({
  selector: 'yougiver-profile-settings-categories',
  template: require('./categories.component.pug'),
  providers: [TaxonomiesService]
})
export class ProfileSettingsCategoriesComponent implements OnInit, OnDestroy {
  public account: Account;
  public taxonomies: Taxonomies;
  protected subscriptions: Array<Subscription> = [];
  form: FormGroup;
  taxonomy_ids: AbstractControl;
  taxon_id: AbstractControl;

  constructor(protected _changeDetectionRef: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected taxonomiesService: TaxonomiesService,
    protected router: Router,
    protected ls: CoolLocalStorage) { }

  ngOnInit() {
    EmitterService.get('SET_BREADCRUMB').emit(['shared.settings', 'profile.navbar.settings.categories']);
    this.account = this.accountServise.getAccount();
    if (this.account) {
      this.formSetup();
    }

    EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.formSetup();
    });

    this._changeDetectionRef.detectChanges();
  }

  formSetup() {
    this.form = this.fb.group({
      taxonomy_ids: this.fb.array([])
    });

    this.taxonomy_ids = this.form.controls['taxonomy_ids'];

    this.taxonomiesService.getTaxonomies({ 'set': 'nested' }).subscribe((taxonomies: Taxonomies) => {
      this.taxonomies = taxonomies;
      taxonomies.taxonomies.forEach(taxonomy => {
        this.initTaxonControl(taxonomy);
      });
    });
  }

  getTaxons() {
    return this.form.get('taxonomy_ids')['controls'] as FormControl
  }

  initTaxonControl(taxonomy: Taxonomy) {
    const isActive = this.account.taxonomies.find(({ id }) => id === taxonomy.id)
    const taxonControl = this.fb.group({
      name:[{ value: taxonomy.name, disabled: false }],
      id: [{ value: taxonomy.id, disabled: false }],
      isActive:[{ value: isActive, disabled: false }] 
    })

    const taxons = this.taxonomy_ids as FormArray
    taxons.push(taxonControl);
  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      const taxons = this.form.value.taxonomy_ids.filter(({ isActive }) => isActive).map(({id}) => id)
      this.accountServise.updateAccount(this.account.id, { taxonomy_ids: taxons }).subscribe((res: any) => {
        if (res) {
          if (res['resource']) {
            this.accountServise.setAccount(res['resource']);
          }
          EmitterService.get('NOTIFICATION').emit(res);
        }
      });
    } else {
      console.log('form invalid!', this.form.controls);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

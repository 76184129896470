import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'yougiver-profile-steps-nav',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileStepsNavComponent implements OnInit {
  @Input() state: string = 'initial';
  @Input() accountType: string;

  public items: any;

  constructor(private router: Router) { }

  ngOnInit() {
    if (this.accountType === 'Seller') {
      this.items = [
        {
          step: 1,
          sref: ['profile', 'activation', 'main-info'],
          text: 'profile.activation.step1'
        },
        {
          step: 2,
          sref: ['profile', 'activation', 'goods-and-schedule'],
          text: 'profile.activation.step2'
        },
        {
          step: 3,
          sref: ['profile', 'activation', 'documents-and-payments'],
          text: 'profile.activation.step3'
        }
      ];
    }
    else if (this.accountType === 'Developer') {
      this.items = [
        {
          step: 1,
          sref: ['profile', 'developer', 'activation', 'main-info'],
          text: 'profile.activation.step1'
        },
        {
          step: 2,
          sref: ['profile', 'developer', 'activation', 'documents-and-payments'],
          text: 'profile.activation.step3'
        }
      ];
    }
  }

  isCompleted(item: any): boolean {
    if (item.step === 1) {
      return this.state === 'activation_step1' || this.state === 'activation_step2' || this.state === 'activated' || this.state === 'pending' ? true : false;
    }
    if (item.step === 2) {
      return this.state === 'activation_step2' || this.state === 'activated' || this.state === 'pending' ? true : false;
    }
    if (item.step === 3) {
      return this.state === 'activated' ? true : false;
    }
  }

  currentStep(item: any): boolean {
    return this.router.url.indexOf(item.sref.slice(-1).pop()) > 0 ? true : false;
  }

  stateText(): string {
    if(this.state === 'pending') {
      return 'profile.activation.state.pending'
    } else if (this.state === 'declined') {
      return 'profile.activation.state.declined'
    } else {
      return ''
    }
  }
}

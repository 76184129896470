import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Observable } from 'rxjs';
import { EmitterService } from '@frontend/@shared/services';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class UserService {
  public loggedIn = false;

  public headers;
  public options;
  public res: Response;
  constructor(private http: Http, private ls: CoolLocalStorage) {
    this.loggedIn = !!ls.getItem('auth_token');

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.options = new RequestOptions({ headers: this.headers });
  }

  login(body: any): Observable<any> {
    return this.http
      .post('/api/user/sign_in', JSON.stringify({ user: body }), this.options)
      .map(res => res.json())
      .map((res) => {
        if (res.api_key) {
          if (body.remember_me) {
            this.ls.setItem('auth_token', res.api_key);
          }
          EmitterService.get('LOGGED_IN').emit(true);
          this.loggedIn = true;
        }
        return res;
      })
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  signUp(account_type, email, password, password_confirmation) {
    return this.http
      .post(
      '/api/user',
      JSON.stringify({ user: { account_type, email, password, password_confirmation } }),
      this.options
      )
      .map(res => res.json())
      .map((res) => {
        return res;
      });
  }

  getCurrentUser(): Observable<any> {
    return this.http.post('/api/v1/users/current_user', JSON.stringify({token: this.ls.getItem('auth_token')}), this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  update(body): Observable<any> {
    let bodyString = JSON.stringify({ user: body }, null, 2);
    return this.http.patch('/api/user', bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  destroyAvatar(): Observable<any> {
    return this.http.delete('/api/user/avatar', this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  confirm(confirmation_token): Observable<any> {
    return this.http.get('/api/user/confirmation?confirmation_token=' + confirmation_token)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  requestNewPassword(body): Observable<any> {
    let bodyString = JSON.stringify({ user: body }, null, 2);
    return this.http.post('/api/user/password', bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  getResetPassword(reset_password_token): Observable<any> {
    return this.http.get(`/api/user/password/edit?reset_password_token=${reset_password_token}`)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  doResetPassword(body): Observable<any> {
    let bodyString = JSON.stringify(body, null, 2);
    return this.http.patch('/api/user/password', bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  updateApiKey(newKey) {
    this.ls.setItem('auth_token', newKey);
  }

  logout(): Observable<any> {
    return this.http.delete('/api/user/logout').map((res: Response) => {
      this.ls.removeItem('auth_token');
      this.ls.removeItem('accountType');
      this.ls.removeItem('lastProductParams');
      // let accountId = this.ls.getItem('accountId');
      // this.ls.setItem('latestAccount', accountId);
      this.ls.removeItem('latestAccount');
      this.ls.removeItem('accountId');
      this.loggedIn = false;
      EmitterService.get('LOGGED_IN').emit(false);
      return res;
    });
  }

  isLoggedIn(): Observable<boolean> | boolean {
    if (this.loggedIn) {
      return true;
    } else {
      return this.getCurrentUser().map(res => {
        if (res['api_key']) {
          this.loggedIn = true;
          return true;
        }
        return false;
      }).take(1);
    }
  }
}

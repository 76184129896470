import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'yougiver-profile-confirmation',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileConfirmationComponent {
  @Output() destroy = new EventEmitter<Object>();
  @Input() productId: number;

  select(agreed: boolean) {
    this.destroy.emit({value: this.productId, destroy: agreed});
  }
}

import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Helper } from '@frontend/@shared/services';

@Injectable()
export class WebhooksService {
  public headers;
  public options;
  private webhooksUrl = '/api/v1/webhooks';
  private eventsListUrl = '/api/v1/webhooks/events_list';
  private apiKey: string;

  constructor(private router: Router, private route: ActivatedRoute,
              private http: Http, private ls: CoolLocalStorage) {
    this.apiKey = this.ls.getItem('auth_token');
    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Yougiver-Token': this.apiKey
    });
    this.options = new RequestOptions({ headers: this.headers });
  }

  public setQueryParams(params: Object) {
    return this.route.queryParams.subscribe(queryParams => {
      if (Object.keys(params).length !== 0) {
        let newQueryParams = JSON.parse(JSON.stringify(queryParams));
        Object.assign(newQueryParams, params);
        let navigationExtras: NavigationExtras = {
          queryParams: newQueryParams
        };
        this.router.navigate(['profile/webhooks'], navigationExtras);
        params = {};
      }
    });
  }

  public getWebhooks(params: Object = undefined): Observable<any> {
    if (params) {
      params = Helper.transformQueryParams(params);
      this.options.search = new URLSearchParams(Helper.toParams(params, this.ls));
    }
    return this.http.get(this.webhooksUrl, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  public getEventsList(){
    return this.http.get(this.eventsListUrl)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  public createWebhook(body): Observable<any> {
    this.options = new RequestOptions({ headers: this.headers });

    let obj = {webhook: body, account_id: this.ls.getItem('accountId')};
    let bodyString = JSON.stringify(obj);

    return this.http.post(this.webhooksUrl, bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  public getWebhook(id, params: Object = undefined): Observable<any> {
    this.options = new RequestOptions({ headers: this.headers });

    return this.http.get(`${this.webhooksUrl}/${id}`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  update(webhookId, body): Observable<any> {
    this.options = new RequestOptions({ headers: this.headers });

    let bodyString = JSON.stringify({ webhook: body });
    return this.http.patch(`${this.webhooksUrl}/${webhookId}`, bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  destroy(id): Observable<any> {
    return this.http.delete(`${this.webhooksUrl}/${id}`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  mock(webhookId): Observable<any> {
    this.options = new RequestOptions({ headers: this.headers });
    let obj = {account_id: this.ls.getItem('accountId')};
    let bodyString = JSON.stringify(obj);

    return this.http.post(`${this.webhooksUrl}/${webhookId}/mock`, bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }
}

import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
  Validators
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { AccountService, EmitterService, SellersService } from '@frontend/@shared/services';
import { Account, Location, SellerWebhook } from '@frontend/@shared/types';
import { CoolLocalStorage } from 'angular2-cool-storage';
import {DomSanitizer, SafeResourceUrl, SafeHtml} from "@angular/platform-browser";
import { YougiverValidators } from "@frontend/@shared/directives";


@Component({
  selector: 'yougiver-profile-settings-integrations',
  styles: [require('./styles/_import.sass')],
  template: require('./integrations.component.pug'),
})

export class ProfileSettingsIntegrationsComponent implements OnInit, OnDestroy {
  protected subscriptions: Array<Subscription> = [];
  protected invalidForm: boolean = false;
  public account: Account;
  private accountId: number;
  private webhook: SellerWebhook;
  public instructionUrl: SafeResourceUrl;
  public cssUrlProd: SafeResourceUrl;
  public scriptUrlProd: SafeResourceUrl;
  public cssUrlStaging: SafeResourceUrl;
  public scriptUrlStaging: SafeResourceUrl;
  public wideExampleUrl: SafeResourceUrl;
  public smallExampleUrl: SafeResourceUrl;
  public loaderState: string;

  keysForm: FormGroup;
  url: AbstractControl;
  header_key: AbstractControl;
  header_value: AbstractControl;

  form: FormGroup;
  account_id: AbstractControl;
  apiKey: AbstractControl;

  constructor(protected _changeDetectionRef: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected accountService: AccountService,
    protected sellersService: SellersService,
    protected router: Router,
    protected ls: CoolLocalStorage,
    protected sanitizer: DomSanitizer) {
    this.instructionUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/document/d/1USmS_Gd1pAeG1W3TA5xzsBumJEc4VxrMdnYrFYRjqoI/edit')
    this.cssUrlProd = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/embedded/prod/yougiver-forms.css')
    this.scriptUrlProd = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/embedded/prod/yougiver-forms.js')
    this.cssUrlStaging = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/embedded/staging/yougiver-forms.css')
    this.scriptUrlStaging = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/embedded/staging/yougiver-forms.js')
    this.wideExampleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/embedded/wide-form.html')
    this.smallExampleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/embedded/small-form.html')
  }

  ngOnInit() {
    this.accountId = +this.ls.getItem('accountId');
    EmitterService.get('SET_BREADCRUMB').emit(['shared.settings', 'profile.navbar.settings.integrations']);

    this.formSetup();
    this.account = this.accountService.getAccount();
    this.sellersService.getWebhook(this.accountId).subscribe((value) => {
      this.webhook = value;
      if (this.webhook) {
        this.keysFormUpdate();
      }
    });

    this.form = this.fb.group({
      apiKey: [{value: '', disabled: true}],
      account_id: [{value: '', disabled: true}]
    });

    this.apiKey = this.form.controls['apiKey'];
    this.account_id = this.form.controls['account_id'];
    
    if (this.account) {
      this.formUpdate();
    }

    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.formUpdate();
    }));
    
  }

  formSetup() {
    this.keysForm = this.fb.group({
      seller_id: this.accountId,
      url: ['', Validators.required],
      header_key: ['', YougiverValidators.headerKey],
      header_value: [''],
    });

    this.url = this.keysForm.controls['url'];
    this.header_key = this.keysForm.controls['header_key'];
    this.header_value = this.keysForm.controls['header_value'];
  }

  sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)['changingThisBreaksApplicationSecurity'];
  }

  keysFormUpdate() {
    this.keysForm.patchValue({
      url: this.webhook.url,
      header_key: this.webhook.header_key,
      header_value: this.webhook.header_value,
    })
  }

  formUpdate() {
    this.form.patchValue({
      apiKey: this.ls.getItem('auth_token'),
      account_id: this.account.id
    })
  }

  onSubmit(value: any): void {
    let formData = this.keysForm.value;

    if (this.keysForm.valid) {
      this.invalidForm = false;
      this.loaderState = 'isLoading';

      this.sellersService.updateWebhook(this.accountId, formData).subscribe((res: any) => {
        if (!res.error) {
          this.loaderState = 'isComplete';
          if (res['resource']) {
            // this.accountService.setAccount(res['resource']);
          }
        } else {
          this.loaderState = '';
        }
        EmitterService.get('NOTIFICATION').emit(res);
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.keysForm.controls);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  smallFormInitExample(): string {
    return `new YougiverIntegration({
  type: 'small',
  apiKey: '${this.apiKey.value}',
  accountId: '${this.account_id.value}',
});`
  }

  wideFormInitExample(): string {
    return `new YougiverIntegration({
  type: 'wide',
  apiKey: '${this.apiKey.value}',
  accountId: '${this.account_id.value}',
});`
  }
}

import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { StaticPagesService } from '@frontend/@shared/services';

@Component({
  encapsulation: ViewEncapsulation.None,
  template: require('./page.component.pug'),
  styles: [require('./@styles/_import.sass')]
})

export class PageComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private pageBody: string;

  constructor(private activatedRoute: ActivatedRoute, private pageService: StaticPagesService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      this.pageService.show(params['slug']).subscribe(page => {
        this.pageBody = page['body'];
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

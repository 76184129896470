import { Component, Input, OnInit } from '@angular/core';
import { EmitterService } from '@frontend/@shared/services';
import { DocumentFile } from '@frontend/@shared/types';

@Component({
  selector: 'yougiver-profile-upload-list',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileUploadListComponent implements OnInit {
  @Input() uploader: any;
  @Input() prepopulated: Array<DocumentFile>;

  ngOnInit() {
  }

  destroy(item: DocumentFile): void {
    EmitterService.get('DELETE_UPLOADED_FILE').emit(item);
    item.deleted = true;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@frontend/@shared/services';

@Component({
  template: ``
})
export class LogoutComponent implements OnInit {
  constructor(private user: UserService, private router: Router) {

  }

  ngOnInit() {
    this.user.logout().subscribe((res) => {
      this.router.navigate(['']);
    });
  }
}

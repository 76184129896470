import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-profile-add-button',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileAddButtonComponent {
  @Input() text: any = 'Add';
}

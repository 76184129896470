import { Component, forwardRef, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { YougiverValidators } from '@frontend/@shared/directives';

@Component({
  selector: 'yougiver-sign-form-input',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SignFormSharedInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => SignFormSharedInputComponent), multi: true }
  ],
  styles: [require('./@styles/_import.sass')],
  template: require('./input.component.pug')
})

export class SignFormSharedInputComponent implements ControlValueAccessor, OnChanges {
  @Input() _value: string;
  @Input() mod: string;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() errors: Object;
  @Input() ico: string;
  @Input() validator: string;
  @Input() disabled: boolean;
  @Input() msg: string | Object;
  @Input() name: string;
  @Input() hint: string;
  @Output() toggleIcon = new EventEmitter()

  onTouchedCallback: () => void;
  propagateChange: any = () => { };
  validateFn: any = () => { };

  ngOnChanges(changes: any) {
    if (changes.errors) {
      this.msg = this.errorMessage();
    }
  }

  set value(value: string) {
    this._value = value || '';
    this.propagateChange(value);
  }

  get value() {
    return this._value;
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value) {
    this.msg = this.errorMessage();
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  errorMessage(): string | Object {
    if (this.errors) {
      return YougiverValidators.validationNotifications(this.errors);
    }
  }

  onToggleIcon() {
    this.toggleIcon.emit()
  }
}

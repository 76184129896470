import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'yougiver-profile-balance',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileBalanceComponent {
  @Input() data: {
    mod: string,
    ico: string,
    titleText: string,
    titleLink: string,
    balanceCurrency: string,
    balanceValue: string,
    linkText: string,
    linkHref: string,
    infoText: string,
    infoLink: string,
    infoValue: string,
    showCalendar: boolean
  };

  @Output() dateRangeChanged: EventEmitter<Object> = new EventEmitter();

  selDateRange: string = 'thisMonth';

  dateRangePickerOptions: Object = {
    monthLabels: {
      1: 'profile.balance.chart.month.january',
      2: 'profile.balance.chart.month.february',
      3: 'profile.balance.chart.month.march',
      4: 'profile.balance.chart.month.april',
      5: 'profile.balance.chart.month.may',
      6: 'profile.balance.chart.month.june',
      7: 'profile.balance.chart.month.july',
      8: 'profile.balance.chart.month.august',
      9: 'profile.balance.chart.month.september',
      10: 'profile.balance.chart.month.october',
      11: 'profile.balance.chart.month.november',
      12: 'profile.balance.chart.month.december'
    },
    // dateRangeFilterLabels: {
    //   today: 'сегодня',
    //   thisWeek: 'текущую неделю',
    //   thisMonth: 'текущий месяц',
    //   thisYear: 'текущий год'
    // },
    dateFormat: 'dd.mm.yyyy',
    showClearBtn: false,
    editableMonthAndYear: false,
    inline: false,
    width: 'auto',
    oneLineMonthYearSelector: true,
    inputTypeInput: false,
    showSelectDateText: true
  };

  onDateRangeChanged(e): void {
    this.dateRangeChanged.emit(e);
  }
}

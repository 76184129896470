import { ProfileKeysComponent } from '@frontend/profile/keys/keys.component';
import { ProfileKeysBlockComponent } from '@frontend/profile/keys/block/block.component';

export const profileKeysRoutes = {
  path: 'keys',
  component: ProfileKeysComponent,
  children: [
    {
      path: '',
      component: ProfileKeysBlockComponent
    }
  ]
};

import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';

import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';

import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { ProfileDeveloperSettingsCommonComponent } from '@frontend/profile/developer/common/common.component';
import { ProfileDeveloperActivationDocumentsAndPaymnetsComponent } from '@frontend/profile/developer/documents-and-payments/documents-and-payments.component';
import { ProfileDeveloperActivationMainInfoAndProductsComponent } from '@frontend/profile/developer/main-info-and-products/main-info-and-products.component';
import { ProfileDeveloperSettingsPaymentsComponent } from '@frontend/profile/developer/payments/payments.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    ProfileSharedModule,
  ],
  declarations: [
    ProfileDeveloperSettingsCommonComponent,
    ProfileDeveloperActivationDocumentsAndPaymnetsComponent,
    ProfileDeveloperActivationMainInfoAndProductsComponent,
    ProfileDeveloperSettingsPaymentsComponent
  ],
  exports: [
    ProfileDeveloperSettingsCommonComponent,
    ProfileDeveloperActivationDocumentsAndPaymnetsComponent,
    ProfileDeveloperActivationMainInfoAndProductsComponent,
    ProfileDeveloperSettingsPaymentsComponent
  ],
})
export class ProfileDeveloperModule { }

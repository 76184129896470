import { NgModule } from '@angular/core';
import { SignComponent } from '@frontend/sign/sign.component';
import { SignInComponent } from '@frontend/sign/sign-in/sign-in.component';
import { SignRestoreComponent } from '@frontend/sign/sign-restore/sign-restore.component';
import { SignUpComponent } from '@frontend/sign/sign-up/sign-up.component';
import { SignConfirmationComponent } from '@frontend/sign/sign-confirmation/sign-confirmation.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { SignFormModule } from '@frontend/sign/@shared/sign-form/sign-form.module';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';

import { SignUpSellerComponent } from '@frontend/sign/sign-seller/sign-up-seller.component';
import { SignInSellerComponent } from '@frontend/sign/sign-seller/sign-in-seller.component';

import { SignUpDevComponent } from '@frontend/sign/sign-dev/sign-up-dev.component';
import { SignInDevComponent } from '@frontend/sign/sign-dev/sign-in-dev.component';

import { SignUpMassMediaComponent } from '@frontend/sign/sign-mass-media/sign-up-mass-media.component';
import { SignInMassMediaComponent } from '@frontend/sign/sign-mass-media/sign-in-mass-media.component';

import { SignUpShopComponent } from '@frontend/sign/sign-shop/sign-up-shop.component';
import { SignInShopComponent } from '@frontend/sign/sign-shop/sign-in-shop.component';

import { SignInInfluencerComponent } from '@frontend/sign/sign-influencer/sign-in-influencer.component';
import { SignUpInfluencerComponent } from '@frontend/sign/sign-influencer/sign-up-influencer.component';

import { SignProxyComponent } from '@frontend/sign/sign-proxy.component';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    DirectivesModule,
    SignFormModule,
    SharedComponentsModule
  ],
  declarations: [
    SignComponent,
    SignInComponent,
    SignRestoreComponent,
    SignUpComponent,
    SignConfirmationComponent,
    SignUpSellerComponent,
    SignInSellerComponent,
    SignUpDevComponent,
    SignInDevComponent,
    SignUpMassMediaComponent,
    SignInMassMediaComponent,
    SignUpShopComponent,
    SignInShopComponent,
    SignInInfluencerComponent,
    SignUpInfluencerComponent,
    SignProxyComponent,
  ],
  exports: [
    SignFormModule,
    SignComponent,
    SignInComponent,
    SignRestoreComponent,
    SignUpComponent,
    SignConfirmationComponent,
    SignUpSellerComponent,
    SignInSellerComponent,
    SignUpDevComponent,
    SignInDevComponent,
    SignUpMassMediaComponent,
    SignInMassMediaComponent,
    SignUpShopComponent,
    SignInShopComponent,
    SignInInfluencerComponent,
    SignUpInfluencerComponent,
    SignProxyComponent,
  ]
})
export class SignModule { }

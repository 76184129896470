import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@frontend/@shared/services';

@Component({
  selector: 'yougiver-sign-confirmation',
  template: require('./sign-confirmation.component.pug')
})
export class SignConfirmationComponent {
  public response$: any;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    route.params.subscribe(params => {
      let confirmationToken = params['confirmation_token'];
      if (confirmationToken) {
        userService.confirm(confirmationToken).subscribe(
          response => {
            this.response$ = response;
            userService.updateApiKey(response.api_key);
            userService.loggedIn = true;
          },
          err => { console.log(err); }
        );
      }
    });
  }

  public errorMessage(response) {
    let messages = [];
    for (let name in response.errors) {
      if (response.errors[name as string]) {
        messages.push(name + ' ' + response.errors[name as string]);
      }
    }
    return messages.join('\n');
  }
}

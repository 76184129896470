import { Component, forwardRef, Input, Output, ViewChild, EventEmitter, AfterContentInit, OnChanges, ElementRef, HostListener } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { YougiverValidators } from '@frontend/@shared/directives';
import { NavigatorExtended } from '@frontend/@shared/types'

@Component({
  selector: 'yougiver-profile-input-actions',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileInputActionsComponent {
  @Input() value: string;
  @Input() disabled: boolean = false;

  @Input() showClipboard: boolean = false;
  @Input() showShare: boolean = false;
  @Input() showDelete: boolean = false;
  @Input() showOpenLink: boolean = false;

  @Output() share = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() keyUp = new EventEmitter<any>();
  @Output() action = new EventEmitter();

  @ViewChild('input') valueInput: ElementRef;

  private showShareOptions: boolean = false;
  
  constructor(private sanitizer: DomSanitizer) {
  }

  onClickClipboard() {
    this.onAction()

    if (this.disabled) {
      return 
    }

    if (navigator && (navigator as NavigatorExtended).clipboard) {
      (navigator as NavigatorExtended).clipboard.writeText(this.value)
    } else {
      this.valueInput.nativeElement.type = 'text'
      this.valueInput.nativeElement.select()
      document.execCommand("copy")
      this.valueInput.nativeElement.type = 'hidden'
    }
    
    this.copy.emit()
  }

  @HostListener('window:click', ['$event'])
  onHideShareOptions() {
    this.showShareOptions = false
  }

  onClickShare(e: MouseEvent) {
    this.onAction()

    if (this.disabled) {
      return
    }

    e.stopPropagation()
    this.showShareOptions = true
  }

  onClickDelete() {
    this.onAction()
    this.delete.emit()
  }

  onClickOpen(e: MouseEvent) {
    this.onAction()

    if (this.disabled) {
      e.preventDefault()
    }
  }

  onAction() {
    this.action.emit()
  }

  get url() {
    return encodeURI(this.value)
  }

  getFacebookLink() {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`
  }

  getMessengerLink() {
    return this.sanitizer.bypassSecurityTrustUrl(`fb-messenger://share/?link=${this.url}`)
  }
  
  getOdnoklassnikiLink() {
    return `https://connect.ok.ru/offer?url=${this.url}`
  }
  
  getTwitterLink() {
    return `https://twitter.com/intent/tweet?url=${this.url}`
  }
  
  getViberLink() {
    return this.sanitizer.bypassSecurityTrustUrl(`viber://forward?text=${this.url}`)
  }
  
  getWhatsappLink() {
    return `https://wa.me/?text=${this.url}`
  }
  
  getTelegramLink() {
    return `https://t.me/share/url?url=${this.url}`
  }

  getSkypeLink() {
    return `https://web.skype.com/share?url=${this.url}`
  }
  
  getLinkedinLink() {
    return `https://www.linkedin.com/sharing/share-offsite/?url=${this.url}`
  }
  
  getVkLink() {
    return `http://vk.com/share.php?url=${this.url}`
  }
  
  getGooglePlusLink() {
    return `https://plus.google.com/share?url=${this.url}`
  }

  getInstagramLink() {
    return 'https://www.instagram.com/'
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-loader',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class LoaderComponent {
}

import { Component, Input } from '@angular/core';
import { SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'yougiver-profile-link',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})


export class ProfileLinkComponent {

  @Input() text: any;
  @Input() ico: any;
  @Input() mod: any;
  @Input() routerLink: any;
  @Input() linkTo: SafeResourceUrl;
  @Input() target: any;
  @Input() download: any = null;

}

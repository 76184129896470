import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-register-as',
  styles: [require('./@styles/_import.sass')],
  template: require('./register-as.component.pug')
})

export class RegisterAsComponent {
  @Input() purpose: string;
  @Input() title: string;
  @Input() role: string;
  @Input() routerLink: string;
  @Input() externalLink: string;
  @Input() showBorder: boolean;
}
import {Component, Input} from '@angular/core';

@Component({
  selector: 'yougiver-profile-order-preview-link',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})


export class ProfileOrderPreviewLinkComponent {


}

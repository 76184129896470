import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-sign-up-request-form',
  template: require('./up-request.component.pug')
})
export class SignUpRequestFormComponent {

  private tabItems: any;

  constructor() {

    this.tabItems = [
      {
        mod: 'is-active',
        text: 'shared.roles.seller'
      },
      {
        mod: '',
        text: 'shared.roles.developer'
      }
    ];
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-sign-form-link',
  styles: [require('./@styles/_import.sass')],
  template: require('./link.component.pug')
})

export class SignFormSharedLinkComponent {
  @Input() text: string;
  @Input() routerLink: string = '#';
}

import { NgModule } from '@angular/core';
import { ProfileActionsComponent } from '@frontend/profile/@shared/actions/component';
import { ProfileActionsLinkComponent } from '@frontend/profile/@shared/actions/link/component';
import { ProfileActionsButtonComponent } from '@frontend/profile/@shared/actions/button/component';
import { ProfileAutocompleteComponent } from '@frontend/profile/@shared/autocomplete/component';
import { ProfileBalanceComponent } from '@frontend/profile/@shared/balance/component';
import { ProfileDeveloperShareComponent } from '@frontend/profile/@shared/developer-share/component';
import { ProfileCalendarActionsComponent } from '@frontend/profile/@shared/calendar/actions/component';
import { ProfileCalendarHolderComponent } from '@frontend/profile/@shared/calendar/holder/component';
import { ProfileCalendarMultipleComponent } from '@frontend/profile/@shared/calendar/multiple/component';
import { ProfileCalendarSimpleComponent } from '@frontend/profile/@shared/calendar/simple/component';
import { ProfileCheckboxComponent } from '@frontend/profile/@shared/checkbox/component';
import { ProfileCheckboxGroupComponent } from '@frontend/profile/@shared/checkbox/group/component';
import { ProfileCodesListComponent } from '@frontend/profile/@shared/codes/component';
import { ProfileConfirmationComponent } from '@frontend/profile/@shared/confirmation/component';
import { ProfileContentBoxComponent } from '@frontend/profile/@shared/content-box/component';
import { ProfileContentMsgComponent } from '@frontend/profile/@shared/content-msg/component';
import { ProfileContentMsgBtnComponent } from '@frontend/profile/@shared/content-msg/btn/component';
import { ProfileContentTabsComponent } from '@frontend/profile/@shared/content-tabs/component';
import { ProfileDescriptionComponent } from '@frontend/profile/@shared/description/component';
import { ProfileHeaderComponent } from '@frontend/profile/@shared/header/component';
import { ProfileLogoComponent } from '@frontend/profile/@shared/logo/component';
import { ProfileNavComponent } from '@frontend/profile/@shared/nav/component';
import { ProfileOperationsPreviewComponent } from '@frontend/profile/@shared/operations/preview/component';
import { ProfileOperationsSortableComponent } from '@frontend/profile/@shared/operations/sortable/component';
import { ProfileOrderPreviewSellerComponent } from '@frontend/profile/@shared/order/preview/seller/component';
import { ProfileOrderPreviewDeveloperComponent } from '@frontend/profile/@shared/order/preview/developer/component';
import { ProfileOrderPreviewActionsComponent } from '@frontend/profile/@shared/order/preview/partials/actions/component';
import { ProfileOrderPreviewLinkComponent } from '@frontend/profile/@shared/order/preview/partials/link/component';
import { ProfileOrderPreviewRatingComponent } from '@frontend/profile/@shared/order/preview/partials/rating/component';
import { ProfileOrderPreviewStatusComponent } from '@frontend/profile/@shared/order/preview/partials/status/component';
import { ProfileOrderSortableSellerComponent } from '@frontend/profile/@shared/order/sortable/seller/component';
import { ProfileOrderSortableDeveloperComponent } from '@frontend/profile/@shared/order/sortable/developer/component';
import { ProfilePaginationComponent } from '@frontend/profile/@shared/pagination/component';
import { ProfileProductCategoriesComponent } from '@frontend/profile/@shared/product/categories/component';
import { ProfileProductSubcategoryComponent } from '@frontend/profile/@shared/product/category/component';
import { ProfileProductPreviewComponent } from '@frontend/profile/@shared/product/preview/component';
import { ProfileProductSortableComponent } from '@frontend/profile/@shared/product/sortable/component';
import { ProfileRadioComponent } from '@frontend/profile/@shared/radio/component';
import { ProfileSelectComponent } from '@frontend/profile/@shared/select/component';
import { ProfileStatusSelectComponent } from '@frontend/profile/@shared/select/status/component';
import { ProfileStepsNavComponent } from '@frontend/profile/@shared/steps-nav/component';
import { ProfileTextareaComponent } from '@frontend/profile/@shared/textarea/component';
import { ProfileUploadListComponent } from '@frontend/profile/@shared/upload/document/list/component';
import { ProfileUploadComponent } from '@frontend/profile/@shared/upload/document/component';
import { ProfileUploadPhotoComponent } from '@frontend/profile/@shared/upload/photo/component';
import { ProfileUploadPhotoSingleComponent } from '@frontend/profile/@shared/upload/photo-single/component';
import { ProfileWebhooksPreviewComponent } from '@frontend/profile/@shared/webhooks/preview/component';
import { ProfileWebhooksSortableComponent } from '@frontend/profile/@shared/webhooks/sortable/component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { RouterModule } from '@angular/router';
import { ProfileWebhookLogsPreviewComponent } from "@frontend/profile/@shared/webhook-logs/preview/component";
import { ProfileWebhookLogsSortableComponent } from "@frontend/profile/@shared/webhook-logs/sortable/component";
import { ProfileAddButtonComponent } from '@frontend/profile/@shared/add-button/component';
import { ProfileContentInfoComponent } from '@frontend/profile/@shared/content-info/component';
import { ProfileSuccessMessageComponent } from '@frontend/profile/@shared/success-message/component';
import { ProfileInputActionsComponent } from '@frontend/profile/@shared/input-actions/component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    SharedComponentsModule
  ],
  declarations: [
    ProfileActionsComponent,
    ProfileActionsLinkComponent,
    ProfileActionsButtonComponent,
    ProfileAutocompleteComponent,
    ProfileBalanceComponent,
    ProfileDeveloperShareComponent,
    ProfileCalendarActionsComponent,
    ProfileCalendarHolderComponent,
    ProfileCalendarMultipleComponent,
    ProfileCalendarSimpleComponent,
    ProfileCheckboxComponent,
    ProfileCheckboxGroupComponent,
    ProfileCodesListComponent,
    ProfileConfirmationComponent,
    ProfileContentBoxComponent,
    ProfileContentMsgComponent,
    ProfileContentMsgBtnComponent,
    ProfileContentTabsComponent,
    ProfileDescriptionComponent,
    ProfileHeaderComponent,
    ProfileLogoComponent,
    ProfileNavComponent,
    ProfileOperationsPreviewComponent,
    ProfileOperationsSortableComponent,
    ProfileWebhookLogsPreviewComponent,
    ProfileWebhookLogsSortableComponent,
    ProfileOrderPreviewSellerComponent,
    ProfileOrderPreviewDeveloperComponent,
    ProfileOrderPreviewActionsComponent,
    ProfileOrderPreviewLinkComponent,
    ProfileOrderPreviewRatingComponent,
    ProfileOrderPreviewStatusComponent,
    ProfileOrderSortableSellerComponent,
    ProfileOrderSortableDeveloperComponent,
    ProfilePaginationComponent,
    ProfileProductCategoriesComponent,
    ProfileProductSubcategoryComponent,
    ProfileProductPreviewComponent,
    ProfileProductSortableComponent,
    ProfileRadioComponent,
    ProfileSelectComponent,
    ProfileStatusSelectComponent,
    ProfileStepsNavComponent,
    ProfileTextareaComponent,
    ProfileUploadListComponent,
    ProfileUploadComponent,
    ProfileUploadPhotoComponent,
    ProfileUploadPhotoSingleComponent,
    ProfileWebhooksPreviewComponent,
    ProfileWebhooksSortableComponent,
    ProfileAddButtonComponent,
    ProfileContentInfoComponent,
    ProfileSuccessMessageComponent,
    ProfileInputActionsComponent,
  ],
  exports: [
    ProfileActionsComponent,
    ProfileActionsLinkComponent,
    ProfileActionsButtonComponent,
    ProfileAutocompleteComponent,
    ProfileBalanceComponent,
    ProfileDeveloperShareComponent,
    ProfileCalendarActionsComponent,
    ProfileCalendarHolderComponent,
    ProfileCalendarMultipleComponent,
    ProfileCalendarSimpleComponent,
    ProfileCheckboxComponent,
    ProfileCheckboxGroupComponent,
    ProfileCodesListComponent,
    ProfileConfirmationComponent,
    ProfileContentBoxComponent,
    ProfileContentMsgComponent,
    ProfileContentMsgBtnComponent,
    ProfileContentTabsComponent,
    ProfileDescriptionComponent,
    ProfileHeaderComponent,
    ProfileLogoComponent,
    ProfileNavComponent,
    ProfileOperationsPreviewComponent,
    ProfileOperationsSortableComponent,
    ProfileWebhookLogsPreviewComponent,
    ProfileWebhookLogsSortableComponent,
    ProfileOrderPreviewSellerComponent,
    ProfileOrderPreviewDeveloperComponent,
    ProfileOrderPreviewActionsComponent,
    ProfileOrderPreviewLinkComponent,
    ProfileOrderPreviewRatingComponent,
    ProfileOrderPreviewStatusComponent,
    ProfileOrderSortableSellerComponent,
    ProfileOrderSortableDeveloperComponent,
    ProfilePaginationComponent,
    ProfileProductCategoriesComponent,
    ProfileProductSubcategoryComponent,
    ProfileProductPreviewComponent,
    ProfileProductSortableComponent,
    ProfileRadioComponent,
    ProfileSelectComponent,
    ProfileStatusSelectComponent,
    ProfileStepsNavComponent,
    ProfileTextareaComponent,
    ProfileUploadListComponent,
    ProfileUploadComponent,
    ProfileUploadPhotoComponent,
    ProfileUploadPhotoSingleComponent,
    ProfileWebhooksPreviewComponent,
    ProfileWebhooksSortableComponent,
    ProfileAddButtonComponent,
    ProfileContentInfoComponent,
    ProfileSuccessMessageComponent,
    ProfileInputActionsComponent,
  ],
})

export class ProfileSharedModule {

}
import { Component, EventEmitter , Input, Output } from '@angular/core';
import { EmitterService } from '@frontend/@shared/services';

@Component({
  selector: 'yougiver-popup',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})
export class PopupComponent {
  @Input() title: string;
  @Output() showPopup = new EventEmitter<boolean>();

  closePopup() {
    this.showPopup.emit(false);
    EmitterService.get('POPUP').emit(false);
    EmitterService.get('SWITCH_ACCOUNT').emit(false);
    EmitterService.get('SWITCH_LANGUAGE').emit(false);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '@frontend/@shared/services';
import { YougiverValidators } from '@frontend/@shared/directives';

import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators
} from '@angular/forms';

@Component({
  selector: 'yougiver-sign-in-form',
  template: require('./in.component.pug')
})
export class SignInFormComponent implements OnInit {
  private passwordShown: boolean = false;
  private passwordInputType: string = 'password';
  private passwordIcon: string = 'eye'

  form: FormGroup;
  email: AbstractControl;
  password: AbstractControl;
  remember_me: AbstractControl;
  private resultError: Object;
  public isError: boolean = false;
  public notFound: boolean = false;

  private invalidForm: Object;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', YougiverValidators.email],
      password: ['', Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(8)])],
      remember_me: null
    });

    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];
    this.remember_me = this.form.controls['remember_me'];
  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      this.userService.login(this.form.value).subscribe((result) => {
        if (result['api_key']) {
          this.isError = false;
          this.router.navigate(['profile']);
        }
        else if (result['error']) {
          this.notFound = true;
          this.invalidForm = this.form.value;
          this.resultError = result;
        }
      });
    } else {
      this.isError = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  loginErrors() {

    if (this.resultError) {
      let res = this.resultError;
      this.resultError = null;
      return res;
    }
    if (this.notFound === true) {
      this.notFound = (JSON.stringify(this.invalidForm) === JSON.stringify(this.form.value));
      return {};
    }
    else if (this.email.errors) {
      return this.email.errors;
    }
    else {
      return {};
    }
  }
  
  public onTogglePassword() {
    this.passwordShown = !this.passwordShown

    if (this.passwordShown) {
      this.passwordInputType = 'text'
      this.passwordIcon = 'eye_slash'
    } else {
      this.passwordInputType = 'password'
      this.passwordIcon = 'eye'
    }
  }
}

import { Directive, HostListener, Input, ElementRef } from '@angular/core';
/* tslint:disable */
@Directive({
  selector: '[offClick]'
})
export class OffClickDirective {
  
  @Input('offClick') public offClickHandler: any;

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.el.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.clickedOutside();
    }
  }

  constructor(private el: ElementRef) {
  }

  clickedOutside() {
    if (this.offClickHandler instanceof Function) {
      this.offClickHandler()
    }
  }
}
/* tslint:enable */

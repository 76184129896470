import { ProfileActivationComponent } from '@frontend/profile/activation/activation.component';
import { ProfileActivationGoodsAndScheduleComponent } from '@frontend/profile/activation/goods-and-schedule/goods-and-schedule.component';
import { ProfileActivationMainInfoComponent } from '@frontend/profile/activation/main-info/main-info.component';
import { ProfileActivationDocumentsAndPaymentsComponent } from '@frontend/profile/activation/documents-and-payments/documents-and-payments.component';

export const profileActivationRoutes = {
  path: 'activation',
  component: ProfileActivationComponent,
  children: [
    {
      path: '', redirectTo: 'main-info', pathMatch: 'full'
    },
    {
      path: 'main-info',
      component: ProfileActivationMainInfoComponent
    },
    {
      path: 'goods-and-schedule',
      component: ProfileActivationGoodsAndScheduleComponent
    },
    {
      path: 'documents-and-payments',
      component: ProfileActivationDocumentsAndPaymentsComponent
    }
  ]
};
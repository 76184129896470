import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-profile-operations-sortable',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileOperationsSortableComponent {
}

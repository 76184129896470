import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-profile-product-subcategory',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})


export class ProfileProductSubcategoryComponent {

  @Input() title: any;

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-sign-form-text',
  styles: [require('./@styles/_import.sass')],
  template: require('./text.component.pug')
})

export class SignFormSharedTextComponent {

  @Input() text: any;

}
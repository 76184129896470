import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'yougiver-profile-activation-wrapper',
  encapsulation: ViewEncapsulation.None,
  template: `<router-outlet></router-outlet>`
})

export class ProfileActivationComponent {
  constructor() {
  }
}

import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Helper } from '@frontend/@shared/services';

@Injectable()
export class TranslationsService {
  private translationsUrl = '/api/v1/translations';
  private apiKey: string;
  public translations: Object;
  public headers;
  public options;


  constructor(private http: Http, private ls: CoolLocalStorage) {
    this.apiKey = ls.getItem('auth_token');
    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Yougiver-Token': this.apiKey
    });
    this.options = new RequestOptions({ headers: this.headers });
  }


  getTranslations(params: Object = undefined, setLocale = true): void {
    if (params) {
      this.options.search = new URLSearchParams(Helper.toParams({ params: params }));
    }
    this.http.get(this.translationsUrl, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => error.json() || 'Server error')
      .forEach(result => {
        this.translations = result;
        if (setLocale) {
          this.ls.setItem('locale', params['locale']);
        }
      });
  }

  getTranslationByKey(keys: string[]): string {
    if (this.translations) {
      let res = undefined;
      for (let key of keys) {
        res !== undefined ? res = res[key] : res = this.translations[key];
      }
      return res;
    }
    else {
      return '';
    }
  }



}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-sign-info',
  styles: [require('./@styles/_import.sass')],
  template: require('./sign-info.component.pug')
})

export class SignInfoComponent {
  @Input() icon: string;
  @Input() title: string;
}
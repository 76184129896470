import { Component, OnInit } from '@angular/core';
import { TranslationsService, AppConfig } from '@frontend/@shared/services';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router, NavigationEnd } from '@angular/router';

let svgs = require.context('@assets/frontend/ico', false, /\.svg$/);
svgs.keys().forEach(svgs);

@Component({
  selector: 'yougiver-app',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {

  constructor(
    private translationsService: TranslationsService, 
    private ls: CoolLocalStorage, 
    private config: AppConfig, 
    private router: Router
  ) {
    router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        const windowWithDataLayer: any = window;

        windowWithDataLayer.dataLayer = windowWithDataLayer.dataLayer || [];
        windowWithDataLayer.dataLayer.push({
          'event': 'virtualPageview',
          'pageUrl': window.location.href 
        });
      }
    })
  }

  ngOnInit() {
    let locale = this.ls.getItem('locale');

    if (!locale) {
      locale = this.config.getConfig('locale');
      this.ls.setItem('locale', locale);
    }
    this.translationsService.getTranslations({locale: locale});
  }
}

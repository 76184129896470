import { Component, Input, Output, EventEmitter } from '@angular/core';


import { Product } from '@frontend/@shared/types';

@Component({
  selector: 'yougiver-profile-product-preview',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})


export class ProfileProductPreviewComponent {
  @Input() product: Product;
  @Output() productId = new EventEmitter<number>();
  private isActive: boolean = false;

  public destroy(selected: Object) {
    if (selected['destroy'] === true) {
      this.productId.emit(selected['value']);
    }
    else {
      this.isActive = false;
    }
  }
}

import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { CoolLocalStorage } from 'angular2-cool-storage';

@Injectable()
export class LoggedOutGuard implements CanActivate {
  constructor(private user: UserService, private router: Router, private ls: CoolLocalStorage) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.user.loggedIn) {
      return this.redirect();
    } else {
      return this.user.getCurrentUser().map(res => {
        if (res['api_key']) {
          this.user.loggedIn = true;
          return this.redirect();
        }
        return true;
      }).take(1);
    }
  }

  redirect(): boolean {
    let accountType = this.ls.getItem('accountType');
    if (accountType === 'Seller') {
      this.router.navigateByUrl('/profile/settings/common_info');
      return false;
    } else if (accountType === 'Developer') {
      this.router.navigateByUrl('/profile/developer/settings/common');
      return false;
    } else {
      return true;
    }
  }
}

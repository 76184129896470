import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';
import { ProfileSettingsComponent } from '@frontend/profile/settings/settings.component';
import { ProfileSettingsCategoriesComponent } from '@frontend/profile/settings/categories/categories.component';
import { ProfileSettingsCommonInfoComponent } from '@frontend/profile/settings/common-info/common-info.component';
import { ProfileSettingsGeneralComponent } from '@frontend/profile/settings/general/general.component';
import { ProfileSettingsPaymentsComponent } from '@frontend/profile/settings/payments/payments.component';
import { ProfileSettingsIntegrationsComponent } from "@frontend/profile/settings/integrations/integrations.component";
import { ProfileSettingsShopByLinkComponent } from "@frontend/profile/settings/shop-by-link/shop-by-link.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ProfileSharedModule,
    SharedComponentsModule,
    DirectivesModule
  ],
  declarations: [
    ProfileSettingsComponent,
    ProfileSettingsCategoriesComponent,
    ProfileSettingsCommonInfoComponent,
    ProfileSettingsGeneralComponent,
    ProfileSettingsPaymentsComponent,
    ProfileSettingsIntegrationsComponent,
    ProfileSettingsShopByLinkComponent
  ],
  exports: [
    ProfileSettingsComponent,
    ProfileSettingsCategoriesComponent,
    ProfileSettingsCommonInfoComponent,
    ProfileSettingsGeneralComponent,
    ProfileSettingsPaymentsComponent,
    ProfileSettingsIntegrationsComponent,
    ProfileSettingsShopByLinkComponent
  ]
})
export class ProfileSettingsModule { }

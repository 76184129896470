import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'yougiver-payment',
  encapsulation: ViewEncapsulation.None,
  template: require('./payment.component.html'),
  styles: [require('./styles/_import.sass')]
})

export class PaymentComponent implements OnInit {
  constructor(private route: ActivatedRoute) { }

  private isPaymentSuccess: boolean;

  ngOnInit() {
    this.route.data.subscribe((data: { isPaymentSuccess: boolean }) => {
      this.isPaymentSuccess = data.isPaymentSuccess;
    });
  }
}

import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Helper, EmitterService } from '@frontend/@shared/services';
import { Account, Accounts } from '@frontend/@shared/types';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class AccountService {
  private accountsUrl = '/api/v1/accounts';
  private locationsUrl = '/api/v1/locations';
  private apiKey: string;
  public accounts: Accounts;

  public headers;
  public options;

  constructor(private http: Http, private ls: CoolLocalStorage) {
    this.setHeaders();
  }

  accountSubscribe(params: Object = undefined): void {
    this.setHeaders();
    let accountType = this.ls.getItem('accountType') || this.ls.getItem('signUpAs');
    if (accountType) {
      accountType = accountType.toLowerCase();
    }

    if (params) {
      params = Helper.transformQueryParams(params);
      let ls = params.hasOwnProperty('locale') ? {locale: params['locale']} : this.ls;
      this.options.search = new URLSearchParams(Helper.toParams(params, ls));
    }
    this.http.get(`${this.accountsUrl}?account_type=${accountType}`, this.options)
      .map((res: Response) => res.json())
      .forEach(result => {
        let index = 0;
        this.accounts = result;
        if (index === 0) {
          EmitterService.get('ACCOUNTS').emit(this.accounts);
        }
        index++;
      });
  }

  getAccount(): Account {
    let accountId = +this.ls.getItem('accountId');
    if (!this.accounts) {
      // this.accountSubscribe({ account_id: accountId });
      EmitterService.get('ACCOUNTS').subscribe(accounts => {
        this.accounts = accounts;
        return accountId === undefined ? this.accounts.accounts[0] :
          this.accounts.accounts.find(account => account.id === accountId);
      });
    } else {
      return accountId === undefined ? this.accounts.accounts[0] :
        this.accounts.accounts.find(account => account.id === accountId);
    }
  }

  setAccount(currentAccount: Account) {
    let index = this.accounts.accounts.indexOf(this.accounts.accounts.find(account => account.id === currentAccount.id));
    this.accounts.accounts[index] = currentAccount;
    EmitterService.get('ACCOUNT').emit(currentAccount);
  }

  createAccount(body): Observable<any> {
    let bodyString = JSON.stringify(body);
    return this.http.post(this.accountsUrl, bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  destroyAccount(id): Observable<any> {
    return this.http.delete(`${this.accountsUrl}/${id}`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  getAccounts(params: Object = undefined): Observable<any> {
    this.setHeaders();
    let accountType = this.ls.getItem('accountType') || this.ls.getItem('signUpAs');
    if (accountType) {
      accountType = accountType.toLowerCase();
    }
    if (params) {
      params = Helper.transformQueryParams(params);
      this.options.search = new URLSearchParams(Helper.toParams(params, this.ls));
    }
    return this.http.get(`${this.accountsUrl}?account_type=${accountType}`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  setHeaders() {
    if (this.apiKey !== this.ls.getItem('auth_token')) {
      this.apiKey = this.ls.getItem('auth_token');
      this.headers = new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'X-Yougiver-Token': this.apiKey
      });
      this.options = new RequestOptions({ headers: this.headers });
    }
  }

  updateAccount(id: number, body: Object): Observable<any> {
    this.options.body = undefined;
    this.options.search = new URLSearchParams(Helper.toParams({p: { account_id: id }}, this.ls));
    let bodyString = JSON.stringify({ account: body }, null, 2);
    let accountType = this.ls.getItem('accountType') || this.ls.getItem('signUpAs');
    if (accountType) {
      accountType = accountType.toLowerCase();
    }
    return this.http.put(`${this.accountsUrl}/${id}?account_type=${accountType}`, bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  destroyAsset(id, file): Observable<any> {
    let destroyPath;
    if (id && file) {
      destroyPath = `${this.accountsUrl}/${id}/assets/${file.id}`;
    } else if (!id && file) {
      destroyPath = `${this.accountsUrl}/assets/${file.id}`;
    }

    if (file) {
      this.options.body = { type: file.type };
    }

    return this.http.delete(destroyPath, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  getLocations(params: Object = undefined): Observable<any> {
    if (params) {
      this.options.search = new URLSearchParams(this.toParams(params));
    }

    return this.http.get(this.locationsUrl, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  getCountries(params: Object = undefined): Observable<any> {
    if (params) {
      this.options.search = new URLSearchParams(this.toParams(params));
    }

    return this.http.get(`${this.locationsUrl}/countries`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  private toParams(object) {
    let messages = [];
    for (let name in object) {
      if (object[name as string]) {
        messages.push(`${name}=${object[name as string]}`);
      }
    }
    return messages.join('&');
  }
}

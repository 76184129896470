import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
  Validators
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { AccountService, AppConfig, EmitterService } from '@frontend/@shared/services';
import { Account, AccountContractor, Location } from '@frontend/@shared/types';
import { YougiverValidators } from '@frontend/@shared/directives';
import { CoolLocalStorage } from 'angular2-cool-storage';


@Component({
  selector: 'yougiver-profile-settings-common-info',
  template: require('./common-info.component.pug'),
  styles: [require('./styles/_import.sass')]
})
export class ProfileSettingsCommonInfoComponent implements OnInit, OnDestroy {
  protected subscriptions: Array<Subscription> = [];
  protected invalidForm: boolean = false;
  protected formSubmitted: boolean = false;
  public account: Account;

  public locationsMaxSize: number = 10; //5

  public locations: Object = {};
  public removedLocations: Array<Location> = [];
  public countries: Array<Object> = [];

  public headersForUploader: Array<any> = [];

  public timeMask = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];

  form: FormGroup;
  site_url: AbstractControl;
  seller_currency: AbstractControl;
  subscribed: AbstractControl;
  working_hours_attributes: AbstractControl;
  contractors_attributes: AbstractControl;
  slug: AbstractControl;

  // protected contractorLabelsByType: Array<Object> = [];
  protected timer_id: any = 0;

  constructor(protected _changeDetectionRef: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected router: Router,
    protected ls: CoolLocalStorage,
    protected config: AppConfig) {
    this.fillInCountry = this.fillInCountry.bind(this);
    this.fillInLocation = this.fillInLocation.bind(this);
    // this.contractorLabelsByType = [
    //   {
    //     str1: 'profile.store_settings.official_company_name',
    //     str2: 'profile.store_settings.physical_adress',
    //     str3: 'profile.store_settings.company_email',
    //     str4: 'profile.store_settings.company_phone'
    //   },
    //   {
    //     str1: 'profile.name',
    //     str2: 'profile.address',
    //     str3: 'profile.email',
    //     str4: 'profile.phone'
    //   }
    // ];
  }

  clearOldData() {
    this.removedLocations = [];
    this.locations = {};
  }

  protected getAccount() {
    this.account = this.accountServise.getAccount();
    this.formSetup();
    if (this.account) {
      this.formUpdate();
    }
    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      // let accountId = +this.ls.getItem('accountId');
      this.account = account;
      // this.account = accounts.accounts.find(account => account.id === +accountId);
      this.clearOldData();
      this.formUpdate();
    }));
  }

  ngOnInit() {
    this.getAccount();

    this.accountServise.headers.forEach((value, name) => {
      if (name.toLowerCase() !== 'content-type') {
        this.headersForUploader.push({ name: name, value: value[0] });
      }
    });

    this.subscriptions.push(EmitterService.get('DELETE_UPLOADED_FILE').subscribe(file => {
      if (!file.deleted) {
        this.accountServise.destroyAsset(this.account.id, file).subscribe(accounts => {
          if (accounts.count > 0) {
            this.account = accounts.accounts.find(account => account.id === this.account.id);
          }
        },
          err => { console.log(err); });
      }
    }));

    this.subscriptions.push(EmitterService.get('FILE_UPLOADER_EVENT').subscribe(res => {
      let response = JSON.parse(res.response);
      if (res.status <= 400) {
        this.account[response['snake_type']].push(response);
        EmitterService.get('NOTIFICATION').emit(response);
      } else if (response['errors']) {
        EmitterService.get('NOTIFICATION').emit({ error: response['errors']['attachment'][0] });
      }
    }));

    EmitterService.get('SET_BREADCRUMB').emit(['shared.settings', 'profile.navbar.settings.common_info']);

    this._changeDetectionRef.detectChanges();
  }

  formSetup() {
    this.form = this.fb.group({
      site_url: '',
      subscribed: '',
      slug: '',
      seller_currency: '',
      working_hours_attributes: this.fb.array([
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours })
      ]),
      contractors_attributes: this.fb.array([])
    });
    this.site_url = this.form.controls['site_url'];
    this.seller_currency = this.form.controls['seller_currency'];
    this.slug = this.form.controls['slug'];
    this.subscribed = this.form.controls['subscribed'];
    this.working_hours_attributes = this.form.controls['working_hours_attributes'];
    this.contractors_attributes = this.form.controls['contractors_attributes'];
  }

  formUpdate() {
    this.subscribed.patchValue(this.account.subscribed);
    this.site_url.patchValue(this.account.site_url);
    this.seller_currency.patchValue(this.account.seller_currency);
    this.slug.patchValue(this.account.slug);

    this.account.working_hours.forEach(day => {
      let day_control = (this.working_hours_attributes as any).controls[day.isodow - 1].controls;
      day_control['begin'].patchValue(day.begin);
      day_control['end'].patchValue(day.end);
      if (day.begin && day.end) {
        day_control['isodow'].patchValue(day.isodow);
      } else {
        day_control['isodow'].patchValue(false);
      }
      if (day.id) {
        day_control['id'].patchValue(day.id);
      }
    });

    for (let key in this.account.contractor) {
      if (this[key] && this.account['contractor'][key] !== null) {
        this[key].patchValue(this.account['contractor'][key]);
      }
    }

    this.account.contractors.forEach((contractor, index) => {
      if (!contractor.locations) {
        contractor.locations = [];
      }
      if (contractor.locations.length === 0 && !contractor.whole_country_in_stack) {
        this.addNewLocation(index, contractor);
      } else {
        this._changeDetectionRef.detectChanges();
        const control = this.contractors_attributes['controls'][index];
        control.patchValue(contractor.locations);
      }
    })
  }

  initGeneralInfo(contractors_control: number, contractor: AccountContractor) {
    const control = <FormArray>this.contractors_attributes;

    if (!control.controls[contractors_control]) {
      const settings = new FormGroup({
        id: new FormControl(contractor.id || null),
        _destroy: new FormControl(contractor._destroy || false),
        directors_name: new FormControl(contractor.directors_name || null, [Validators.required, YougiverValidators.whitespace,
          Validators.minLength(3), Validators.maxLength(100)]),
        country: new FormControl(contractor.country || null, Validators.required),
        country_name: new FormControl(contractor.country_name || null, Validators.required),
        vat: new FormControl(contractor.vat || null, YougiverValidators.decimal),
        whole_country_in_stack: new FormControl(contractor.whole_country_in_stack || null),
        type_of: new FormControl(contractor.type_of || null),
        location_id: new FormControl(contractor.location_id || null),
        str1: new FormControl(contractor.str1 || null, [Validators.required, YougiverValidators.whitespace,
          Validators.minLength(3), Validators.maxLength(100)]),
        str2: new FormControl(contractor.str2 || null, [Validators.required, YougiverValidators.whitespace,
          Validators.minLength(3), Validators.maxLength(100)]),
        str3: new FormControl(contractor.str3 || null, [Validators.required, YougiverValidators.email]),
        str4: new FormControl(contractor.str4 || null, [Validators.required, YougiverValidators.phone]),
        locations_attributes: new FormArray(this.updateCitiesAttributes(contractor), YougiverValidators.uniqOsmId)
      })

      control.setControl(contractors_control, settings);

      this.countryChangeHandler(settings, contractors_control);
      this.wholeCountryInStackChangeHandler(settings, contractors_control, contractor);
    }

    return contractors_control;
  }

  updateCitiesAttributes(contractor: AccountContractor): FormGroup[] {
    if (contractor.locations && contractor.locations.length > 0) {
      return contractor.locations.map(location =>
        this.initLocation(contractor.id, location)
      )
    } else {
      return [this.initLocation(contractor.id)];
    }
  }

  initLocation(contractorId: number, location?: Location) {
    return new FormGroup({
      id: new FormControl(location ? location.id : null),
      seller_id: new FormControl(this.account.id),
      contractor_id: new FormControl(contractorId || null),
      location_id: new FormControl(location ? location.location_id : null, Validators.required),
      shipping_cost: new FormControl(location ? location.shipping_cost : '', YougiverValidators.decimal),
      name: new FormControl(location ? location.name : ''),
      city: new FormControl(location ? location.city : '', Validators.required),
      state: new FormControl(location ? location.state : ''),
      display_name: new FormControl(location ? location.display_name : ''),
      _destroy: new FormControl(false)
    })
  }

  initLocationsControl(contractor_control: number, location_control: number, location: Location) {
    const contractorControl = this.contractors_attributes['controls'][contractor_control];
    const locationControl = <FormArray>contractorControl['controls']['locations_attributes'];


    if (!locationControl.controls[location_control]) {
      const settings = this.initLocation(location.contractor_id, location);
      locationControl.setControl(location_control, settings);

      this.cityChangeHandler(locationControl, location_control, contractor_control)
    }
    return location_control;
  }

  addNewLocation(i, contractor: AccountContractor) {
    if (this.account.contractors[i]) {
      this.account.contractors[i].locations.push({
        id: null,
        seller_id: this.account.id,
        contractor_id: contractor.id,
        name: null,
        display_name: null,
        shipping_cost: null,
        location_id: null,
        city: null,
        state: null,
        _destroy: false
      });
    }
    this._changeDetectionRef.detectChanges();
  }

  addNewCountry() {
    const contractor = this.account.contractor;
    this.account.contractors.push({
      id: null,
      _destroy: null,
      directors_name: contractor ? contractor.directors_name : null,
      country: null,
      country_name: null,
      vat: null,
      whole_country_in_stack: false,
      type_of: null,
      location_id: null,
      str1: contractor ? contractor.str1 : null,
      str2: contractor ? contractor.str2 : null,
      str3: contractor ? contractor.str3 : null,
      str4: contractor ? contractor.str4 : null,
      locations: [{
        id: null,
        seller_id: this.account.id,
        contractor_id: null,
        name: null,
        display_name: null,
        shipping_cost: null,
        location_id: null,
        city: null,
        state: null,
        _destroy: false
      }]
    })
    this._changeDetectionRef.detectChanges();
  }

  removeCountry(contractorIndex: number) {
    let contractors = <FormArray>this.contractors_attributes;
    let contractor = contractors.controls[contractorIndex];
    if (contractor['value']['id']) {
      (contractor as any).controls['_destroy'].patchValue(true);
    } else {
      contractors.removeAt(contractorIndex);
    }
    // this.account.contractors.splice(contractorIndex, 1);
    this.account.contractors[contractorIndex]._destroy = true;
  }

  removeLocation(contractorIndex: number, locationIndex: number): void {
    let contractor = this.contractors_attributes['controls'][contractorIndex];
    let locations = <FormArray>contractor['controls'].locations_attributes;

    if (locations.controls[locationIndex]['value']['id']) {
      (locations.controls[locationIndex] as any).controls['_destroy'].patchValue(true);
      // this.removedLocations.push(locations.at(locationIndex).value);
    } else {
      locations.removeAt(locationIndex);
    }
    this.updateLocations(contractorIndex, locationIndex);
    this.account.contractors[contractorIndex].locations.splice(locationIndex, 1);
  }

  updateLocations(contractorIndex: number, index: number) {
    let locations = this.account.contractors[contractorIndex].locations;

    let keys = +Object.keys(locations)[Object.keys(locations).length - 1];
    for (let i = index; i < keys; i++) {
      if (locations.hasOwnProperty(i)) {
        locations[i] = locations[i + 1];
      }
    }
    delete locations[keys];
  }

  isCheckedWorkingDay(day: any) {
    return (day.begin && day.end);
  }

  previousWorkingDayControl(index: number) {
    let controls = (this.working_hours_attributes as any).controls.filter(control => control['hours'] !== null);
    if (controls.length > 0) {
      let ctrl = controls.find(ctrl => ctrl.controls['begin'].value && ctrl.controls['end'].value);
      if (ctrl) {
        controls[index].controls['begin'].patchValue(ctrl.controls['begin'].value);
        controls[index].controls['end'].patchValue(ctrl.controls['end'].value);
        return { begin: ctrl.controls['begin'].value, end: ctrl.controls['end'].value };
      } else {
        return { begin: null, end: null };
      }
    } else {
      return { begin: null, end: null };
    }
  }

  assets_path() {
    return `/api/v1/accounts/${this.account.id}/assets`;
  }

  onSubmit(value: any): void {
    const contractors = this.contractors_attributes['controls'];
    contractors.forEach((control) => {
      control['controls']['locations_attributes'].setErrors(null);
      control['controls']['locations_attributes'].markAsTouched();
    })
    this.formSubmitted = true;

    if (this.form.valid) {
      this.invalidForm = false;
      let index = 0;
      let value_object = JSON.parse(JSON.stringify(this.form.value));
      value_object['working_hours_attributes'] = [];
      (this.working_hours_attributes as any).controls.forEach(day_group => {
        index++;
        let day = day_group.controls;
        if (day.isodow.value) {
          value_object['working_hours_attributes'].push(
            { id: day.id.value, isodow: day.isodow.value, hours: `[${day.begin.value},${day.end.value}]` }
          );
        } else {
          value_object['working_hours_attributes'].push(
            { id: day.id.value, isodow: index, hours: null }
          );
        }
      });
      // value_object['locations_attributes'] = value_object['locations_attributes'].concat(this.removedLocations);
      this.accountServise.updateAccount(this.account.id, value_object).subscribe((res: any) => {
        if (res) {
          if (res['resource']) {
            this.locations = {};
            this.removedLocations = [];
            this.account = res['resource'];
            this.accountServise.setAccount(res['resource']);
          }
          EmitterService.get('NOTIFICATION').emit(res);
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  sellerUrl(): string {
    let host = this.config.getConfig('new_front_domain');

    return `${host}/` + (this.account.slug || '')
  }

  wholeCountryInStackChangeHandler(settings: FormGroup, contractors_control: number, contractor: AccountContractor) {
    const wholeCountryInStackSub = settings.controls['whole_country_in_stack'].valueChanges.subscribe((value) => {
      this.account.contractors[contractors_control].whole_country_in_stack = value;

      if (!value && this.account.contractors[contractors_control].locations.length < 1) {
        this.addNewLocation(contractors_control, contractor)
        return
      } else if (!value) {
        return
      }

      settings.controls['locations_attributes']['controls'].forEach((attribute, index) => {
        this.removeLocation(contractors_control, index)
      })
    });
    this.subscriptions.push(wholeCountryInStackSub);
  }

  countryChangeHandler(settings: FormGroup, contractors_control: number) {
    const countryChangeSub = settings.controls['country_name'].valueChanges.subscribe((value) => {
      if (value === this.account.contractors[contractors_control].country_name) {
        return;
      }
      settings.controls['country'].patchValue('');
      this.subscriptions.push(countryChangeSub);
    })
  }

  cityChangeHandler(locationControl: FormArray, location_control: number, contractor_control: number) {
    const subscription = locationControl.controls[location_control]['controls']['city'].valueChanges.subscribe((value) => {
      const locationsAttributes = this.account.contractors[contractor_control].locations;
      const selectedCity = locationsAttributes[location_control] && locationsAttributes[location_control].city
      if (value === selectedCity) {
        return
      }
      if (!locationControl.controls[location_control]) {
        return
      }
      locationControl.controls[location_control].patchValue({
        location_id: null,
      })
    })
    this.subscriptions.push(subscription);
  }

  trackLocationBy(index: number, location) {
    return index.toString()
  }

  fillInCountry(contractorIndex, place): void {
    const contractor = <FormArray>this.contractors_attributes['controls'][contractorIndex];
    contractor.controls['country_name'].patchValue(place.name);
    contractor.controls['country'].patchValue(place.address_components[0].short_name.toLowerCase());

    this.account.contractors[contractorIndex].country_name = place.name;
    this.account.contractors[contractorIndex].country = place.country;
  }

  filterCityCountryAddressComponents(comp) {
    return comp.types.some(v => ['administrative_area_level_1', 'sublocality', 'route'].includes(v))
  }

  filterCityAddressComponent(comp) {
    return comp.types.some(v => ['locality', 'administrative_area_level_2'].includes(v))
  }

  filterStateAddressComponent(comp) {
    return comp.types.some(v => ["administrative_area_level_1"].includes(v))
  }

  fillInLocation(place, contractorIndex, id): void {
    const displayName = place.address_components
      .filter(this.filterCityCountryAddressComponents)
      .reverse()
      .map(v => v.long_name)
      .join(', ')

    const city = (place.address_components.filter(this.filterCityAddressComponent)[0] || {}).long_name
    const state = (place.address_components.filter(this.filterStateAddressComponent)[0] || {}).long_name

    const location = {
      location_id: place.place_id,
      city,
      state,
      name: city,
      display_name: displayName
    }

    this.account.contractors[contractorIndex].locations[id] = {
      ...this.account.contractors[contractorIndex].locations[id],
      ...location
    }

    const contractor = <FormArray>this.contractors_attributes['controls'][contractorIndex];
    contractor.controls['locations_attributes']['controls'][id].patchValue(location);
  }

  // showMoreLocations() {
  //   this.locationsMaxSize = this.locationsMaxSize + 5;
  // }

  getCountryRestrictions(contractorIndex) {
    const contractor = this.contractors_attributes['controls'][contractorIndex];
    return contractor['controls'].country.value ? { country: [contractor['controls'].country.value] } : null
  }

  getIsGeneralInfoErrors(index, attributeName) {
    const contractorsAttributes = this.contractors_attributes['controls'][index];
    const attribute = contractorsAttributes['controls'][attributeName];
    return this.checkIsGeneralAttributeValid(index, attributeName) ? attribute.errors : null;
  }

  checkIsGeneralAttributeValid(index, attributeName) {
    const contractorsAttributes = this.contractors_attributes['controls'][index];
    const attribute = contractorsAttributes['controls'][attributeName];
    const isValidationNeeded = this.formSubmitted && (attribute.touched || this.invalidForm);

    return isValidationNeeded && !attribute.valid;
  }

  getIsLocationAttributeErrors(contractorIndex, locationIndex, attributeName) {
    const contractorsAttributes = this.contractors_attributes['controls'][contractorIndex];
    const locationsAttributes = contractorsAttributes['controls']['locations_attributes'];
    const location = locationsAttributes['controls'][locationIndex];
    const attribute = location['controls'][attributeName];
    return this.checkIsLocationAttributeInvalid(contractorIndex, locationIndex, attributeName) ? attribute.errors : null;
  }

  checkIsLocationAttributeInvalid(contractorIndex, locationIndex, attributeName) {
    const contractorsAttributes = this.contractors_attributes['controls'][contractorIndex];
    const locationsAttributes = contractorsAttributes['controls']['locations_attributes'];
    const location = locationsAttributes['controls'][locationIndex];
    const attribute = location['controls'][attributeName];
    const isValidationNeeded = attribute.touched || this.invalidForm;
    return isValidationNeeded && !attribute.valid;
  }
}

import { RouterModule, Routes } from '@angular/router';

import { signRoutes } from '@frontend/sign/sign.routing';
import { profileRoutes } from '@frontend/profile/profile.routing';
import { pagesRoutes } from '@frontend/pages/page.routing';
import { LogoutComponent } from '@frontend/@shared/components/logout/component';
import { PaymentComponent } from '@frontend/payment/payment.component';
import { SignProxyComponent } from '@frontend/sign/sign-proxy.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {path: '', component: SignProxyComponent, pathMatch: 'full'},
      {path: 'logout', component: LogoutComponent},
      {path: 'payment_success', component: PaymentComponent, data: { isPaymentSuccess: true }},
      {path: 'payment_failed', component: PaymentComponent, data: { isPaymentSuccess: false }},
      pagesRoutes,
      signRoutes,
      profileRoutes
    ]
  }
];

export const routing = RouterModule.forRoot(routes);

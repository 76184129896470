/* eslint-disable */
/* tslint:disable */

import { AbstractControl, FormGroup, FormArray, ValidatorFn } from '@angular/forms';
import { EmitterService } from '@frontend/@shared/services';
export const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
export const DECIMAL_REGEXP = /^\d+(\.(\d+)?)?$/;
export const NUMERIC_REGEXP = /^\d+$/;
export const LATINIC_REGEXP = /^[^а-яА-Я]+$/;
export const TIME24H_REGEXP = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const PHONE_REGEXP = /^(\+\d{1,4}([\s.-])?)?\(?\d{3}\)?([\s.-])?\d{2}([\s.-])?\d{1}([\s.-])?\d{1}([\s.-])?\d{1}([\s.-])?\d{2}$/;
export const WHITESPACE_REGEXP = /^\s+$/;
export const WHITESPACE_IN_THE_MIDDLE_OF_WORD_REGEXP = /.\s/;
export const SWIFT_CODE_REGEXP = /^[a-zA-Z0-9]+$/;
export const YOUTUBE_VIDEO_REGEXP = /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
export const VIMEO_VIDEO_REGEXP = /((http|https)?:\/\/)?(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/
export const VIDBY_VIDEO_REGEXP = /((http|https)?:\/\/)?vidby\.com\/(?:[a-z]{2}\/)?order\/[A-Z0-9]+\/embed$/
export const SHOP_NAME_REGEXP = /^\w+$/
export const REFERRAL_LINK_REGEXP = /^(?:\w+\/\w+)+$/
export const HEADER_KEY_REGEXP = /^[a-zA-Z, _-]+$/


export class YougiverValidators {
  static password(control: AbstractControl): { [key: string]: boolean } {
    if (control.value) {
      if (WHITESPACE_IN_THE_MIDDLE_OF_WORD_REGEXP.test(control.value)) {
        return { password_with_blank_records: true };
      }
      return control.value.replace(/[^A-Z]+/g, '').length >= 1 &&
             control.value.replace(/\D+/g, '').length >= 1 &&
             control.value.replace(/[A-Za-z0-9]+/g, '').length >= 1 ? null : { password_format: true };
    } else {
      return null;
    }
  }

  static whitespace(control: AbstractControl): { [key: string]: boolean } {
    return !WHITESPACE_REGEXP.test(control.value) ? null : { required: true };
  }

  static email(control: AbstractControl): { [key: string]: boolean } {
    return EMAIL_REGEXP.test(control.value) ? null : { email: false };
  }

  static decimal(control: AbstractControl): { [key: string]: boolean } {
    return DECIMAL_REGEXP.test(control.value) ? null : { decimal: false };
  }

  static numeric(control: AbstractControl): { [key: string]: boolean } {
    return NUMERIC_REGEXP.test(control.value) ? null : { numeric: false };
  }

  static latinic(control: AbstractControl): { [key: string]: boolean } {
    return LATINIC_REGEXP.test(control.value) ? null : { latinic: false };
  }

  static swift_code(control: AbstractControl): { [key: string]: boolean } {
    return SWIFT_CODE_REGEXP.test(control.value) ? null : { swift_code_format: true };
  }

  static phone(control: AbstractControl): { [key: string]: boolean } {
    return PHONE_REGEXP.test(control.value) ? null : { phone: false };
  }

  static time24h(control: AbstractControl): { [key: string]: boolean } {
    if (control.value === null) {
      return null;
    }
    return TIME24H_REGEXP.test(control.value) ? null : { time: false };
  }

  static workingHours(group: FormGroup): { [key: string]: boolean } {
    let controls = group.controls;
    if (controls['isodow'].value === false) {
      return null;
    }
    return TIME24H_REGEXP.test(controls['begin'].value) && TIME24H_REGEXP.test(controls['end'].value) ? null : { time: false };
  }

  static greaterThan(value: number): ValidatorFn {
    return (control: AbstractControl): any => {
      return +control.value < value ? { greaterThan: { 'requiredValue': value, 'actualValue': +control.value } } : null;
    };
  }

  static greaterThanOrNull(value: number): ValidatorFn {
    return (control: AbstractControl): any => {
      if (control.value == null) {
        return null;
      }
      return +control.value < value ? { greaterThan: { 'requiredValue': value, 'actualValue': +control.value } } : null;
    };
  }

  static areEqual(group: FormGroup): { [key: string]: boolean } {
    let valid = false;
    let values = [];

    for (let name in group.controls) {
      if (group.controls[name as string]) {
        values.push(group.controls[name as string].value);
      }
    }

    for (let i = 1; i < values.length; i++) {
      if (values[i] === '' || values[0] === values[i]) {
        valid = true;
      } else {
        valid = false;
      }
    }

    if (valid) {
      return null;
    }

    return {
      areEqual: false
    };
  }

  static securityKey(group: FormGroup): { [key: string]: boolean } {
    let valid = false;
    let values = [];

    for (let name in group.controls) {
      if (group.controls[name as string]) {
        values.push(group.controls[name as string].value);
      }
    }

    for (let i = 1; i < values.length; i++) {
      if ((values[i] && values[i - 1]) || (!values[i] && !values[i - 1])){
        valid = true;
      } else {
        valid = false;
      }
    }

    if (valid) {
      for (let name in group.controls) {
        if (group.controls[name as string]) {
          let ctrl = group.controls[name as string];
          ctrl.setErrors(null);
          ctrl.clearValidators();
        }
      }

      return null;
    }

    for (let name in group.controls) {
      if (group.controls[name as string]) {
        let ctrl = group.controls[name as string];
        if (ctrl.value == null || ctrl.value == '') {
          ctrl.setErrors({required: true});
        }
      }
    }

    return { required: false }
  }

  static uniqOsmId(control: FormArray): { [key: string]: boolean } {
    let values = [];

    for (let group in control.controls) {
      if (control.controls[group as string]) {
        let cg = control.controls[group as string];
        for (let name in cg.controls) {
          if (name as string === 'osm_location_id') {
            let cc = cg.controls[name as string];
            values.push(cc.value);
          }
        }
      }
    }

    let i = 0, m = [];

    let duplicates = values.filter(n => (!m[n] as any) * ~values.indexOf(n, m[n] = ++i));

    if (duplicates.length > 0) {
      return { uniqOsmId: false };
    } else {
      return null;
    }
  }

  static fileValidation(item: any, filter: any, options: any) {
    let errorMap = {
      fileSize: 'shared.validation.file_to_big',
      mimeType: 'shared.validation.wrong_file_extension'
    };
    EmitterService.get('NOTIFICATION').emit({ error: errorMap[filter.name] });
    return { item, filter, options };
  };

  static videoLink(control: AbstractControl): { [key: string]: boolean } {
    if (!control.value) return null

    return YOUTUBE_VIDEO_REGEXP.test(control.value) 
      ? null 
      : (
        VIMEO_VIDEO_REGEXP.test(control.value)
          ? null
          : VIDBY_VIDEO_REGEXP.test(control.value) ? null : { videoLink: false }
      )
  }

  static headerKey(control: AbstractControl): { [key: string]: boolean } {
    return HEADER_KEY_REGEXP.test(control.value) ? null : { headerKey: false };
  }
  
  static shopName(control: AbstractControl): { [key: string]: boolean } {
    return SHOP_NAME_REGEXP.test(control.value) ? null : { shopName: false };
  }

  static referralLink(control: AbstractControl): { [key: string]: boolean } {
    return REFERRAL_LINK_REGEXP.test(control.value) ? null : { referralLink: false };
  }

  static validationNotifications(error: Object): string | Object {
    if (error['required']) {
      return 'shared.validation.required';
    }
    else if (error['minlength']) {
      return {
        key: 'shared.validation.min_length',
        params: { requiredLength: error['minlength']['requiredLength'] }
      };
    }
    else if (error['maxlength']) {
      return {
        key: 'shared.validation.max_length',
        params: { requiredLength: error['maxlength']['requiredLength'] }
      };
    }
    else if (error['greaterThan']) {
      return {
        key: 'shared.validation.greater_than',
        params: { requiredValue: error['greaterThan']['requiredValue'] }
      };
    }
    else if ('email' in error && error['email'] === false) {
      return 'shared.validation.wrong_format';
    }
    else if ('email' in error && error['email'] instanceof Array) {
      return 'shared.validation.not_available';
    }
    else if ('areEqual' in error && error['areEqual'] === false) {
      return 'shared.validation.are_equal';
    }
    else if ('uniqOsmId' in error && error['uniqOsmId'] === false) {
      return 'shared.validation.uniq_osm_id';
    }
    else if ('decimal' in error && error['decimal'] === false) {
      return 'shared.validation.decimal';
    }
    else if ('numeric' in error && error['numeric'] === false) {
      return 'shared.validation.numeric';
    }
    else if ('latinic' in error && error['latinic'] === false) {
      return 'shared.validation.latinic';
    }
    else if ('phone' in error && error['phone'] === false) {
      return 'shared.validation.wrong_phone_format';
    }
    else if ('videoLink' in error && error['videoLink'] === false) {
      return 'shared.validation.wrong_link_format';
    }
    else if ('shopName' in error && error['shopName'] === false) {
      return 'shared.validation.wrong_shop_name_format';
    }
    else if ('referralLink' in error && error['referralLink'] === false) {
      return 'shared.validation.wrong_referral_link_format';
    }
    else if (error['pattern']) {
      return 'shared.validation.incorrect_format';
    }
    else if (error['password_format']) {
      return 'shared.validation.password_format';
    }
    else if (error['password_incorrect']) {
      return 'shared.validation.password_incorrect';
    }
    else if (error['password_with_blank_records']) {
      return 'shared.validation.password_with_blank_records';
    }
    else if (error['swift_code_format']) {
      return 'shared.validation.swift_code_format';
    }
    else if ('error' in error) {
      return { msg: error['error'] };
    }
    else {
      return '';
    }
  }
}

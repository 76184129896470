import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@frontend/@shared/services';

@Component({
  selector: 'yougiver-sign-restore',
  template: require('./sign-restore.component.pug')
})
export class SignRestoreComponent {
  public response$: any;
  private resetPassword: boolean = false;
  private resetPasswordToken: string;

  constructor(private userService: UserService, private route: ActivatedRoute) {
    route.params.subscribe(params => {
      this.resetPasswordToken = params['reset_password_token'];
      if (this.resetPasswordToken) {
        userService.getResetPassword(this.resetPasswordToken).subscribe(response => {
                                                   this.response$ = response;
                                                   if (response.error) {
                                                     this.resetPassword = null;
                                                   } else {
                                                     this.resetPassword = true;
                                                   }
                                                 }
                                               );
      }
    });
  }

  public response_message(response) {
    let messages = [];
    for (let name in response.errors) {
      if (response.errors[name as string]) {
        messages.push(name + ' ' + response.errors[name as string]);
      }
    }
    return messages.join('\n');
  }
}

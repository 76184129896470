import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Helper } from '@frontend/@shared/services';

@Injectable()
export class StaticPagesService {
  private pagesUrl = '/api/v1/pages';
  
  public headers;
  public options;
  private apiKey: string;

  constructor(private router: Router, private route: ActivatedRoute,
              private http: Http, private ls: CoolLocalStorage) {
    this.apiKey = this.ls.getItem('auth_token');
    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Yougiver-Token': this.apiKey
    });
    this.options = new RequestOptions({ headers: this.headers });
  }

  public show(slug: string): Observable<any>{
    return this.http.get(`${this.pagesUrl}/${slug}`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }
}

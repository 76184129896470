import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
  Validators
} from '@angular/forms';

import { Subscription } from 'rxjs';
import {AccountService, AppConfig, EmitterService} from '@frontend/@shared/services';
import { Account, Location } from '@frontend/@shared/types';
import { YougiverValidators } from '@frontend/@shared/directives';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { ProfileSettingsCommonInfoComponent } from '@frontend/profile/settings/common-info/common-info.component';
declare const google;


@Component({
  selector: 'yougiver-profile-activation-main-info',
  template: require('./main-info.component.pug'),
  styles: [require('./styles/_import.sass')]
})
export class ProfileActivationMainInfoComponent extends ProfileSettingsCommonInfoComponent implements OnInit {
  private loaderState: string = '';

  constructor(protected _changeDetectionRef: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected router: Router,
    protected ls: CoolLocalStorage,
    protected config: AppConfig) {
    super(_changeDetectionRef, fb, accountServise, router, ls, config);
  }


  protected getAccount() {

  }

  ngOnInit() {
    this.account = this.accountServise.getAccount();
    this.formSetup();
    if (this.account) {
      this.formUpdate();
    }
    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.formUpdate();
    }));
    EmitterService.get('SET_BREADCRUMB').emit(['profile.seller.activation']);
  }

  formUpdate() {
    this.subscribed.patchValue(this.account.subscribed);
    this.site_url.patchValue(this.account.site_url);
    this.seller_currency.patchValue(this.account.seller_currency);
    this.slug.patchValue(this.account.slug);

    for (let key in this.account.contractor) {
      if (this[key] && this.account['contractor'][key] !== null) {
        this[key].patchValue(this.account['contractor'][key]);
      }
    }

    if (this.account.contractors.length == 0) {
      this.addNewCountry();
    }

    this.account.contractors.forEach((contractor, index) => {
      if (!contractor.locations) {
        contractor.locations = [];
      }
      if (contractor.locations.length === 0 && !contractor.whole_country_in_stack) {
        this.addNewLocation(index, contractor);
      } else {
        this._changeDetectionRef.detectChanges();
        const control = this.contractors_attributes['controls'][index];
        control.patchValue(contractor.locations);
      }
    })
  }

  formSetup() {
    this.form = this.fb.group({
      site_url: '',
      subscribed: '',
      slug: '',
      seller_currency: '',
      contractors_attributes: this.fb.array([])
    });
    this.site_url = this.form.controls['site_url'];
    this.seller_currency = this.form.controls['seller_currency'];
    this.slug = this.form.controls['slug'];
    this.subscribed = this.form.controls['subscribed'];
    this.contractors_attributes = this.form.controls['contractors_attributes'];
  }

  onSubmit(value: any): void {
    const contractors = this.contractors_attributes['controls'];
    contractors.forEach((control) => {
      control['controls']['locations_attributes'].setErrors(null);
      control['controls']['locations_attributes'].markAsTouched();
    })
    this.formSubmitted = true;

    if (this.form.valid) {
      this.loaderState = 'isLoading';
      this.invalidForm = false;
      let value_object = JSON.parse(JSON.stringify(this.form.value));

      // value_object['locations_attributes'] =  value_object['locations_attributes'].concat(this.removedLocations);
      this.accountServise.updateAccount(this.account.id, value_object).subscribe((res: any) => {
        if (!res.error) {
          this.loaderState = 'isComplete';
          if (res['resource']) {
            this.locations = {};
            this.removedLocations = [];
            this.account = res['resource'];
            this.accountServise.setAccount(res['resource']);
          }
          this.router.navigate(['profile/activation/goods-and-schedule']);
        } else {
          this.loaderState = '';
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  destroyAccount() {
    EmitterService.get('DESTROY_ACCOUNT').emit(this.ls.getItem('accountId'));
  }

  buttonText(): string {
    if(this.account && (this.account.state === 'initial')) {
      return 'profile.activation.next_step';
    } else {
      return 'profile.activation.update_info';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'yougiver-profile-radio',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ProfileRadioComponent), multi: true }
  ],
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileRadioComponent {
  @Input() value: number;
  @Input() _checked: boolean;
  @Input() name: string;
  @Input() link: string;
  @Input() text: string;
  @Input() initialValue: number;

  onTouchedCallback: any = () => {};
  propagateChange: any = () => {};
  validateFn: any = () => {};

  set checked(checked: boolean) {
    this._checked = this.value === this.initialValue;
    this.propagateChange(this.value);
  }
  get checked() {
    return this._checked;
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(checked) {
    this.checked = checked === this.value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TransactionService, EmitterService } from '@frontend/@shared/services';
import { Transactions } from '@frontend/@shared/types';
import { CoolLocalStorage } from 'angular2-cool-storage';

@Component({
  selector: 'yougiver-profile-developer-operations-list',
  template: require('./list.component.pug'),
  providers: [TransactionService]
})

export class ProfileOperationsListComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private paginatorName: string;
  private transactions: Transactions;

  constructor(private transactionService: TransactionService, private route: ActivatedRoute,
    private ls: CoolLocalStorage) { }

  ngOnInit(): void {
    this.paginatorName = 'TRANSACTIONS_LIST_PARAMS';
    this.getTransactions(this.ls.getItem('accountId'));

    this.subscriptions.push(EmitterService.get('ACCOUNT_ID').subscribe(id => {
      this.getTransactions(id);
    }));

    EmitterService.get('SET_BREADCRUMB').emit(['profile.navbar.balance.name', 'profile.navbar.balance.list_all']);
  }

  getTransactions(account_id: string): void {
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      let newQueryParams = JSON.parse(JSON.stringify(queryParams));
      Object.assign(newQueryParams, { account_id: account_id });
      this.subscriptions.push(this.transactionService.getTransactions({ params: newQueryParams })
        .subscribe((transactions: Transactions) => {
          this.transactions = transactions;
        }));
    }));
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

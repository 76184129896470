import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EmitterService, AccountService, AppConfig } from '@frontend/@shared/services';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  FormControl,
  AbstractControl,
  Validators
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { Account } from '@frontend/@shared/types';

import { YougiverValidators } from '@frontend/@shared/directives';

@Component({
  selector: 'yougiver-profile-developer-settings-payments',
  template: require('./payments.component.pug'),

})

export class ProfileDeveloperSettingsPaymentsComponent implements OnInit, OnDestroy {
  protected subscriptions: Array<Subscription> = [];
  protected invalidForm: boolean = false;
  protected loaderState: string = '';
  protected type: string;
  public account: Account;
  protected formProperties: Object = {
    paypall: [
      { name: 'email', validators: YougiverValidators.email, label: 'profile.developer.paypall_email' }
    ],
    custom: [
      {
        name: 'iban', validators: Validators.compose([YougiverValidators.whitespace,
                                                      Validators.minLength(1)]),
        label: 'profile.payment_data.iban'
      },
      {
        name: 'beneficiary_account_usd', validators: Validators.compose([Validators.required,
                                                                         YougiverValidators.whitespace,
                                                                         Validators.minLength(1)]),
        label: 'profile.payment_data.beneficiary_account_usd'
      },
      {
        name: 'beneficiary_name', validators: Validators.compose([Validators.required,
                                                                  YougiverValidators.whitespace,
                                                                  Validators.minLength(1)]),
        label: 'profile.payment_data.beneficiary_name'
      },
      {
        name: 'beneficiary_address', validators: Validators.compose([Validators.required,
                                                                     YougiverValidators.whitespace,
                                                                     Validators.minLength(1)]),
        label: 'profile.payment_data.beneficiary_address'
      },
      {
        name: 'beneficiary_bank_name', validators: Validators.compose([Validators.required,
                                                                       YougiverValidators.whitespace,
                                                                       Validators.minLength(1)]),
        label: 'profile.payment_data.beneficiary_bank_name'
      },
      {
        name: 'beneficiary_bank_swift', validators: Validators.compose([Validators.required,
                                                                        YougiverValidators.whitespace,
                                                                        YougiverValidators.swift_code]),
        label: 'profile.payment_data.beneficiary_bank_swift'
      },
      {
        name: 'beneficiary_bank_address', validators: Validators.compose([Validators.required,
                                                                          YougiverValidators.whitespace,
                                                                          Validators.minLength(1)]),
        label: 'profile.payment_data.beneficiary_bank_address'
      },
      {
        name: 'intermediary_bank', validators: Validators.compose([Validators.required,
                                                                   YougiverValidators.whitespace,
                                                                   Validators.minLength(1)]),
        label: 'profile.payment_data.intermediary_bank'
      },
      {
        name: 'correspondent_account', validators: Validators.compose([Validators.required,
                                                                       YougiverValidators.whitespace,
                                                                       Validators.minLength(1)]),
        label: 'profile.payment_data.correspondent_account'
      }
    ]
  };

  form: FormGroup;
  billing_information: AbstractControl;
  type_of: AbstractControl;
  email: AbstractControl;
  iban: AbstractControl;
  beneficiary_account_usd: AbstractControl;
  beneficiary_name: AbstractControl;
  beneficiary_address: AbstractControl;
  beneficiary_bank_name: AbstractControl;
  beneficiary_bank_swift: AbstractControl;
  beneficiary_bank_address: AbstractControl;
  intermediary_bank: AbstractControl;
  correspondent_account: AbstractControl;

  tax_docs: AbstractControl;
  incorp_docs: AbstractControl;

  constructor(protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected router: Router,
    protected config: AppConfig) {
  }

  ngOnInit() {
    EmitterService.get('SET_BREADCRUMB').emit(['shared.settings', 'profile.navbar.settings.payment_data']);
    this.form = this.fb.group({
      billing_information: this.fb.group({
        type_of: null
      })
    });
    this.billing_information = this.form.controls['billing_information'];
    this.type_of = (this.billing_information as any).controls['type_of'];

    this.account = this.accountServise.getAccount();

    if (this.account) {
      this.account['billing_information']['type_of'] === 0 ? this.formGenerator('paypall') : this.formGenerator('custom');
      this.type_of.patchValue(this.account.billing_information.type_of);
    }
    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.account['billing_information']['type_of'] === 0 ? this.formGenerator('paypall') : this.formGenerator('custom');
      this.type_of.patchValue(this.account.billing_information.type_of);
    }));

  }

  formGenerator(type: string) {
    let control = <FormGroup>this.billing_information;
    type === 'custom' ? this.destroyProperties('paypall') : this.destroyProperties('custom');
    for (let property of this.formProperties[type]) {
      control.addControl(property['name'], new FormControl(this.account.billing_information[property['name']], property['validators']));
      this[property['name']] = (this.billing_information as any).controls[property['name']];
    }
    this.type = type;
  }

  destroyProperties(type: string) {
    let control = <FormGroup>this.billing_information;
    for (let property of this.formProperties[type]) {
      delete control['controls'][property['name']];
      control.removeControl(property['name']);
    }
  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      this.loaderState = 'isLoading';
      this.invalidForm = false;
      this.accountServise.updateAccount(this.account.id, this.form.value).subscribe((res: any) => {
        if (!res.error) {
          if (res['resource']) {
            this.accountServise.setAccount(res['resource']);
          }
          this.loaderState = 'isComplete';
        } else {
          this.loaderState = '';
        }
        EmitterService.get('NOTIFICATION').emit(res);
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

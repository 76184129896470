import {Component, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Webhook } from '@frontend/@shared/types';
import {EmitterService, WebhooksService} from "@frontend/@shared/services";
import {Subscription} from "rxjs/Rx";

@Component({
  selector: 'yougiver-profile-webhooks-preview',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
  providers: [WebhooksService]
})

export class ProfileWebhooksPreviewComponent implements OnDestroy{
  @Input() webhook: Webhook;
  @Output() webhookId = new EventEmitter<number>();
  private isActive: boolean = false;
  protected loaderState: string = '';
  private subscriptions: Array<Subscription> = [];

  constructor(private webhooksService: WebhooksService){}

  public destroy(selected: Object) {
    if (selected['destroy'] === true) {
      this.webhookId.emit(selected['value']);
    }
    else {
      this.isActive = false;
    }
  }

  mockWebhook(){
    this.loaderState = 'isLoading';
    this.subscriptions.push(this.webhooksService.mock(this.webhook.id).subscribe(res => {
      this.loaderState = 'isComplete';
      EmitterService.get('NOTIFICATION').emit({info: 'profile.webhooks.mock_complete'});
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

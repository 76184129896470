import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { TaxonomiesService } from '@frontend/@shared/services';
import { Taxon, Taxonomy } from '@frontend/@shared/types';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductService } from '@frontend/@shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-product-categories',
  styles: [require('./styles/_import.sass')],
  providers: [TaxonomiesService, ProductService],
  template: require('./template.pug'),
})


export class ProfileProductCategoriesComponent implements OnInit, OnDestroy {

  private taxonomy: Taxonomy;
  private selectedCategory: number;

  private subscriptions: Array<Subscription> = [];

  private selectedTaxonomyId: number;

  @Output() onClicked = new EventEmitter<boolean>();

  constructor(private taxonomiesService: TaxonomiesService, private route: ActivatedRoute,
    private router: Router, private productService: ProductService) { }


  ngOnInit() {
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      if (queryParams['taxonomy']) {
        this.selectedTaxonomyId = queryParams['taxonomy'];
        this.subscriptions.push(this.taxonomiesService.getTaxonomy({ 'set': 'nested' }, this.selectedTaxonomyId)
          .subscribe(taxonomy => {
            this.taxonomy = taxonomy;
          }));
      }
      if (queryParams['taxon']) {
        this.selectedCategory = queryParams['taxon'];
      }
      else {
        this.selectedCategory = undefined;
      }
    }));
  }

  hideList() {
    this.onClicked.emit(true);
  }

  setTaxon(taxon: Taxon) {
    this.selectedCategory = +this.selectedCategory === taxon.id ? undefined : taxon.id;
    this.subscriptions.push(
      this.productService.setQueryParams({ taxon: this.selectedCategory })
    );
  }

  isChecked(category_id: number): boolean {
    if (this.selectedCategory) {
      return +this.selectedCategory === category_id ? true : false;
    }
    else {
      return false;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Component, forwardRef, Input, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'yougiver-sign-form-checkbox',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SignFormSharedCheckboxComponent), multi: true }
  ],
  styles: [require('./@styles/_import.sass')],
  template: require('./checkbox.component.pug')
})

export class SignFormSharedCheckboxComponent implements AfterContentInit {
  @Input() value: any;
  @Input() _checked: boolean;
  @Input() text: string;

  onTouchedCallback: () => void;
  propagateChange: any = () => {};
  validateFn: any = () => {};

  constructor(private _changeDetectionRef: ChangeDetectorRef) { }

  set checked(checked: boolean) {
    this._checked = checked || false;
    if (checked && this.value !== false && this.value !== true) {
      this.propagateChange(this.value);
    } else {
      this.propagateChange(checked);
    }
    if (checked === null) {
      this.writeValue(this.value);
    }
  }
  get checked() {
    return this._checked;
  }

  ngAfterContentInit() {
    if (this.value === this._checked) {
      this.propagateChange(this._checked);
    } else {
      setTimeout(() => {
        if (this._checked === false) {
          this.propagateChange(false);
        }
      }, 0);
    }

    this._changeDetectionRef.detectChanges();
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(checked) {
    if (this.value === true || this.value === false) {
      this.checked = checked;
    }
    if (this._checked === false) {
      this.checked = false;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }
}

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { EmitterService, WebhooksService } from '@frontend/@shared/services';
import { Observable, Subscription } from 'rxjs';
import { Webhook, EventsList } from '@frontend/@shared/types';
import { YougiverValidators } from '@frontend/@shared/directives';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
  Validators
} from '@angular/forms';

@Component({
  selector: 'yougiver-profile-webhooks-add',
  template: require('./add.component.pug'),
  providers: [WebhooksService]
})

export class ProfileWebhooksAddComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private invalidForm: boolean = false;
  protected loaderState: string = '';
  public events_list: Array<string> = [];
  public action: string;
  public slug: string;
  public notificationsTitlesMapping: any;

  form: FormGroup;
  events: AbstractControl;
  url: AbstractControl;
  webhook: Webhook;

  constructor(private _changeDetectionRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private webhooksService: WebhooksService,
    private ls: CoolLocalStorage,
    private router: ActivatedRoute,
    private r: Router) {
    this.notificationsTitlesMapping = {
      'gift_request_created': 'profile.webhooks.notifications_titles.gift_request_created',
      'gift_request_payment_started': 'profile.webhooks.notifications_titles.gift_request_payment_started',
      'gift_request_payment_waiting': 'profile.webhooks.notifications_titles.gift_request_payment_waiting',
      'gift_request_money_reserved': 'profile.webhooks.notifications_titles.gift_request_money_reserved',
      'gift_request_captured': 'profile.webhooks.notifications_titles.gift_request_captured',
      'gift_request_accepted': 'profile.webhooks.notifications_titles.gift_request_accepted',
      'gift_request_canceled': 'profile.webhooks.notifications_titles.gift_request_canceled',
      'gift_request_completed': 'profile.webhooks.notifications_titles.gift_request_completed',
      'gift_request_confirmed': 'profile.webhooks.notifications_titles.gift_request_confirmed',
      'gift_request_rejected': 'profile.webhooks.notifications_titles.gift_request_rejected',
      'gift_request_giver_confirmed': 'profile.webhooks.notifications_titles.gift_request_giver_confirmed',
      'order_created': 'profile.webhooks.notifications_titles.order_created',
      'order_pending': 'profile.webhooks.notifications_titles.order_pending',
      'order_accepted': 'profile.webhooks.notifications_titles.order_accepted',
      'order_shipped': 'profile.webhooks.notifications_titles.order_shipped',
      'order_delivered': 'profile.webhooks.notifications_titles.order_delivered',
      'order_cancelled': 'profile.webhooks.notifications_titles.order_cancelled'
    }
  }

  ngOnInit(): void {
    this.formSetup();
    this.subscriptions.push(this.router.params.subscribe(params => {
      if (params['slug']) {
        EmitterService.get('SET_BREADCRUMB').emit(['Webhooks', 'Edit']);
        this.action = 'update';
        this.slug = params['slug'];
        this.subscriptions.push(this.webhooksService.getWebhook(params['slug']).subscribe(webhook => {
          this.webhook = webhook;
          this.formUpdate(webhook);
        }));
      } else {
        EmitterService.get('SET_BREADCRUMB').emit(['Webhooks', 'New']);
        this.action = 'create';
      }
    }));
    this.webhooksService.getEventsList().subscribe((events: EventsList) => {
      this.events_list = events.events;
      this.events_list.forEach(event_title => {
        this.initEventControl(event_title);
      })
    });
  }

  initEventControl(eventControl: string) {
    let control = <FormArray>this.events;
    control.push(new FormControl(eventControl));
  }

  formSetup() {
    this.form = this.fb.group({
      events: this.fb.array([]),
      url: ['', Validators.compose([Validators.required])]
    });

    this.events = this.form.controls['events'];
    this.url = this.form.controls['url'];
  }

  formUpdate(webhook: Webhook) {
    this.url.patchValue(webhook.url);
  }

  onSubmit(value: any, createNew = false): void {
    if (this.form.valid) {
      this.invalidForm = false;
      this.loaderState = 'isLoading';
      if(this.action === 'create') {
        this.webhooksService.createWebhook(this.form.value).subscribe((res: any) => {
          if (!res.error) {
            this.loaderState = 'isComplete';
            if (res['resource']) {
              console.log(res['resource']);
            }
          } else {
            this.loaderState = '';
          }
          EmitterService.get('NOTIFICATION').emit(res);
        });
      } else {
        if (this.action === 'update') {
          this.subscriptions.push(this.webhooksService.update(this.slug, this.form.value).subscribe(res => {
            this.afterCreateUpdate(res, createNew);
          }));
        }
      }
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  afterCreateUpdate(res, createNew): void {
    if (res) {
      EmitterService.get('NOTIFICATION').emit(res);
      this.loaderState = 'isComplete';
    } else {
      this.loaderState = '';
    }
    if (!res.errors && createNew) {
      this.r.navigate(['/profile/webhooks/add']);
    } else if (!res.errors && !createNew && this.action === 'create') {
      this.r.navigate(['/profile/webhooks/edit', res.resource.slug]);
    }
  }

  getEventControl(event_value: string) {
    let finder = (this.events as any).controls.filter(control => control.value === event_value)[0];
    return (this.events as any).controls.indexOf(finder);
  }

  isCheckedEvent(title: string) {
    if (this.webhook && this.webhook.events && this.webhook.events.indexOf(title) < 0) {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

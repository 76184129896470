import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-profile-calendar-multiple',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileCalendarMultipleComponent {
}

import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';


import { ChartsModule } from 'ng2-charts/ng2-charts';
import { ProfileOperationsChartComponent } from '@frontend/profile/operations/chart/chart.component';
import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { ProfileWebhooksComponent } from '@frontend/profile/webhooks/webhooks.component';
import { ProfileWebhooksAddComponent } from '@frontend/profile/webhooks/add/add.component';
import { ProfileWebhooksListComponent } from '@frontend/profile/webhooks/list/list.component';
import { ProfileWebhooksEmptyComponent } from '@frontend/profile/webhooks/empty/empty.component';

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    ProfileSharedModule,
    DirectivesModule,
    ChartsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  declarations: [
    ProfileWebhooksComponent,
    ProfileWebhooksAddComponent,
    ProfileWebhooksListComponent,
    ProfileWebhooksEmptyComponent
  ],
  exports: [
    ProfileWebhooksComponent,
    ProfileWebhooksAddComponent,
    ProfileWebhooksListComponent,
    ProfileWebhooksEmptyComponent
  ]
})
export class ProfileWebhooksModule { }

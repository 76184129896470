import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '@frontend/@shared/services';
import { YougiverValidators } from '@frontend/@shared/directives';

import {
  FormBuilder,
  FormGroup,
  AbstractControl
} from '@angular/forms';

@Component({
  selector: 'yougiver-sign-restore-form',
  template: require('./restore.component.pug')
})
export class SignRestoreFormComponent implements OnInit {
  private restored: boolean = false;
  private errorMessage: string|boolean = false;

  form: FormGroup;
  email: AbstractControl;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', YougiverValidators.email]
    });

    this.email = this.form.controls['email'];
  }

  onSubmit(value: any): void {
    this.errorMessage = false;
    if (this.form.valid) {
      this.userService.requestNewPassword(this.form.value).subscribe((result) => {
        if (!result.errors) {
          this.restored = true;
        } else {
          this.errorMessage = `Email ${result.errors.email}`;
        }
      });
    } else {
      console.log('form invalid!', this.form.controls);
    }
  }
}

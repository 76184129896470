import { Component, Input } from '@angular/core';
import { Transaction } from '@frontend/@shared/types';

@Component({
  selector: 'yougiver-profile-operations-preview',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileOperationsPreviewComponent {
  @Input() transaction: Transaction;

  public sum(income, expense): number {
    return parseFloat(income) - parseFloat(expense);
  }
}

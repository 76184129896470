import { Component, Input } from '@angular/core';
import { EmitterService } from '@frontend/@shared/services';

@Component({
  selector: 'yougiver-profile-description',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileDescriptionComponent {
  @Input() label: any = 'Default label';

  openAcceptancePopup() {
    let popup = {title: 'profile.payment_data.public_offer.name'};
    EmitterService.get('POPUP').emit(popup);
  }
}

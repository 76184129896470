import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'yougiver-profile-developer-share',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileDeveloperShareComponent {
  @Input() share: any;
}

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { EmitterService, ProductService } from '@frontend/@shared/services';
import { Account } from '@frontend/@shared/types';
import { Subscription } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';

@Component({
  selector: 'yougiver-profile-header',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
  providers: [ProductService]
})

export class ProfileHeaderComponent implements OnInit, OnDestroy {
  private isActiveUserNav: boolean = false;
  private isActiveCategories: boolean = false;
  private text: Array<string> = [];
  private timer_id: any = 0;
  private subscriptions: Array<Subscription> = [];
  private currentLocale: string;
  public searchQuery: string;
  @Output() mobileMenu = new EventEmitter<boolean>();
  @Input() account: Account;

  constructor(private router: Router, private route: ActivatedRoute,
    private productService: ProductService, private elementRef: ElementRef,
    private ls: CoolLocalStorage) {
      this.router.events.subscribe(event => {
        if (this.isActiveUserNav === true && event instanceof NavigationEnd) {
          this.isActiveUserNav = !this.isActiveUserNav;
        }
      });
    }

  @HostListener('document:click', ['$event.target']) onClick(targetElement) {
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.isActiveUserNav = false;
    }
  }

  ngOnInit() {
    this.currentLocale = this.ls.getItem('locale');
    let SET_BREADCRUMB = EmitterService.get('SET_BREADCRUMB');
    SET_BREADCRUMB.subscribe(breadcrumb => {
      this.setBreadcrumb(breadcrumb);
    });
    this.subscriptions.push(EmitterService.get('LOCALE').subscribe(locale => {
      this.currentLocale = locale;
    }));
  }

  switchAccount() {
    EmitterService.get('SWITCH_ACCOUNT').emit(true);
  }

  selectLanguage() {
    EmitterService.get('SWITCH_LANGUAGE').emit(true);
  }

  setBreadcrumb(breadcrumb: Array<string>) {
    this.text = [];
    breadcrumb.forEach(txt => {
      this.text.push(txt);
    });
  }

  onClicked(clicked: boolean) {
    if (clicked) {
      this.isActiveCategories = !this.isActiveCategories;
    }
  }

  clickMobileMenu() {
    this.mobileMenu.emit(true);
  }

  isProductPage(): boolean {
    return this.router.url.indexOf('profile/products?taxonomy') > 0;
  }

  Styped(query: string): void {
    clearTimeout(this.timer_id);
    this.timer_id = setTimeout(() => {
      this.subscriptions.push(
        this.productService.setQueryParams({ fulltext: query })
      );
    }, 1000);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-sign-form-button',
  styles: [require('./@styles/_import.sass')],
  template: require('./button.component.pug')
})

export class SignFormSharedButtonComponent {
  @Input() text: string;
  @Input() mod: string;
}

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { EmitterService, ProductService, TaxonomiesService } from '@frontend/@shared/services';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Products, Taxon } from '@frontend/@shared/types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-products-list',
  providers: [ProductService, TaxonomiesService],
  template: require('./list.component.pug')
})
export class ProfileProductsListComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private paginatorName: string;
  private products: Products;

  constructor(private productService: ProductService, private taxonomiesService: TaxonomiesService,
    private route: ActivatedRoute, private router: Router,
    private ls: CoolLocalStorage) { }

  getProducts(params = undefined): void {
    this.subscriptions.push(this.productService.getProducts(params).subscribe((products: Products) => {
      this.products = products;
    }));
  }

  initializeProducts(account_id: string) {
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      let newQueryParams = JSON.parse(JSON.stringify(queryParams));
      Object.assign(newQueryParams, { account_id: account_id });
      this.getProducts(newQueryParams);
      if (queryParams['taxonomy']) {
        this.taxonomiesService.getTaxonomy({ 'set': 'nested' }, queryParams['taxonomy']).
          subscribe(taxonomy => {
            if (queryParams['taxon']) {
              EmitterService.get('SET_BREADCRUMB').emit(['profile.navbar.products', 'profile.products.list',
                taxonomy['name'], this.findTaxon(taxonomy['taxons'], queryParams['taxon'])]);
            }
            else {
              EmitterService.get('SET_BREADCRUMB').emit(['profile.navbar.products', 'profile.products.list',
                taxonomy['name']]);
            }
          });
      }
      else {
        EmitterService.get('SET_BREADCRUMB').emit(['profile.navbar.products', 'profile.products.list']);
      }
    }));
  }

  ngOnInit(): void {
    this.paginatorName = 'PRODUCT_LIST_PARAMS';
    let accountId = this.ls.getItem('accountId');
    if (!accountId) { accountId = this.ls.getItem('latestAccount'); }
    this.initializeProducts(accountId);
    this.subscriptions.push(EmitterService.get('ACCOUNT_ID').subscribe(id => {
      this.initializeProducts(id);
    }));
  }

  public destroyProduct(productId: number) {
    this.subscriptions.push(this.productService.destroy(productId).subscribe(res => {
      this.products['products'] = this.products['products'].filter(function(obj) {
        return obj.id !== productId;
      });
    },
      err => { console.log(err); }));
  }

  private findTaxon(taxons: Array<Taxon>, taxon_id: number): string {
    let selected;
    for (let taxon of taxons) {
      selected = taxon['taxons'].find(category => category['id'] === +taxon_id);
      if (selected) {
        return selected['name'];
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EmitterService } from '@frontend/@shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-keys',
  template: `<router-outlet></router-outlet>`,
})

export class ProfileKeysComponent{
  constructor(private router: Router) {}
}

import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';

import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { ProfileComponent } from '@frontend/profile/profile.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { ChartsModule } from 'ng2-charts';
import { CoolStorageModule } from 'angular2-cool-storage';
import { ProfileActivationModule } from '@frontend/profile/activation/activation.module';
import { ProfileBalanceModule } from '@frontend/profile/balance/balance.module';
import { ProfileOperationsModule } from '@frontend/profile/operations/operations.module';
import { ProfileDeveloperModule } from '@frontend/profile/developer/developer.module';
import { ProfileOrdersModule } from '@frontend/profile/orders/orders.module';
import { ProfileProductModule } from '@frontend/profile/products/product.module';
import { ProfileSettingsModule } from '@frontend/profile/settings/settings.module';
import { ProfileKeysModule } from '@frontend/profile/keys/keys.module';
import { ProfileWebhooksModule } from '@frontend/profile/webhooks/webhooks.module';
import { ProfileWebhookLogsModule } from "@frontend/profile/webhook-logs/webhook-logs.module";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    ProfileSharedModule,
    DirectivesModule,
    ChartsModule,
    CoolStorageModule,
    ProfileActivationModule,
    ProfileBalanceModule,
    ProfileOperationsModule,
    ProfileDeveloperModule,
    ProfileOrdersModule,
    ProfileProductModule,
    ProfileSettingsModule,
    ProfileKeysModule,
    ProfileWebhooksModule,
    ProfileWebhookLogsModule
  ],
  declarations: [
    ProfileComponent
  ],
  exports: [
    ProfileComponent
  ],
})
export class ProfileModule { }

import { ProfileWebhooksComponent } from '@frontend/profile/webhooks/webhooks.component';
import { ProfileWebhooksAddComponent } from '@frontend/profile/webhooks/add/add.component';
import { ProfileWebhooksListComponent } from '@frontend/profile/webhooks/list/list.component';

export const profileWebhooksRoutes = {
  path: 'webhooks',
  component: ProfileWebhooksComponent,
  children: [
    {
      path: 'add',
      component: ProfileWebhooksAddComponent
    },
    {
      path: 'edit/:slug',
      component: ProfileWebhooksAddComponent,
    },
    {
      path: '',
      component: ProfileWebhooksListComponent
    }
  ]
};

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'page-header',
  styles: [require('./styles/_import.sass')],
  template: require('./header.component.html')
})

export class PageHeaderComponent {
  private landingLink: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.landingLink = '//www.yougiver.me';
  }
}

import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MonitoringService {
  private outstandingRequestsNumber: number = 0;
  private outstandingRequestsNumberObserver: any;
  private outstandingRequestsNumberObservable: any;

  constructor() {
    this.outstandingRequestsNumberObservable = new Observable(observer => {
      this.outstandingRequestsNumberObserver = observer;
    }).share();
  }

  getOutstandingRequests() {
    return this.outstandingRequestsNumber;
  }

  incrementOutstandingRequests() {
    window['angularReady'] = false;
    this.outstandingRequestsNumber++;
  }

  decrementOutstandingRequests() {
    this.outstandingRequestsNumber--;
    if (this.outstandingRequestsNumber === 0) {
      this.outstandingRequestsNumberObserver.next();
    }
  }

  notifyWhenNoOutstandingRequests(callback) {
    this.outstandingRequestsNumberObservable.subscribe(callback);
  }
}

import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'yougiver-profile-actions',
  encapsulation: ViewEncapsulation.Emulated,
  styles: [require('./@styles/_import.sass')],
  template: `
    <ng-content select=".profile-actions-box"></ng-content>
    <ng-content select=".profile-actions-box"></ng-content>
  `
})
export class ProfileActionsComponent {
  @Input() text: any = 'Default label';
}

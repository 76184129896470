import { Component, Input, ViewEncapsulation,  OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AppConfig, TranslationsService, EmitterService } from '@frontend/@shared/services';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Subscription } from "rxjs/Rx";

@Component({
  selector: 'yougiver-footer-select',
  encapsulation: ViewEncapsulation.None,
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class FooterSelectComponent implements OnInit, OnDestroy, AfterViewInit   {

    private current_locale: string;
    private available_locales: Array<string>;
    private itemList: Array<Object> = [];
    private localeBeforeSwitch: string;
    private subscriptions: Array<Subscription> = [];
    private isApply: boolean;

    constructor(private config: AppConfig, private ls: CoolLocalStorage, private translationsService: TranslationsService) {
    }

    ngOnInit() {
      this.isApply = false;
      this.localeBeforeSwitch = this.ls.getItem('locale');
      this.subscriptions.push(EmitterService.get('SWITCH_LANGUAGE').subscribe(status => {
        if (status === false && this.isApply === false) {
          this.setupLocale(this.localeBeforeSwitch);
        }
      }));
    }

    ngAfterViewInit() {
      if (this.config['config'].hasOwnProperty('error')) {
        console.log('Config has an error. Maybe you need to restart it backend server');
      }
      this.available_locales = this.config.getConfig('available_locales');
      this.current_locale = this.ls.getItem('locale');
      this.available_locales.forEach(locale => this.itemList.push({locale: locale, active: (this.current_locale === locale)}));
    }

    ngOnDestroy() {
      let finder = this.itemList.find(loc => loc['active'] === true);
      if (finder['locale'] !== this.ls.getItem('locale')) {
        this.translationsService.getTranslations({locale: this.current_locale}, false);
      }
      this.available_locales = [];
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
    
    onSelect(locale: string) {
      const newLocale = this.itemList.find(item => item['locale'] === locale)
      this.changeLanguage(newLocale)
    }

    changeLanguage(item: any) {
      this.itemList.forEach(locale => locale['active'] = false);
      item.active = true;
      this.setupLocale(item.locale);
    }

    setupLocale(locale: string) {
      this.ls.setItem('locale', locale);
      EmitterService.get('LOCALE').emit(locale);
      this.translationsService.getTranslations({locale: locale}, false);
    }

    applyLanguage() {
      this.isApply = true;
      EmitterService.get('SWITCH_LANGUAGE').emit(false);
    }
}

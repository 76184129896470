import {Component, Input} from '@angular/core';

@Component({
  selector: 'yougiver-profile-order-preview-rating',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})


export class ProfileOrderPreviewRatingComponent {


}

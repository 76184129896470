import { Component, Input, Output, AfterContentInit, OnChanges, OnInit, Injector, EventEmitter, NgZone, forwardRef } from '@angular/core';
import { FormControl, NgControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

declare const google;

@Component({
  selector: 'google-form-input',
  styles: [require('./styles/_import.sass')],
  template: require('./input.component.pug'),
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GoogleFormInputComponent), multi: true }
  ],
})

export class GoogleFormInputComponent implements OnInit, ControlValueAccessor, AfterContentInit {
  // ControlValueAccessor
  @Input() _value: string = '';
  private control: FormControl = new FormControl('');
  private disabled: boolean;

  set value(value: string) {
    this.updateValue(value)
  }

  get value() {
    return this.control.value;
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: string) {
    this.value = outsideValue;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  updateValue(insideValue: string) {
    this._value = insideValue
    this.control.patchValue(insideValue)
    this.onChange(insideValue);
    this.onTouched();
  }

  // UI
  @Input() placeholder: string;
  @Input() label: string;
  @Input() errors: Object;


  // Google Autocomplete
  // @ts-ignore
  private autocomplete: google.maps.places.Autocomplete;
  @Input() inputId = 'google-search-field';
  @Input() componentRestrictions: any = null;
  @Input() fields: string[] = [];
  @Input() types: string[] = [];
  @Output() placeChanged = new EventEmitter<any>();

  constructor(private _injector: Injector, private zone: NgZone) {
    this.initAutocomplete = this.initAutocomplete.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);

    this.control.valueChanges.subscribe(value => {
      if (value === this._value) {
        return
      }

      this.onChange(value)
    })
  }

  ngOnInit(): void {
    this.loadGoogleAutoComplete()
  }
  
  ngOnChanges(changes: any) { 
    if (changes.componentRestrictions) {
      this.setComponentRestrictions();
    }
  }
  
  ngAfterContentInit() {
    this.value = this._value
  }

  private loadGoogleAutoComplete() {
    const url = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAKAzXz2CmPq4FsOR2_NGiOrMUMry0eRXY&libraries=places&v=weekly';
    this.loadScript(url).then(() => this.initAutocomplete());
  }

  private loadScript(url) {
    return new Promise((resolve, reject) => {
      const anyWindow: any = window
      const id = 'google-maps-autocomplete-script';

      let script = document.getElementById(id) as HTMLScriptElement
      
      if (Boolean(script) && !anyWindow.google) {
        script.addEventListener('load', resolve)
        script.addEventListener('error', reject)
        return
      }

      if (Boolean(script)) {
        resolve(true)
        return
      }

      script = document.createElement('script');
      script.id = id;
      script.type = 'text/javascript';
      script.src = url;
      script.text = '';
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      document.getElementsByTagName('head')[0].appendChild(script);
    })
  }

  
  initAutocomplete(): void {
    const input = document.querySelector(`#${this.inputId}`) as HTMLInputElement;
    const settings = {
      fields: this.fields,
      types: this.types,
    }

    if (this.componentRestrictions) {
      settings['componentRestrictions'] = this.componentRestrictions
    }

    this.autocomplete = new google.maps.places.Autocomplete(input, settings);
    
    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
  }

  onPlaceChanged() {
    this.zone.run(() => {
      const place = this.autocomplete.getPlace();
      this.placeChanged.emit(place)
    })
  }

  setComponentRestrictions() {
    if (!this.autocomplete) {
      return
    }

    this.autocomplete.setComponentRestrictions(this.componentRestrictions)
  }
}

import { Component, OnInit } from '@angular/core';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router } from '@angular/router';

@Component({
    selector: 'yougiver-sign-proxy',
    template: require('./sign-proxy.template.pug'),
})
export class SignProxyComponent implements OnInit {
  constructor(
    private router: Router,
    private ls: CoolLocalStorage
  ) {}

  ngOnInit() {
    const registered = this.ls.getItem('registered')
    const role = this.ls.getItem('signUpAs')

    if (registered && role) {
        this.router.navigate(['sign', 'in', role]);
    } else {
        this.router.navigate(['sign'])
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { SignFormSharedModule } from '@frontend/sign/@shared/sign-form/@shared/shared.module';
import { SignInFormComponent } from '@frontend/sign/@shared/sign-form/in/in.component';
import { SignSetupPasswordFormComponent } from '@frontend/sign/@shared/sign-form/password/password.component';
import { SignRestoreFormComponent } from '@frontend/sign/@shared/sign-form/restore/restore.component';
import { SignUpPlainFormComponent } from '@frontend/sign/@shared/sign-form/up-plain/up-plain.component';
import { SignUpRequestFormComponent } from '@frontend/sign/@shared/sign-form/up-request/up-request.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignFormComponent } from '@frontend/sign/@shared/sign-form/sign-form.component';
import { RegisterAsComponent } from '@frontend/sign/@shared/sign-form/register-as/register-as.component';
import { SignInfoComponent } from '@frontend/sign/@shared/sign-form/sign-info/sign-info.component';
import { SignLayoutComponent } from '@frontend/sign/@shared/sign-form/sign-layout/sign-layout.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    SignFormSharedModule
  ],
  declarations: [
    SignFormComponent,
    SignInFormComponent,
    SignSetupPasswordFormComponent,
    SignRestoreFormComponent,
    SignUpPlainFormComponent,
    SignUpRequestFormComponent,
    RegisterAsComponent,
    SignInfoComponent,
    SignLayoutComponent
  ],
  exports: [
    SignFormSharedModule,
    SignFormComponent,
    SignInFormComponent,
    SignSetupPasswordFormComponent,
    SignRestoreFormComponent,
    SignUpPlainFormComponent,
    SignUpRequestFormComponent,
    RegisterAsComponent,
    SignInfoComponent,
    SignLayoutComponent
  ]
})
export class SignFormModule {}

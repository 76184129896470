import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-profile-content-info',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileContentInfoComponent {
  @Input() text: string = '';
  @Input() icon: string;
}

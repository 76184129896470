import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AppConfig, EmitterService, ProductService, TaxonomiesService } from '@frontend/@shared/services';
import { Observable, Subscription } from 'rxjs';
import { YougiverValidators } from '@frontend/@shared/directives';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
  Validators
} from '@angular/forms';

@Component({
  selector: 'yougiver-profile-products-add',
  template: require('./add.component.pug'),
  styles: [require('./styles/_import.sass')],
  providers: [ProductService]
})
export class ProfileProductsAddComponent implements OnInit, OnDestroy {
  private invalidForm: boolean = false;
  private subscriptions: Array<Subscription> = [];
  private initParams: Object = {};
  private product: Object = {};
  private productTypeOf: string;
  private isActive: boolean = false;
  private action: string;
  private vat: number;
  private priceMode: string;
  private slug: string;
  public typeOfProductsTranslates: Object = {
    create: {
      product: 'profile.products.edit.add_product',
      certificate: 'profile.certificates.edit.add_certificate'
    },
    update: {
      product: 'profile.products.edit.edit_product',
      certificate: 'profile.certificates.edit.edit_certificate'
    },
    product: 'profile.products.type_of',
    certificate: 'profile.certificates.type_of'
  };
  public headersForUploader: Array<any> = [];

  form: FormGroup;
  in_stock: AbstractControl;
  digital: AbstractControl;
  name: AbstractControl;
  product_prices_attributes: FormArray;
  from_prototype: AbstractControl;
  taxon_ids: AbstractControl;
  properties: AbstractControl;
  description: AbstractControl;
  videos_attributes: FormArray;

  constructor(private _changeDetectionRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private productService: ProductService,
    private taxonomiesService: TaxonomiesService,
    private ls: CoolLocalStorage,
    private router: ActivatedRoute,
    private r: Router,
    protected config: AppConfig) {

  }

  ngOnInit(): void {
    this.productService.headers.forEach((value, name) => {
      if (name.toLowerCase() !== 'content-type') {
        this.headersForUploader.push({ name: name, value: value[0] });
      }
    });

    this.initParams = {};

    this.subscriptions.push(this.router.params.subscribe(params => {
      this.productTypeOf = (this.router.data as any).value.type_of;
      if (params['slug']) {
        this.subscriptions.push(this.productService.show(params['slug'], {}).subscribe(product => {
          this.action = 'update';
          this.slug = params['slug'];
          this.product = product;
          this.vat = +this.product['vat'];
          this.form = this.productForm();
          this.formControls();
          let getTaxonomies = this.taxonomiesService.getTaxonomies();
          if (getTaxonomies instanceof Observable) {
            this.subscriptions.push(getTaxonomies.subscribe(taxonomies => {
              this.fireEditBreadcrumbs(taxonomies.taxonomies);
            }));
          } else {
            this.fireEditBreadcrumbs(getTaxonomies['taxonomies']);
          }
        }));
      } else {
        EmitterService.get('SET_BREADCRUMB').emit([this.typeOfProductsTranslates['create'][this.productTypeOf]]);
        this.subscriptions.push(this.router.queryParams.subscribe(queryParams => {
          this.initParams['taxonomy_id'] = queryParams['taxonomy'];
        }));

        this.action = 'create';
        this.buildProduct();
        this.form = this.productForm();
        this.formControls();
      }
    }));

    this.fileUploaderEvents();

    this._changeDetectionRef.detectChanges();
  }

  fileUploaderEvents() {
    this.subscriptions.push(EmitterService.get('FILE_UPLOADER_EVENT').subscribe(res => {
      let response = JSON.parse(res.response);
      if (res.status >= 400) {
        EmitterService.get('NOTIFICATION').emit(response);
      } else if (res.status >= 200 && res.status < 300) {
        if (res.item.url.indexOf('assets') > 0) {
          if (response.type === 'Image' && this.product['images'].filter(img => +img.id === +response.id).length < 1) {
            this.product['images'].push(response);
          }
        } else if (res.item.url.indexOf('import_by_file') > 0) {
          EmitterService.get('NOTIFICATION').emit(response['message']);
          this.product['codes'] = response;
        }
      }
    }));

    this.subscriptions.push(EmitterService.get('DELETE_UPLOADED_FILE').subscribe(file => {
      if (file.type === 'Image') {
        this.subscriptions.push(this.productService.destroyAsset(this.slug, file).subscribe(res => {
          if (!res.errors) {
            let deleted_image = this.product['images'].find(img => +img['id'] === +file.id);
            if (deleted_image) {
              let index = this.product['images'].indexOf(deleted_image);
              delete this.product['images'][index];
              this.product['images'] = this.product['images'].filter(Object);
              // ngOnChanges dont work with splice, and I don't know why
              // this.product['images'].splice(index, 1);
            }
          }
        }));
      } else if (file.type === 'CertificateDocument') {
        if (!file.deleted) {
          this.subscriptions.push(this.productService.destroyAsset(this.slug, file).subscribe());
        }
      }
    }));
  }

  productForm(): FormGroup {
    return this.fb.group({
      in_stock: this.product['in_stock'],
      digital: this.product['digital'] || false,
      name: [this.product ? this.product['name'] : '',
        Validators.compose([Validators.required, YougiverValidators.whitespace, YougiverValidators.latinic, Validators.minLength(3), Validators.maxLength(100)])],
      from_prototype: null,
      taxon_ids: this.fb.array([new FormControl(0, Validators.required)]),
      properties: this.fb.array([]),
      description: [this.product ? this.product['description'] : ''],
      product_prices_attributes: this.fb.array(this.init_prices_input()),
      videos_attributes: this.fb.array(this.init_videos_input())
    });
  }

  init_prices_input(): FormGroup[] {
    if (this.product['product_prices'] && this.product['product_prices'].length > 0) {
      return this.product['product_prices'].map((attribute) => {
        return this.fb.group({
          id: attribute.id,
          product_id: this.product['id'],
          currency_code: attribute.currency_code,
          price: new FormControl(attribute ? attribute['price'] : '',
              Validators.compose([Validators.required, YougiverValidators.whitespace, YougiverValidators.decimal])),
        })
      })
    } else {
      return [this.init_empty_price()];
    }
  }

  init_empty_price(currency?): FormGroup {
    return this.fb.group({
      product_id: this.product['id'],
      currency_code: currency || 'USD',
      price: new FormControl('', Validators.compose([Validators.required, YougiverValidators.whitespace, YougiverValidators.decimal]))
    })
  }

  get_currencies() {
    return this.config.getConfig('available_currencies').filter(item => {
      return !this.product_prices_attributes.controls.some(control =>
        (control.value.currency_code === item) && !control.value._destroy
      );
    })
  }

  add_empty_price() {
    const currency = this.get_currencies()[0];

    this.product_prices_attributes.push(this.init_empty_price(currency));
  }

  remove_price(index: number) {
    let ctrl = <FormGroup>this.product_prices_attributes.controls[index];

    if (ctrl.controls.hasOwnProperty('id')) {
      ctrl.addControl('_destroy', new FormControl(true));
    } else this.product_prices_attributes.removeAt(index);
  }

  is_last_visible_price(index): boolean {
    for (let i = index + 1; i < this.product_prices_attributes.length; i++) {
      if (!this.product_prices_attributes.at(i).value._destroy) {
        return false;
      }
    }
    return true;
  }

  init_videos_input(): FormGroup[] {
    if (this.product['videos'] && this.product['videos'].length > 0) {
      return this.product['videos'].map((video) => {
        return this.fb.group({
          id: video.id,
          product_id: this.product['id'],
          url: [video.url || '', YougiverValidators.videoLink],
          position: video.position
        })
      })
    } else {
      return [
        this.fb.group({
          product_id: this.product['id'],
          url: ['', YougiverValidators.videoLink],
          position: 1
        })
      ]
    }
  }

  formControls() {
    this.in_stock = this.form.controls['in_stock'];
    this.digital = this.form.controls['digital'];
    this.name = this.form.controls['name'];
    this.from_prototype = this.form.controls['from_prototype'];
    this.taxon_ids = this.form.controls['taxon_ids'];
    this.properties = this.form.controls['properties'];
    this.description = this.form.controls['description'];
    this.videos_attributes = this.form.controls['videos_attributes'] as FormArray;
    this.product_prices_attributes = this.form.controls['product_prices_attributes'] as FormArray;
  }

  buildProduct(): void {
    this.productService.build(this.initParams).subscribe(builder => {
      let images = builder.images;
      this.product = {
        ...builder, 
        properties: builder.properties ? builder.properties.map(p => {
        return {
          ...p,
          videoLink: p.name === 'Link',
        }
      }): builder.properties};

      this.product['images'] = images;
      this.vat = +this.product['vat'];
    });
  }

  prototypeSelected(selectItem: any): void {
    this.ngOnDestroy();
    this.fileUploaderEvents();
    this.initParams['prototype_id'] = selectItem.id;
    (this.properties as any).controls = [];
    this.buildProduct();
  }

  initPropertyControl(property_control: number) {
    let control = <FormArray>this.properties;
    let productProperty = this.product[this.action === 'update' ? 'product_properties' : 'properties'][property_control];
    if (control.controls[property_control] === undefined) {
      let ppValue = null;
      if (productProperty && productProperty['value'] && productProperty['value'][productProperty['property_value_type']]) {
        ppValue = productProperty['value'][productProperty['property_value_type']].toString();
      } else if (productProperty &&  productProperty['value'] && productProperty['value'][productProperty['value_type']] && productProperty['fixed']) {
        ppValue = productProperty['value'][productProperty['value_type']].toString();
      }
      control.push(new FormGroup({
        id: new FormControl,
        value: new FormControl(ppValue, Validators.compose(
          [
            productProperty['required'] ? Validators.required : null, 
            productProperty['videoLink'] ? YougiverValidators.videoLink : null,
            YougiverValidators.whitespace, 
            Validators.minLength(1)
          ])),
        fixed: new FormControl(productProperty ? productProperty['property_fixed'] : this.product['properties'][property_control]['fixed'])
      }));
    }
    return property_control;
  }

  assetsPath(slug: string): string {
    if (slug) {
      return `/api/v1/products/${slug}/assets`;
    } else {
      return `/api/v1/products/assets`;
    }
  }

  onSubmit(createNew = false): void {
    if (this.form.valid) {
      this.invalidForm = false;
      if (this.action === 'create') {
        let image_ids = this.product['images'].map(image => image.id);
        if (image_ids.length === 0) {
          EmitterService.get('NOTIFICATION').emit({ error: 'shared.validation.product_image_required' });
        } else {
          this.subscriptions.push(this.productService.create(this.form.value, image_ids).subscribe(res => {
            this.afterCreateUpdate(res, createNew);
          }));
        }
      } else {
        if (this.action === 'update') {
          this.subscriptions.push(this.productService.update(this.slug, this.form.value).subscribe(res => {
            this.afterCreateUpdate(res, createNew);
          }));
        }
      }
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  afterCreateUpdate(res, createNew): void {
    if (res) {
      EmitterService.get('NOTIFICATION').emit(res);
    }
    if (!res.errors && createNew) {
      this.r.navigate(['/profile/products/add']);
    } else if (!res.errors && !createNew && this.action === 'create') {
      this.r.navigate(['/profile/products/edit', res.resource.slug]);
    }
  }

  goBackOrDefault(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    let params = this.ls.getItem('lastProductParams');
    if (params && params.length > 0) {
      params = JSON.parse(params);
      delete params['taxonomies'];
      navigationExtras['queryParams'] = params as Object;
    }
    this.r.navigate(['/profile/products'], navigationExtras);
  }

  destroy(selected: Object) {
    if (selected['destroy'] === true) {
      this.subscriptions.push(this.productService.destroy(selected['value']).subscribe(res => {
        this.goBackOrDefault();
      },
        err => { console.log(err); }));
    }
    else {
      this.isActive = false;
    }
  }

  fireEditBreadcrumbs(finder: any): void {
    let find = finder.find(taxonomy => +taxonomy['id'] === +this.product['taxons'][0]['taxonomy_id']);
    if (find) {
      this.productTypeOf = find['type_of'];
    } else {
      this.productTypeOf = 'product';
    }
    EmitterService.get('SET_BREADCRUMB').emit([this.typeOfProductsTranslates['update'][this.productTypeOf]]);
  }

  calculateVat(price: number, mode: string): number {
    if (mode === 'with_vat') {
      return +((+price / 100 * (100 + this.vat)).toFixed(2));
    } else if (mode === 'without_vat') {
      return +((+price / (100 + this.vat) * 100).toFixed(2));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

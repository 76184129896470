import { Component, OnInit, Input } from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators
} from '@angular/forms';

import { YougiverValidators } from '@frontend/@shared/directives';
import { UserService, AppConfig } from '@frontend/@shared/services';
import { CoolLocalStorage } from 'angular2-cool-storage';

@Component({
  selector: 'yougiver-sign-up-plain-form',
  template: require('./up-plain.component.pug')
})

export class SignUpPlainFormComponent implements OnInit {
  @Input() title: string;
  @Input() signInLink: string;
  @Input() userRole: string = 'seller';
  private tabs: any;
  private registered: boolean = false;
  private invalidEmail: string;
  private wrongEmail: boolean = false;

  private passwordShown: boolean = false;
  private passwordInputType: string = 'password';
  private passwordIcon: string = 'eye'

  private passwordConfirmationShown: boolean = false;
  private passwordConfirmationInputType: string = 'password';
  private passwordConfirmationIcon: string = 'eye'

  private termsUrl: string = '';

  public resultError: Object;
  public invalidForm: boolean = false;
  form: FormGroup;
  email: AbstractControl;
  passwords: AbstractControl;
  password: AbstractControl;
  password_confirmation: AbstractControl;
  account_type: AbstractControl;

  constructor(
    private fb: FormBuilder, 
    private userService: UserService, 
    private ls: CoolLocalStorage,
    private config: AppConfig
  ) {
    this.termsUrl = this.config.getConfig('terms_url');

    this.tabs = [
      {
        mod: '',
        text: 'shared.roles.seller',
        name: 'seller',
        active: true
      },
      {
        mod: '',
        text: 'shared.roles.developer',
        name: 'developer',
        active: false
      }
    ];
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', YougiverValidators.email],
      passwords: this.fb.group({
        password: ['', Validators.compose([Validators.required,
        YougiverValidators.whitespace,
        YougiverValidators.password,
        Validators.minLength(8)])],
        password_confirmation: ['', Validators.compose([Validators.required,
        YougiverValidators.whitespace,
        YougiverValidators.password,
        Validators.minLength(8)])]
      }, { validator: YougiverValidators.areEqual }),
      account_type: 'seller'
    });

    this.email = this.form.controls['email'];
    this.passwords = this.form.controls['passwords'];
    this.password = (this.form.controls['passwords'] as any).controls['password'];
    this.password_confirmation = (this.form.controls['passwords'] as any).controls['password_confirmation'];
    this.account_type = this.form.controls['account_type'];
  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      this.userService.signUp(
        this.userRole,
        value.email,
        value.passwords.password,
        value.passwords.password_confirmation
      ).subscribe((result) => {
        if (result.email) {
          // this.router.navigate(['']);
          this.registered = true;
          this.invalidForm = false;
          this.ls.removeItem('latestAccount');
          this.ls.setItem('registered', 'true')
        }
        else if (result.errors) {
          this.wrongEmail = true;
          this.invalidEmail = this.email.value;
          this.resultError = result.errors;
          this.invalidForm = true;
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  public selectedTab(event) {
    // Hack to ignore account_type as AbstractControl, because of TS2339
    (this.account_type as any).setValue(event.value);
  }

  public emailErrors(): Object {
    if (this.invalidEmail) {
      this.wrongEmail = (this.invalidEmail === this.email.value);
    }
    if (this.resultError) {
      let res = this.resultError;
      this.resultError = null;
      return res;
    }
    else if (this.email.errors) {
      return this.email.errors;
    }
    else {
      return {};
    }
  }

  public onTogglePassword() {
    this.passwordShown = !this.passwordShown

    if (this.passwordShown) {
      this.passwordInputType = 'text'
      this.passwordIcon = 'eye_slash'
    } else {
      this.passwordInputType = 'password'
      this.passwordIcon = 'eye'
    }
  }

  public onTogglePasswordConfirmation() {
    this.passwordConfirmationShown = !this.passwordConfirmationShown

    if (this.passwordConfirmationShown) {
      this.passwordConfirmationInputType = 'text'
      this.passwordConfirmationIcon = 'eye_slash'
    } else {
      this.passwordConfirmationInputType = 'password'
      this.passwordConfirmationIcon = 'eye'
    }
  }
}

import { Component, Input, ElementRef, ViewChild, OnInit, OnChanges, OnDestroy, HostListener } from '@angular/core';
import { FileUploader } from '@frontend/@shared/components/ng2-file-upload/file-uploader.class';
import { YougiverValidators } from '@frontend/@shared/directives';
import { EmitterService } from '@frontend/@shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-upload-photo',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileUploadPhotoComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private currentImage: any;
  private isEditMode: boolean = false;
  public hasDropZone: boolean = false;
  public uploader: any;

  private isUploading: boolean = false;

  @Input() url: string;
  @Input() headers: any;
  @Input() prepopulated: any;
  @Input() viewableType: string = 'Product';
  @Input() type: string = 'Image';
  @Input() disabled: boolean = false;



  constructor() { }

  ngOnInit() {
    this.subscriptions.push(EmitterService.get('STATUS_FILE_UPLOADER_EVENT').subscribe((res: boolean) => {
      this.isUploading = res;
    }));
    this.uploader = new FileUploader({
      url: this.url,
      isHTML5: true,
      autoUpload: true,
      headers: this.headers,
      itemAlias: 'attachment',
      viewableType: this.viewableType,
      type: this.type,
      allowedMimeType: [
        'image/jpeg',
        'image/png'
      ]
    });

    this.uploader.onWhenAddingFileFailed = YougiverValidators.fileValidation;

  }

  ngOnChanges(changes: any) {
    if (changes.prepopulated && changes.prepopulated.currentValue) {
      this.currentImage = changes.prepopulated.currentValue[0];
    } else if (changes.prepopulated && changes.prepopulated.currentValue === null) {
      this.currentImage = undefined;
      this.isEditMode = false;
    }
  }

  setActive(image: any): void {
    this.currentImage = image;
  }

  destroy(item: any): void {
    EmitterService.get('DELETE_UPLOADED_FILE').emit(item);
  }

  fileOverZone(e: any): void {
    this.hasDropZone = e;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  @ViewChild('photo_carousel') carousel: ElementRef; 
  isStartedScrolling: boolean = false;
  currentScrollPosition: number;
  scrolledBy: number = 0;

  getMousePosition(e) {
    return e.clientX || (e.touches && e.touches[0] && e.touches[0].clientX)
  }

  startScrolling(e: any) {
    this.isStartedScrolling = true
    this.currentScrollPosition = this.getMousePosition(e)
  }

  startMoving(e: any) {
    if (!this.isStartedScrolling) return
    const newPosition = this.getMousePosition(e)
    this.scrolledBy += this.currentScrollPosition - newPosition
    this.carousel.nativeElement.scrollLeft = this.scrolledBy
    this.currentScrollPosition = newPosition
  }

  
  @HostListener("window:mouseup", ["$event"]) 
  @HostListener("window:touchend", ["$event"]) 
  onStopScrolling(evt: KeyboardEvent) {
    this.isStartedScrolling = false
  }
}

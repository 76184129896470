import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Order } from '@frontend/@shared/types';
import { OrderService, EmitterService } from '@frontend/@shared/services/index';
import { Subscription } from 'rxjs';
import {CoolLocalStorage} from "angular2-cool-storage";

@Component({
  selector: 'yougiver-profile-order-preview-developer',
  styles: [require('../styles/_import.sass')],
  template: require('./template.pug'),
  providers: [OrderService]
})

export class ProfileOrderPreviewDeveloperComponent implements OnInit, OnDestroy {
  private mod: string = '';
  private subscriptions: Array<Subscription> = [];
  public recipientName: string = '';
  @Input() order: Order;

  constructor(private orderService: OrderService, private ls: CoolLocalStorage) {
  }

  ngOnInit(): void {
    if (this.order['recipient_delivery_name']) {
      this.recipientName = this.order['recipient_delivery_name'];
    } else {
      this.recipientName = this.order['recipient_name'];
    }

    switch (this.order.state) {
      case 'new':
        this.mod = 'mod-new';
        break;
      case 'complete':
        this.mod = 'mod-complete';
        break;
      default:
        this.mod = '';
    }
  }

  stateChange(event: string) {
    this.subscriptions.push(this.orderService.updateOrder(this.order.id, {event: event}, {locale: this.ls.getItem('locale')}).subscribe(res => {
      if (res['error']) {
        EmitterService.get('NOTIFICATION').emit(res);
      } else {
        this.order = res;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  quantity(): number {
    return this.order['item_count'] === 0 ? 1 : this.order['item_count'];
  }

  price() {
    return this.order['total'] / this.quantity();
  }

  totalComission() {
    if (this.order['completed_at']) {
      return this.order['line_items'][0]['total_comission'];
    }
  }

  developerComission() {
    if (this.order['completed_at']) {
      return this.order['line_items'][0]['developer_comission'];
    }
  }

  yougiverComission() {
    if (this.order['completed_at']) {
      return this.order['line_items'][0]['yougiver_comission'];
    }
  }
}

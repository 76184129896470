import { ProfileDeveloperBalanceCommonComponent } from '@frontend/profile/balance/common/common.component';

export const profileBalanceRoutes = {
  path: 'balance',
  children: [
    {
      path: 'common',
      component: ProfileDeveloperBalanceCommonComponent
    }
  ]
};
import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-profile-content-msg',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileContentMsgComponent {
  @Input() ico: any = '';
  @Input() text: any = '';
  @Input() btn: any = 'Добавить';
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { YougiverValidators } from '@frontend/@shared/directives';
import { AccountService, EmitterService } from '@frontend/@shared/services';

import { Account } from '@frontend/@shared/types';

import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
  FormArray,
  FormControl
} from '@angular/forms';

@Component({
  selector: 'yougiver-profile-developer-keys-block',
  template: require('./block.component.pug'),
  providers: []
})

export class ProfileKeysBlockComponent
 implements OnInit, OnDestroy {

  form: FormGroup;
  keys: AbstractControl;
  developerKey: AbstractControl;
  notificationsHeaderKey: AbstractControl;
  notificationsHeaderValue: AbstractControl;
  notificationsHeader: AbstractControl;

  private subscriptions: Array<Subscription> = [];
  private apiDocsLink: string;
  public account: Account;
  public invalidForm: boolean;
  public loaderState: string;

  constructor(
    private route: ActivatedRoute,
    private ls: CoolLocalStorage,
    protected fb: FormBuilder,
    protected accountService: AccountService
  ) {
    this.apiDocsLink = '/api/v1/docs';
  }

  ngOnInit(): void {
    this.formSetup();
    this.account = this.accountService.getAccount();
    if (this.account) {
      this.formUpdate();
    }

    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.formUpdate();
    }));
  }

  formSetup() {
    this.form = this.fb.group({
      keys: this.fb.group({
        developerKey: [''],
        notificationsHeader: this.fb.group({
          notificationsHeaderKey: [''],
          notificationsHeaderValue: ['']
        }, {validator: YougiverValidators.securityKey})
      })
    });

    this.keys = this.form.controls['keys'];
    this.developerKey = (this.keys as any).controls['developerKey'];
    this.notificationsHeader = (this.keys as any).controls['notificationsHeader'];
    this.notificationsHeaderKey = (this.notificationsHeader as any).controls['notificationsHeaderKey'];
    this.notificationsHeaderValue = (this.notificationsHeader as any).controls['notificationsHeaderValue'];
  }

  formUpdate() {
    for (let key in this.account.keys) {
      if (this.account['keys'][key]) {
        this[key].patchValue(this.account['keys'][key]);
      }
    }
  }

  onSubmit(value: any): void {
    let keysSection = this.form.value['keys']['notificationsHeader'];
    delete(this.form.value['keys']['notificationsHeader']);
    Object.assign(this.form.value['keys'], keysSection);

    let formData = this.form.value;

    if (this.form.valid) {
      this.invalidForm = false;
      this.loaderState = 'isLoading';

      this.accountService.updateAccount(this.account.id, formData).subscribe((res: any) => {
        if (!res.error) {
          this.loaderState = 'isComplete';
          if (res['resource']) {
            this.accountService.setAccount(res['resource']);
          }
        } else {
          this.loaderState = '';
        }
        EmitterService.get('NOTIFICATION').emit(res);
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

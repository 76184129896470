import { Component, OnInit } from '@angular/core';
import { CoolLocalStorage } from "angular2-cool-storage";

@Component({
    selector: 'yougiver-sign-up-mass-media',
    template: require('./sign-up-mass-media.component.pug')
})
export class SignUpMassMediaComponent implements OnInit {
    constructor(
        private ls: CoolLocalStorage
    ) {}

    ngOnInit() {
        this.ls.setItem('signUpAs', 'mass-media')
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MyDateRangePickerComponent } from './my-date-range-picker.component';
import { InputFocusDirective } from './directives/my-date-range-picker.input.directive';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule
  ],
  declarations: [ MyDateRangePickerComponent, InputFocusDirective ],
  exports: [ MyDateRangePickerComponent, InputFocusDirective ]
})
export class MyDateRangePickerModule { }

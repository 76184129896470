import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'yougiver-footer',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class FooterComponent {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';

import { FooterComponent } from '@frontend/@shared/components/footer/component';
import { FooterSelectModule } from '@frontend/@shared/components/footer-select/module'

@NgModule({
  imports: [CommonModule, DirectivesModule, FooterSelectModule],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule {
}

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  // Validators,
  FormArray,
  FormControl
} from '@angular/forms';

import { AccountService, TaxonomiesService, EmitterService } from '@frontend/@shared/services';
import { Account, Taxonomies } from '@frontend/@shared/types';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { YougiverValidators } from '@frontend/@shared/directives';
import { ProfileSettingsCategoriesComponent } from '@frontend/profile/settings/categories/categories.component';

@Component({
  selector: 'yougiver-profile-activation-goods-and-schedule',
  template: require('./goods-and-schedule.component.pug'),
  providers: [TaxonomiesService]
})
export class ProfileActivationGoodsAndScheduleComponent
  extends ProfileSettingsCategoriesComponent implements OnInit {
  public timeMask = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];
  working_hours_attributes: AbstractControl;

  private invalidForm: boolean = false;
  private loaderState: string = '';

  constructor(protected _changeDetectionRef: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected taxonomiesService: TaxonomiesService,
    protected router: Router,
    protected ls: CoolLocalStorage) { super(_changeDetectionRef, fb, accountServise, taxonomiesService, router, ls); }

  ngOnInit() {
    EmitterService.get('SET_BREADCRUMB').emit(['profile.activation.step2']);
    this.formSetup();

    this.account = this.accountServise.getAccount();
    if (this.account) {
      this.formUpdate();
    }
    EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      this.formUpdate();
    });


    this._changeDetectionRef.detectChanges();
  }

  formSetup() {
    this.form = this.fb.group({
      taxonomy_ids: this.fb.array([]),
      working_hours_attributes: this.fb.array([
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours }),
        this.fb.group({
          id: null, isodow: false, begin: [null, YougiverValidators.time24h],
          end: [null, YougiverValidators.time24h]
        }, { validator: YougiverValidators.workingHours })
      ])
    });

    this.taxonomy_ids = this.form.get('taxonomy_ids') as FormArray;
    this.working_hours_attributes = this.form.controls['working_hours_attributes'];

    this.taxonomiesService.getTaxonomies({ 'set': 'nested' }).subscribe((taxonomies: Taxonomies) => {
      this.taxonomies = taxonomies;
      taxonomies.taxonomies.forEach(taxonomy => {
        this.initTaxonControl(taxonomy);
      });
    });
  }

  formUpdate() {

    this.account.working_hours.forEach(day => {
      let day_control = (this.working_hours_attributes as any).controls[day.isodow - 1].controls;
      if (day.begin && day.end) {
        day_control['isodow'].patchValue(day.isodow);
      }
      if (day.id) {
        day_control['id'].patchValue(day.id);
      }
    });


  }

  isCheckedWorkingDay(day: any) {
    return (day.begin && day.end);
  }

  previousWorkingDayControl(index: number) {
    let controls = (this.working_hours_attributes as any).controls.filter(control => control['hours'] !== null);
    if (controls.length > 0) {
      let ctrl = controls.find(ctrl => ctrl.controls['begin'].value && ctrl.controls['end'].value);
      if (ctrl) {
        controls[index].controls['begin'].patchValue(ctrl.controls['begin'].value);
        controls[index].controls['end'].patchValue(ctrl.controls['end'].value);
        return { begin: ctrl.controls['begin'].value, end: ctrl.controls['end'].value };
      } else {
        return { begin: null, end: null };
      }
    } else {
      return { begin: null, end: null };
    }
  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      this.loaderState = 'isLoading';
      this.invalidForm = false;
      let index = 0;
      const taxons = this.taxonomy_ids.value.filter(({ isActive }) => isActive).map(({id}) => id)
      let value_object = { taxonomy_ids: taxons };
      value_object['working_hours_attributes'] = [];
      (this.working_hours_attributes as any).controls.forEach(day_group => {
        index++;
        let day = day_group.controls;
        if (day.isodow.value) {
          value_object['working_hours_attributes'].push(
            { id: day.id.value, isodow: day.isodow.value, hours: `[${day.begin.value},${day.end.value}]` }
          );
        } else {
          value_object['working_hours_attributes'].push(
            { id: day.id.value, isodow: index, hours: null }
          );
        }
      });

      this.accountServise.updateAccount(this.account.id, value_object).subscribe((res: any) => {
        if (!res.error) {
          this.loaderState = 'isComplete';
          if (res['resource']) {
  this.accountServise.setAccount(res['resource']);
}
          this.router.navigate(['profile/activation/documents-and-payments']);
        } else {
          EmitterService.get('NOTIFICATION').emit(res);
          this.loaderState = 'none';
          this.invalidForm = true;
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

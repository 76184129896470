import { NgModule, APP_INITIALIZER } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Http, HttpModule, RequestOptions, XHRBackend, XSRFStrategy, CookieXSRFStrategy } from '@angular/http';

// @shared/services should be imported at least before @shared/services/logged-out.guard
// see the commit message
import {
  UserService,
  LoggedInGuard,
  LoggedOutGuard,
  AccountTypeGuard,
  TaxonomiesService,
  ReviewsService,
  ProductService,
  CodesService,
  TranslationsService,
  MonitoringService,
  HttpService,
  AppConfig
} from '@frontend/@shared/services';

import { routing } from './app.routing';

import {AppComponent} from './app.component';

// Modules
import { PageModule } from '@frontend/pages/page.module';
import { SignModule } from '@frontend/sign/sign.module';
import { PaymentModule } from '@frontend/payment/payment.module';
import { ProfileModule } from '@frontend/profile/profile.module';
import { CoreModule } from '@frontend/@shared/modules/core.module';


@NgModule({
  imports: [
    BrowserModule,
    HttpModule,
    routing,
    CoreModule.forRoot(),
    PageModule,
    SignModule,
    PaymentModule,
    ProfileModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: XSRFStrategy, useValue: new CookieXSRFStrategy('YOUGIVER-XSRF-TOKEN', 'X-YOUGIVER-XSRF-TOKEN') },
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true },
    {
      provide: Http,
      useFactory: (backend: XHRBackend, options: RequestOptions, monitoring: MonitoringService) => {
        return new HttpService(backend, options, monitoring);
      },
      deps: [XHRBackend, RequestOptions, MonitoringService]
    },
    UserService,
    LoggedInGuard,
    LoggedOutGuard,
    TaxonomiesService,
    ReviewsService,
    ProductService,
    CodesService,
    TranslationsService,
    MonitoringService,
    AccountTypeGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

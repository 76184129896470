import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-profile-orders-show',
  styles: [require('./@styles/_import.sass')],
  template: require('./show.component.pug')
})

export class ProfileOrdersProductsShowComponent {
  private contactList: any;
  private orderList: any;

  constructor() {
    this.contactList = [
      {
        ico: 'contacts_user',
        list: [
          {
            text: 'Людмила',
          },
          {
            text: 'Зеленова',
          }
        ]
      },
      {
        ico: 'contacts_phone',
        list: [
          {
            text: '8 (957) 315-21-41',
            link: 'tel:89573152141'
          },
          {
            text: 'ludush@gmail.com',
            link: 'mailto:ludush@gmail.com',
          }
        ]
      },
      {
        ico: 'contacts_location',
        list: [
          {
            text: 'Киев, ул. Хмельницкого 145, кв.17',
            link: 'tel:89573152141'
          }
        ]
      }

    ];

    this.orderList = [
      {
        title: 'Номер заказа',
        text: '132'
      },
      {
        title: 'Цена',
        text: '$ 1325.00'
      },
      {
        title: 'Дата заказа',
        text: '13.01.2016, 13:43'
      },
      {
        title: 'Запрошенная дата доставки',
        text: '13.01.2016, 13:43'
      },
      {
        title: 'Примечание к заказу',
        text: 'Доставить желательно в первой половине дня. Набрать у подьезда!'
      },
    ];

  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { OrderService } from '@frontend/@shared/services/index';

@Component({
  selector: 'yougiver-profile-order-sortable-developer',
  styles: [require('../styles/_import.sass')],
  template: require('./template.pug'),
  providers: [OrderService]
})

export class ProfileOrderSortableDeveloperComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private sortable: Object = {};

  constructor(private router: Router,
              private route: ActivatedRoute,
              private orderService: OrderService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      if (queryParams['sortable']) {
        this.setActiveSorting(queryParams['sortable']);
      }
      else {
        this.sortable = {};
      }
    }));
  }

  setActiveSorting(sorting: string) {
    this.sortable[sorting.match(/by_(.*)/)[0]] = sorting.match(/(.*)_by_/)[1];
  }

  orderColumn(column: string): void {
    if (this.sortable[column]) {
      if (this.sortable[column] === 'ascend') {
        this.sortable[column] = 'descend';
      } else {
        this.sortable[column] = 'ascend';
      }
    } else {
      this.sortable = {};
      this.sortable[column] = 'ascend';
    }
    let params = [];
    for (let name in this.sortable) {
      if (this.sortable[name as string]) {
        params.push(`${this.sortable[name as string]}_${name}`);
      }
    }
    this.subscriptions.push(
      this.orderService.setQueryParams({ sortable: params })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

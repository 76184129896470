import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators
} from '@angular/forms';

import { AccountService, EmitterService, StaticPagesService, AppConfig } from '@frontend/@shared/services';
import { Account } from '@frontend/@shared/types';
import { YougiverValidators } from '@frontend/@shared/directives';
import { ProfileSettingsPaymentsComponent } from '@frontend/profile/settings/payments/payments.component';


@Component({
  selector: 'yougiver-profile-activation-documents-and-payments',
  template: require('./documents-and-payments.component.pug'),
  styles: [require('./styles/_import.sass')],
  providers: [StaticPagesService]
})
export class ProfileActivationDocumentsAndPaymentsComponent
  extends ProfileSettingsPaymentsComponent implements OnInit, OnDestroy {
  private loaderState: string = '';

  public headersForUploader: Array<any> = [];
  policies_accepted_at: AbstractControl;

  privacyPolicyUrl: string;
  intellectualPropertyPolicyUrl: string;

  constructor(protected fb: FormBuilder,
    protected accountServise: AccountService,
    protected router: Router,
    protected staticPagesService: StaticPagesService,
    protected config: AppConfig) {
    super(fb, accountServise, router, config);
  }

  ngOnInit() {
    this.privacyPolicyUrl = this.config.getConfig('privacy_policy_page_id');
    this.intellectualPropertyPolicyUrl = this.config.getConfig('intellectual_property_policy_page_id');

    EmitterService.get('SET_BREADCRUMB').emit(['profile.activation.step3']);
    this.form = this.fb.group({
      billing_information: this.fb.group({
        iban: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_account_usd: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_name: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_address: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_bank_name: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        beneficiary_bank_swift: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, YougiverValidators.swift_code])],
        beneficiary_bank_address: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        intermediary_bank: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])],
        correspondent_account: ['',
          Validators.compose([Validators.required, YougiverValidators.whitespace, Validators.minLength(1)])]
      }),
      policies_accepted_at: [{value: false, disabled: false}, [Validators.requiredTrue]]
    });

    this.policies_accepted_at = this.form.controls['policies_accepted_at'];
    this.billing_information = this.form.controls['billing_information'];
    this.iban = (this.billing_information as any).controls['iban'];
    this.beneficiary_account_usd = (this.billing_information as any).controls['beneficiary_account_usd'];
    this.beneficiary_name = (this.billing_information as any).controls['beneficiary_name'];
    this.beneficiary_address = (this.billing_information as any).controls['beneficiary_address'];
    this.beneficiary_bank_name = (this.billing_information as any).controls['beneficiary_bank_name'];
    this.beneficiary_bank_swift = (this.billing_information as any).controls['beneficiary_bank_swift'];
    this.beneficiary_bank_address = (this.billing_information as any).controls['beneficiary_bank_address'];
    this.intermediary_bank = (this.billing_information as any).controls['intermediary_bank'];
    this.correspondent_account = (this.billing_information as any).controls['correspondent_account'];
    EmitterService.get('SET_BREADCRUMB').emit(['shared.settings', 'profile.navbar.settings.payment_data']);
    this.account = this.accountServise.getAccount();
    if (this.account) {
      if(this.account.state == 'activated') {
        this.router.navigate(['profile/orders/list']);
      } else {
        for (let key in this.account.billing_information) {
          if (this.account.billing_information[key]) {
            this[key].patchValue(this.account.billing_information[key]);
          }
        }
      }
    }
    this.subscriptions.push(EmitterService.get('ACCOUNT').subscribe(account => {
      this.account = account;
      if(this.account.state == 'activated') {
        this.router.navigate(['profile/orders/list']);
      } else {
        for (let key in this.account.billing_information) {
          if (this.account.billing_information[key]) {
            this[key].patchValue(this.account.billing_information[key]);
          }
        }
      }
    }));

    this.accountServise.headers.forEach((value, name) => {
      if (name.toLowerCase() !== 'content-type') {
        this.headersForUploader.push({ name: name, value: value[0] });
      }
    });

    this.subscriptions.push(EmitterService.get('FILE_UPLOADER_EVENT').subscribe(res => {
      let response = JSON.parse(res.response);
      if (res.status <= 400) {
        this.account[response['snake_type']].push(response);

        EmitterService.get('NOTIFICATION').emit(response);
      } else if (response['errors']) {
        EmitterService.get('NOTIFICATION').emit({ error: response['errors']['attachment'][0] });
      }
    }));

    this.subscriptions.push(EmitterService.get('DELETE_UPLOADED_FILE').subscribe(file => {
      if (!file.deleted) {
        this.accountServise.destroyAsset(this.account.id, file).subscribe(accounts => {
          if (accounts.count > 0) {
            this.account = accounts.accounts.find(account => account.id === this.account.id);
          }
        },
          err => { console.log(err); });
      }
    }));
  }

  assetsPath() {
    return `/api/v1/accounts/${this.account.id}/assets`;
  }

  onSubmit(value: any): void {
    if (this.form.valid) {
      this.loaderState = 'isLoading';
      this.invalidForm = false;
      this.accountServise.updateAccount(this.account.id, this.form.value).subscribe((res: any) => {
        EmitterService.get('NOTIFICATION').emit({info: 'profile.seller.sent_to_moderation'});
        if (!res.error) {
          this.loaderState = 'isComplete';
          if (res['resource']) {
            this.accountServise.setAccount(res['resource']);
          }
        } else {
          this.loaderState = '';
        }
      });
    } else {
      this.invalidForm = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  previousStep() {
    this.router.navigateByUrl('/profile/developer/activation/main-info-and-products');
  }

  buttonText(): string {
    if(this.account.state === 'activation_step2') {
      return 'profile.activation.finish';
    } else if((this.account.state === 'pending') || (this.account.state === 'declined')) {
      return 'profile.activation.update_info';
    }
  }
}

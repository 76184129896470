import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderService, TransactionService, EmitterService } from '@frontend/@shared/services';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';

import {
  // FORM_DIRECTIVES,
  // REACTIVE_FORM_DIRECTIVES,
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators
} from '@angular/forms';
import { YougiverValidators } from '@frontend/@shared/directives';

@Component({
  selector: 'yougiver-profile-developer-balance-common',
  template: require('./common.component.pug'),
  providers: [TransactionService, OrderService]
})

export class ProfileDeveloperBalanceCommonComponent implements OnInit, OnDestroy {
  private lastChartDate: Date;
  public balanceMain: any;
  public balanceSecond: any;
  public developerShare: any;
  public accountType: string;
  private chartStatistic: Object;
  private formPopup: boolean = false;
  private successPopup: boolean = false;
  private formInvalid: boolean = false;
  private subscriptions: Array<Subscription> = [];
  private chartReady: boolean = false;
  private datasets: Array<Object> = [
    {
      label: 'Статистика по дням',
      data: [],
    }
  ];
  private lineChartColors: Array<any>;

  private labels: Array<string> = [];

  private options: Object;

  form: FormGroup;
  expense: AbstractControl;
  // description: AbstractControl;

  constructor(private transactionService: TransactionService,
    private fb: FormBuilder, private route: ActivatedRoute,
    private orderService: OrderService,
    private ls: CoolLocalStorage) {

    this.lineChartColors = [
      {
        backgroundColor: '#fdb53a',
        borderColor: '#fdb53a',
        pointBackgroundColor: '#fdb53a',
        pointHoverBorderColor: '#fdb53a'
      }
    ];
    this.options = {
      legend: {
        display: false
      },
      tooltips: {

        displayColors: false,
        backgroundColor: '#fff',
        titleFontColor: '#677883',
        bodyFontColor: '#292d2f'
      },
      responsive: false,
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            // labelString: 'Дни'
          }
        }],
        yAxes: [{
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Количество, шт'
          }
        }]
      }
    };
  }

  ngOnInit(): void {
    EmitterService.get('SET_BREADCRUMB').emit(['profile.navbar.balance.name', 'profile.navbar.balance.common_balance']);
    this.accountType = this.ls.getItem('accountType');
    this.balanceMain = {
      ico: 'cart',
      titleText: 'profile.navbar.balance.operations_sum',
      titleLink: 'profile.navbar.balance.current_month',
      balanceCurrency: '$',
      // balanceValue: '115 800',
      linkText: 'profile.orders.completed_orders',
      linkHref: '/profile/orders/list',
      linkParams: { state: 'completed' },
      // infoText: {
      // key: 'profile.navbar.balance.comissions_sum',
      // params: { comission: 3 }
      // },
      // infoLink: 'profile.navbar.balance.current_month',
      // infoValue: '23 741',
      showCalendar: this.accountType === 'Seller'
    };

    this.balanceSecond = {
      ico: 'bag',
      titleText: 'profile.navbar.balance.avaible_sum',
      balanceCurrency: '$',
      // balanceValue: '7 139',
      linkText: 'profile.navbar.balance.create_withdraw',
      linkHref: '',
      infoText: 'profile.navbar.balance.withdraws_sum',
      infoLink: 'profile.navbar.balance.current_month',
      // infoValue: '50 230',
      showCalendar: this.accountType === 'Seller'
    };

    this.form = this.fb.group({
      expense: ['', Validators.compose([Validators.required,
        YougiverValidators.whitespace,
        YougiverValidators.decimal,
        YougiverValidators.greaterThan(1)
      ])]
      // description: ['']
    });
    this.expense = this.form.controls['expense'];
    // this.description = this.form.controls['description'];
    this.getMainBalanceData();
    this.getSecondBalanceData();
    EmitterService.get('ACCOUNT_ID').subscribe(id => {
      this.getMainBalanceData();
      this.getSecondBalanceData();
    });

    this.subscriptions.push(EmitterService.get('LOCALE').subscribe(locale => {
      this.getChart(this.lastChartDate);
    }));


  }

  showPopup(show: boolean) {
    this.formPopup = show;
    this.successPopup = show;
  }

  getMainBalanceData(from = undefined, to = undefined): void {
    let type = this.accountType === 'Developer' ? 'developer_transactions' : 'seller_transactions';
    this.subscriptions.push(this.transactionService.getTransactionsData(type, from, to).subscribe((res: Object) => {
      this.balanceMain['balanceValue'] = res['transaction_sum'] || '0.0';
      this.developerShare = res['share'];
    }));
  }

  getSecondBalanceData(from = undefined, to = undefined): void {
    this.subscriptions.push(this.transactionService.getTransactionsData('withdraws', from, to).subscribe((res: Object) => {
      this.balanceSecond['balanceValue'] = res['available_sum'] || '0.0';
      this.balanceSecond['infoValue'] = res['withdraws_sum'] || '0.0';
    }));
  }

  getChart(currentDate: Date) {
    this.lastChartDate = currentDate;
    this.subscriptions.push(this.transactionService.getChartData(currentDate).subscribe((res: Object) => {
      this.chartStatistic = res['data'];
      this.options['scales']['xAxes'][0]['scaleLabel']['labelString'] = res['labels']['x_axis'];
      this.options['scales']['yAxes'][0]['scaleLabel']['labelString'] = res['labels']['y_axis'];
      this.options['tooltips']['callbacks'] = {
        label: (tooltipItems, data) => {
          return res['labels']['tooltip_label'] + ' ' + tooltipItems.yLabel;
        },
        afterLabel: (tooltipItems, data) => {
          return res['labels']['tooltip_secondary_label'] + ' ' + this.chartStatistic[tooltipItems.xLabel]['sum'];
        }
      };
      this.setChartData();
      this.chartReady = true;
    }));
  }

  completedOrders() {
    this.subscriptions.push(
      this.orderService.setQueryParams({ state: 'completed' })
    );
  }

  setChartData() {
    this.labels = [];
    this.datasets[0]['data'] = [];
    for (let key in this.chartStatistic) {
      if (this.chartStatistic.hasOwnProperty(key)) {
        this.labels.push(key);
        this.datasets[0]['data'].push(this.chartStatistic[key]['count']);
      }
    }
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.formInvalid = false;
      let id = this.ls.getItem('accountId');
      this.transactionService.createWithdraw(this.form.value, { account_id: id }).subscribe((res: any) => {
        EmitterService.get('NOTIFICATION').emit(res);
        this.formPopup = false;
        this.form.reset();
        if (!res.error) {
          this.successPopup = true;
        }
      });
    } else {
      this.formInvalid = true;
      console.log('form invalid!', this.form.controls);
    }
  }

  balanceMainChanged(e): void {
    this.ngOnDestroy();
    let f = this.getFormattedDates(e.formatted);
    this.getMainBalanceData(f[0], f[1]);
  }

  balanceSecondChanged(e): void {
    this.ngOnDestroy();
    let f = this.getFormattedDates(e.formatted);
    this.getSecondBalanceData(f[0], f[1]);
  }

  getFormattedDates(formatted: string): Array<string> {
    let split: Array<string> = formatted.split(' - ');
    if (split.length === 2) {
      return split;
    } else {
      return ['', ''];
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}

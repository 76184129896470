import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-profile-checkbox-group',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileCheckboxGroupComponent {
  @Input() title: any = 'Default title';
  @Input() inputs: any;
}

export class Helper {
  public static transformQueryParams(queryParams: Object): Object {
    let newQueryParams = JSON.parse(JSON.stringify(queryParams));
    if (newQueryParams['sortable']) {
      newQueryParams['sortable'] = [newQueryParams['sortable']];
    }
    if (newQueryParams['taxonomy']) {
      newQueryParams['taxonomies'] = { taxonomy: newQueryParams['taxonomy'] };
    }
    if (newQueryParams['taxon']) {
      // eslint-disable-next-line
      Object.assign(newQueryParams['taxonomies'], { taxon: newQueryParams['taxon'] });
    }
    if (newQueryParams['page']) {
      newQueryParams['pagination'] = { page: newQueryParams['page'] };
    }
    if (newQueryParams['fulltext']) {
      newQueryParams['fulltext'] = { keywords: newQueryParams['fulltext'] };
    }
    if (newQueryParams['state']) {
      newQueryParams['state'] = { state: newQueryParams['state'] };
    }
    if (newQueryParams['account_id']) {
      newQueryParams['account_id'] = { account_id: newQueryParams['account_id'] };
    }
    return newQueryParams;
  }

  public static toParams(object, ls = undefined): string {
    let messages = [];
    for (let scopes in object) {
      if (object[scopes] as any instanceof Array) {
        for (let scope of object[scopes]) {
          if (scope) {
            messages.push(`${scope}=1`);
          }
        }
      } else {
        if (object[scopes] as any instanceof Object) {
          for (let scope in object[scopes]) {
            if (object[scopes][scope]) {
              messages.push(`${scope}=${object[scopes][scope]}`);
            }
          }
        }
      }
    }
    if (ls) {
      let locale = ls.hasOwnProperty('locale') ? ls['locale'] : ls.getItem('locale');
      messages.push(`locale=${locale}`);
    }

    return messages.join('&');
  }
}

import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Helper } from '@frontend/@shared/services';

@Injectable()
export class TransactionService {
  public headers;
  public options;
  private transactionsUrl = '/api/v1/transactions';
  private apiKey: string;

  constructor(private router: Router, private route: ActivatedRoute,
    private http: Http, private ls: CoolLocalStorage) {
    this.apiKey = this.ls.getItem('auth_token');
    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Yougiver-Token': this.apiKey
    });
    this.options = new RequestOptions({ headers: this.headers });
  }

  public setQueryParams(params: Object) {
    return this.route.queryParams.subscribe(queryParams => {
      if (Object.keys(params).length !== 0) {
        let newQueryParams = JSON.parse(JSON.stringify(queryParams));
        // eslint-disable-next-line
        Object.assign(newQueryParams, params);
        let navigationExtras: NavigationExtras = {
          queryParams: newQueryParams
        };
        this.router.navigate(['profile/operations'], navigationExtras);
        params = {};
      }
    });
  }

  public getTransactions(params: Object = undefined): Observable<any> {
    if (params) {
      params = Helper.transformQueryParams(params);
      this.options.search = new URLSearchParams(Helper.toParams(params, this.ls));
    }
    return this.http.get(this.transactionsUrl, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  public createWithdraw(body, params: Object = undefined): Observable<any> {
    if (params) {
      params = Helper.transformQueryParams(params);
      this.options.search = new URLSearchParams(Helper.toParams(params, this.ls));
    }
    let bodyString = JSON.stringify(body);
    return this.http.post(this.transactionsUrl + '/create_withdraw', bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  public getStatistic(params: Object = undefined): Observable<any> {
    if (params) {
      params = Helper.transformQueryParams(params);
      this.options.search = new URLSearchParams(Helper.toParams(params, this.ls));
    }
    return this.http.get(this.transactionsUrl + '/get_statistic', this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  public getChartData(date: Date): Observable<any> {
    let id = this.ls.getItem('accountId');
    this.options.search = new URLSearchParams(Helper.toParams({ params: {  account_id: id, from: date } }, this.ls));
    return this.http.get(this.transactionsUrl + '/chart_data', this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  public getTransactionsData(type: string, fromDate: any = undefined, toDate: any = undefined): Observable<any> {
    let id = this.ls.getItem('accountId');
    this.options.search = new URLSearchParams(Helper.toParams({ params: { account_id: id, from: fromDate, to: toDate } }));
    return this.http.get(this.transactionsUrl + `/${type}_data`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

}

import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';

import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';

import { ProfileActivationDocumentsAndPaymentsComponent } from '@frontend/profile/activation/documents-and-payments/documents-and-payments.component';
import { ProfileActivationGoodsAndScheduleComponent } from '@frontend/profile/activation/goods-and-schedule/goods-and-schedule.component';
import { ProfileActivationMainInfoComponent } from '@frontend/profile/activation/main-info/main-info.component';
import { ProfileActivationComponent } from '@frontend/profile/activation/activation.component';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    ProfileSharedModule,
  ],
  declarations: [
    ProfileActivationComponent,
    ProfileActivationDocumentsAndPaymentsComponent,
    ProfileActivationGoodsAndScheduleComponent,
    ProfileActivationMainInfoComponent
  ],
  exports: [
    ProfileActivationComponent,
    ProfileActivationDocumentsAndPaymentsComponent,
    ProfileActivationGoodsAndScheduleComponent,
    ProfileActivationMainInfoComponent
  ],
})
export class ProfileActivationModule { }

import {Injectable} from '@angular/core';
import {Http, XHRBackend, RequestOptions, Request, RequestOptionsArgs, Response} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { MonitoringService } from '@frontend/@shared/services';

@Injectable()
export class HttpService extends Http {
  constructor (backend: XHRBackend, options: RequestOptions, public monitoring: MonitoringService) {
    super(backend, options);
    this.monitoring.notifyWhenNoOutstandingRequests(_ => {
      window['angularReady'] = true;
    });
  }

  request(url: string|Request, options?: RequestOptionsArgs): Observable<Response> {
    this.monitoring.incrementOutstandingRequests();

    return super.request(url, options).finally(() => {
      this.monitoring.decrementOutstandingRequests();
    });
  }
}

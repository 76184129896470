import { Component, OnInit } from '@angular/core';
import { CoolLocalStorage } from "angular2-cool-storage";

@Component({
    selector: 'yougiver-sign-up-seller',
    template: require('./sign-up-seller.component.pug')
})
export class SignUpSellerComponent implements OnInit {
    constructor(
        private ls: CoolLocalStorage
    ) {}

    ngOnInit() {
        this.ls.setItem('signUpAs', 'seller')
    }
}

import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';


import { ChartsModule } from 'ng2-charts/ng2-charts';
import { ProfileOperationsChartComponent } from '@frontend/profile/operations/chart/chart.component';
import { ProfileSharedModule } from '@frontend/profile/@shared/shared.module';
import { SharedComponentsModule } from '@frontend/@shared/components/components.module';
import { DirectivesModule } from '@frontend/@shared/pipes/directives.module';
import { ProfileOperationsListComponent } from '@frontend/profile/operations/list/list.component';

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    ProfileSharedModule,
    DirectivesModule,
    ChartsModule
  ],
  declarations: [
    ProfileOperationsChartComponent,
    ProfileOperationsListComponent
  ],
  exports: [
    ProfileOperationsChartComponent,
    ProfileOperationsListComponent
  ]
})
export class ProfileOperationsModule { }

import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Reviews } from '@frontend/@shared/types';
import { Observable } from 'rxjs';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class ReviewsService {
  private reviewsUrl = '/api/v1/reviews';

  private headers;
  private options;

  constructor (private http: Http) {
    this.headers = new Headers({'Content-Type': 'application/json'});
    this.options = new RequestOptions({ headers: this.headers });
  }

  getReviews(): Observable<Reviews> {
    return this.http.get(this.reviewsUrl, this.options)
                    .map((res: Response) => res.json())
                    .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }
}

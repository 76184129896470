import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Products } from '@frontend/@shared/types';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Helper } from '@frontend/@shared/services';

@Injectable()
export class ProductService {
  public headers;
  public options;
  public lastParams: string;
  private productsUrl = '/api/v1/products';
  private apiKey: string;

  public setQueryParams(params: Object) {
    return this.route.queryParams.subscribe(queryParams => {
      if (Object.keys(params).length !== 0) {
        let newQueryParams = JSON.parse(JSON.stringify(queryParams));
        // eslint-disable-next-line
        Object.assign(newQueryParams, params);
        let navigationExtras: NavigationExtras = {
          queryParams: newQueryParams
        };
        this.router.navigate(['/profile/products'], navigationExtras);
        params = {};
      }
    });
  }

  constructor(private router: Router, private route: ActivatedRoute, private http: Http, private ls: CoolLocalStorage) {
    this.apiKey = ls.getItem('auth_token');
    this.headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Yougiver-Token': this.apiKey
    });
    this.options = new RequestOptions({ headers: this.headers });
  }

  getProducts(params: Object = undefined): Observable<Products> {
    if (params) {
      let id = this.ls.getItem('accountId');
      Object.assign(params, {account_id: id});
      params = Helper.transformQueryParams(params);
      this.ls.setItem('lastProductParams', JSON.stringify(params));
      this.options.search = new URLSearchParams(Helper.toParams(params, this.ls));
    }

    return this.http.get(this.productsUrl, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  build(params: Object = undefined): Observable<any> {
    if (params) {
      let id = this.ls.getItem('accountId');
      Object.assign(params, { seller_id: id, account_id: id});
      this.options.search = new URLSearchParams(Helper.toParams({ params: params }, this.ls));
    }
    return this.http.get(`${this.productsUrl}/new`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  create(body, imageIds): Observable<any> {
    let id = this.ls.getItem('accountId')
    let obj = {product: body, seller_id: id, locale: this.ls.getItem('locale')};

    this.options = new RequestOptions({ headers: this.headers });
    this.options.search = new URLSearchParams(Helper.toParams({ params: {account_id: id} }, this.ls));

    Object.assign(obj.product, {image_ids: imageIds});
    let bodyString = JSON.stringify(obj);

    return this.http.post(this.productsUrl, bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  update(productId, body): Observable<any> {
    let id = this.ls.getItem('accountId')

    this.options = new RequestOptions({ headers: this.headers });
    this.options.search = new URLSearchParams(Helper.toParams({ params: {account_id: id} }, this.ls));

    let bodyString = JSON.stringify({ product: body, locale: this.ls.getItem('locale') });
    return this.http.patch(`${this.productsUrl}/${productId}`, bodyString, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  show(id, params: Object = undefined): Observable<any> {
    if (params) {
      let id = this.ls.getItem('accountId');
      Object.assign(params, { seller_id: id, account_id: id});
      params = Helper.transformQueryParams(params);
      this.options.search = new URLSearchParams(Helper.toParams(params, this.ls));
    }
    return this.http.get(`${this.productsUrl}/${id}`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  destroyAsset(id, file): Observable<any> {
    let destroyPath;
    if (id && file) {
      destroyPath = `${this.productsUrl}/${id}/assets/${file.id}`;
    } else if (!id && file) {
      destroyPath = `${this.productsUrl}/assets/${file.id}`;
    }

    if (file) {
      this.options.body = {type: file.type};
    }

    return this.http.delete(destroyPath, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  destroy(id): Observable<any> {
    let acc_id = this.ls.getItem('accountId')

    this.options.search = new URLSearchParams(Helper.toParams({ params: {account_id: acc_id} }, this.ls));

    return this.http.delete(`${this.productsUrl}/${id}`, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

}

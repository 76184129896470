import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '@frontend/@shared/services'; // our translate service

export const TRANSLATION_KEY_REGEXP = /[a-z]+\.[a-z]+/;

@Pipe({
  name: 'yougiverTranslate',
  pure: false
})

export class TranslatePipe implements PipeTransform {

  constructor(private translationsService: TranslationsService) { }

  transform(value: any, args: any[]): string {
    if (value === undefined) {
      return '';
    }
    else if (value instanceof Object && 'key' in value) {
      let translation = this.translationsService.getTranslationByKey(value['key'].split('.'));
      for (let param in value['params']) {
        if (value['params'].hasOwnProperty(param)) {
          translation = translation.replace(`%{${param}}`, value['params'][param]);
        }
      }
      return translation;
    }
    else if (value instanceof Object && 'msg' in value) {
      return value['msg'];
    }
    else if (typeof value === 'string' && TRANSLATION_KEY_REGEXP.test(value)) {
      return this.translationsService.getTranslationByKey(value.split('.'));
    }
    else {
      return value;
    }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-profile-logo',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})


export class ProfileLogoComponent {

}

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { EmitterService } from '@frontend/@shared/services';
@Component({
  selector: 'yougiver-profile-chart',
  template: require('./chart.component.pug'),
})

export class ProfileOperationsChartComponent implements OnInit {
  @Input() datasets: Array<Object>;
  @Input() lineChartColors: Array<any>;
  @Input() labels: Array<string>;
  @Input() options: Object;
  @Input() chartReady: boolean;
  @Output() currentDate = new EventEmitter<Date>();

  private currentYear: any;
  private currentMonth: string;
  private chartMonth: Array<string>;
  private nextMonth: boolean = false;
  private date: Date;

  constructor() {
    this.date = new Date();
    this.currentYear = this.date.getFullYear();
    this.chartMonth = [
      'profile.balance.chart.month.january',
      'profile.balance.chart.month.february',
      'profile.balance.chart.month.march',
      'profile.balance.chart.month.april',
      'profile.balance.chart.month.may',
      'profile.balance.chart.month.june',
      'profile.balance.chart.month.jule',
      'profile.balance.chart.month.augist',
      'profile.balance.chart.month.september',
      'profile.balance.chart.month.october',
      'profile.balance.chart.month.november',
      'profile.balance.chart.month.december',

    ];
    this.currentMonth = this.chartMonth[this.date.getMonth()];
  }

  ngOnInit() {
    this.getData();
    EmitterService.get('ACCOUNT_ID').subscribe(id => {
      this.getData();
    });
  }

  toMonth(to: string) {
    let index = this.monthIndex();
    if (index === 0 && to === 'previous') {
      this.currentYear--;
    } else if (index === 11 && to === 'next') {
      this.currentYear++;
    }
    this.currentMonth = this.chartMonth[(index + (to === 'previous' ? 11 : 1)) % 12];
    this.nextMonth = this.currentYear >= this.date.getFullYear() && this.monthIndex() >= this.date.getMonth() ? false : true;
    this.getData();
  }

  getData() {
    this.currentDate.emit(new Date(this.currentYear, this.monthIndex(), 1));
  }

  monthIndex(): number {
    return this.chartMonth.indexOf(this.currentMonth);
  }
}

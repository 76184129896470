import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Taxonomies, Taxonomy } from '@frontend/@shared/types';
import { Observable } from 'rxjs';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { Helper } from '@frontend/@shared/services';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class TaxonomiesService {
  private taxonomiesUrl = '/api/v1/taxonomies';
  private apiKey: string;

  private headers;
  private options;

  constructor(private http: Http, private ls: CoolLocalStorage) {
    this.apiKey = ls.getItem('auth_token');
    this.headers = new Headers({
      'Content-Type': 'application/json',
      'X-Yougiver-Token': this.apiKey
    });
    this.options = new RequestOptions({ headers: this.headers });
  }

  getTaxonomies(params: Object = undefined): Observable<Taxonomies> {
    this.options.search = new URLSearchParams(Helper.toParams({params: params}, this.ls));
    return this.http.get(this.taxonomiesUrl, this.options)
      .map((res: Response) => {
        let response = res.json();
        return response;
      })
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  getTaxonomy(params: Object = undefined, taxonomyId): Observable<Taxonomy> {
    if (params) {
      this.options.search = new URLSearchParams(Helper.toParams({params: params}));
    }

    return this.http.get(this.taxonomiesUrl + '/' + taxonomyId, this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  getMarkups(): Observable<any> {
    return this.http.get(this.taxonomiesUrl + '/markups', this.options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }
}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FileUploader } from '@frontend/@shared/components/ng2-file-upload/file-uploader.class';
import { YougiverValidators } from '@frontend/@shared/directives';
import { EmitterService } from '@frontend/@shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yougiver-profile-upload',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})

export class ProfileUploadComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private isUploading: boolean = false;

  public hasDropZone: boolean = false;
  public uploader: any;

  @Input() url: string;
  @Input() headers: any;
  @Input() prepopulated: any;
  @Input() viewableType: string;
  @Input() type: string;

  constructor() { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: this.url,
      isHTML5: true,
      autoUpload: true,
      headers: this.headers,
      itemAlias: 'attachment',
      viewableType: this.viewableType,
      type: this.type,
      // allowedMimeType: [
      //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
      //   'application/pdf',
      //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
      //   'application/msword', // doc
      //   'application/vnd.oasis.opendocument.text', // odt
      //   'application/vnd.oasis.opendocument.spreadsheet-template', // ods,
      //   'image/jpeg',
      //   'image/png'
      // ]
    });

    this.uploader.onWhenAddingFileFailed = YougiverValidators.fileValidation;
    this.subscriptions.push(EmitterService.get('FILE_UPLOADER_EVENT').subscribe(res => {
      let response = JSON.parse(res.response);
      if (res.status <= 400) {
        let item = this.uploader.queue.find(file => file.url.includes(`/${response.viewable_id}/`));
        if (item) {
          this.uploader.removeFromQueue(item);
        }
      }
    }));
    this.subscriptions.push(EmitterService.get('STATUS_FILE_UPLOADER_EVENT').subscribe((res: boolean) => {
      this.isUploading = this.uploader.getNotUploadedItems().length > 0 && res;
    }));
  }

  public fileOverZone(e: any): void {
    this.hasDropZone = e;
  }

  public fileEvent(e: any): void {
    console.log(e);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

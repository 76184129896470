import {Component, Input, OnInit} from '@angular/core';
import { WebhookLog } from '@frontend/@shared/types';

@Component({
  selector: 'yougiver-profile-webhook-logs-preview',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileWebhookLogsPreviewComponent implements OnInit{
  @Input() webhookLog: WebhookLog;

  private moreInfo: boolean;

  ngOnInit() {
    this.moreInfo = false;
  }

  toggleMoreInfo() {
    this.moreInfo = !this.moreInfo;
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'yougiver-profile-content-msg-btn',
  template: require('./template.pug'),
})

export class ProfileContentMsgBtnComponent {
  @Input() text: any = '';
  @Input() classMod: any = '';
  @Input() ico: any = '';
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WebhookLogsService, EmitterService } from '@frontend/@shared/services';
import { WebhookLogs } from '@frontend/@shared/types';
import { CoolLocalStorage } from 'angular2-cool-storage';

@Component({
  selector: 'yougiver-profile-developer-webhook-logs-list',
  template: require('./list.component.pug'),
  providers: [WebhookLogsService]
})

export class ProfileWebhookLogsListComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  private webhookLogs: WebhookLogs;
  private paginatorName: string;


  constructor(private webhookLogsService: WebhookLogsService, private route: ActivatedRoute,
              private ls: CoolLocalStorage) { }

  ngOnInit(): void {
    this.paginatorName = 'WEBHOOK_LOGS_LIST_PARAMS';
    this.getWebhookLogs(this.ls.getItem('accountId'));

    this.subscriptions.push(EmitterService.get('ACCOUNT_ID').subscribe(id => {
      this.getWebhookLogs(id);
    }));

    EmitterService.get('SET_BREADCRUMB').emit(['profile.navbar.other.system', 'profile.navbar.other.webhook_logs']);
  }

  getWebhookLogs(account_id: string): void {
    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      let newQueryParams = JSON.parse(JSON.stringify(queryParams));
      Object.assign(newQueryParams, { account_id: account_id });

      this.subscriptions.push(this.webhookLogsService.getWebhookLogs({ params: newQueryParams })
        .subscribe((webhookLogs: WebhookLogs) => {
          this.webhookLogs = webhookLogs;
        }));
    }));
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

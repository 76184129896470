import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'yougiver-switch-menu',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug')
})
export class SwitchMenuComponent {
  @Output() direction = new EventEmitter<string>();
  @Input() currentValue: string;
  @Input() additionalValue: string = '';

  @Input() nextMonth: boolean;

  switch(to: string) {
    this.direction.emit(to);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'yougiver-profile-status-select',
  styles: [require('./styles/_import.sass')],
  template: require('./template.pug'),
})

export class ProfileStatusSelectComponent {
}
